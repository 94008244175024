import { useResource } from "rest-hooks";
import UsersResource from "resources/users";
import UUID from "types/UUID";

export const useUser = (userId?: UUID) => {
  const { results: organizationsUsers } = useResource(UsersResource.list(), {});

  const user = organizationsUsers.find((user) => user.id === userId);
  return user;
};

export default useUser;
