import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import EntityType from "./entity-type";
import DatabasesResource from "./databases";
import SchemasResource from "./schemas";
import TablesResource from "./tables";
import ColumnsResource from "./columns";

export default class EntitiesResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;

  readonly type: EntityType | undefined = undefined;
  readonly column?: ColumnsResource = undefined;
  readonly table?: TablesResource = undefined;
  readonly schema?: SchemasResource = undefined;
  readonly database?: DatabasesResource = undefined;

  readonly count: number = 0;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("entities");

  static listImportant<T extends typeof Resource>(this: T) {
    return super.list().extend({
      url() {
        return `${EntitiesResource.urlRoot}/important`;
      },
      schema: { results: [this] },
    });
  }

  static databases(entities?: EntitiesResource[]) {
    return entities
      ?.filter((entity) => entity.isDatabase)
      .map((entity) => entity.databaseResource);
  }

  static schemas(entities?: EntitiesResource[]) {
    return entities
      ?.filter((entity) => entity.isSchema)
      .map((entity) => entity.schemaResource);
  }

  static tables(entities?: EntitiesResource[]) {
    return entities
      ?.filter((entity) => entity.isTable)
      .map((entity) => entity.tableResource);
  }

  static columns(entities?: EntitiesResource[]) {
    return entities
      ?.filter((entity) => entity.isColumn)
      .map((entity) => entity.columnResource);
  }

  static getEntityResource(type: EntityType, entityObject: object) {
    switch (type) {
      case EntityType.Database:
        return DatabasesResource.fromJS(entityObject);
      case EntityType.Schema:
        return SchemasResource.fromJS(entityObject);
      case EntityType.Table:
        return TablesResource.fromJS(entityObject);
      case EntityType.Column:
        return ColumnsResource.fromJS(entityObject);
    }
  }

  get isDatabase() {
    return !!this.database;
  }

  get isSchema() {
    return !!this.schema;
  }

  get isTable() {
    return !!this.table;
  }

  get isColumn() {
    return !!this.column;
  }

  get databaseResource() {
    return EntitiesResource.getEntityResource(
      EntityType.Database,
      this.database || {}
    ) as DatabasesResource;
  }

  get schemaResource() {
    return EntitiesResource.getEntityResource(
      EntityType.Schema,
      this.schema || {}
    ) as SchemasResource;
  }

  get tableResource() {
    return EntitiesResource.getEntityResource(
      EntityType.Table,
      this.table || {}
    ) as TablesResource;
  }

  get columnResource() {
    return EntitiesResource.getEntityResource(
      EntityType.Column,
      this.column || {}
    ) as ColumnsResource;
  }
}
