import React from "react";
import { Pane, Row, Span } from "components/base/layout";
import { Code } from "components/base/type";

const ColumnMenuItemContents = ({
  columnName,
  tableName,
  schemaName,
  databaseName,
}: {
  columnName: string;
  tableName: string;
  schemaName: string;
  databaseName: string;
}) => (
  <Pane>
    <Row color="#171E26">
      <Code
        textTransform="uppercase"
        fontSize={11}
        opacity={0.5}
        color="#171E26"
      >
        {databaseName}.{schemaName}.{tableName}
      </Code>
    </Row>
    <Row color="#171E26">
      <Span
        fontWeight={500}
        flexShrink={1}
        textOverflow="ellipsis"
        overflow="hidden"
        textTransform="lowercase"
      >
        {columnName}
      </Span>
    </Row>
  </Pane>
);

export default ColumnMenuItemContents;
