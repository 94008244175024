import React from "react";
import { UIProps } from "components/base/types";
import { Row } from "components/base/layout";

export const EntityRow = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & UIProps) => (
  <Row overflow="hidden" paddingY={16} {...props}>
    {children}
  </Row>
);

export default EntityRow;
