import { StrictGraphData } from "components/SharedGraph/types";

const withDynamicSizeGraphData = (
  data: StrictGraphData,
  isNodeSizeDynamic: boolean
): StrictGraphData =>
  isNodeSizeDynamic
    ? data
    : {
        ...data,
        nodes: data.nodes.map((node) => {
          const { size, ...newNode } = node;
          return newNode;
        }),
      };

export default withDynamicSizeGraphData;
