import { StrictGraphData } from "components/SharedGraph/types";

const filterGraphData = (
  allData: StrictGraphData,
  selectedNodeId: string
): StrictGraphData => {
  if (!selectedNodeId) {
    return allData;
  }

  const { nodes: baseTableNodes, edges: baseTableRelationshipEdges } = allData;

  const nodeIdsInFirstDegreeRelationships: string[] = [];

  const firstDegreeRelationships = baseTableRelationshipEdges.filter(
    (relationship) =>
      relationship.source === selectedNodeId ||
      relationship.target === selectedNodeId
  );

  firstDegreeRelationships.forEach((relationship) => {
    if (!nodeIdsInFirstDegreeRelationships.includes(relationship.source)) {
      nodeIdsInFirstDegreeRelationships.push(relationship.source);
    }
    if (!nodeIdsInFirstDegreeRelationships.includes(relationship.target)) {
      nodeIdsInFirstDegreeRelationships.push(relationship.target);
    }
  });

  const secondDegreeRelationships = baseTableRelationshipEdges.filter(
    (relationship) =>
      nodeIdsInFirstDegreeRelationships.includes(relationship.source) ||
      nodeIdsInFirstDegreeRelationships.includes(relationship.target)
  );

  const nodeIdsInSecondDegreeRelationships: string[] = [];
  secondDegreeRelationships.forEach((relationship) => {
    if (!nodeIdsInSecondDegreeRelationships.includes(relationship.source)) {
      nodeIdsInSecondDegreeRelationships.push(relationship.source);
    }
    if (!nodeIdsInSecondDegreeRelationships.includes(relationship.target)) {
      nodeIdsInSecondDegreeRelationships.push(relationship.target);
    }
  });

  const filteredNodes = baseTableNodes.filter((node) =>
    nodeIdsInSecondDegreeRelationships.includes(node.id)
  );

  const newData = {
    nodes: filteredNodes,
    edges: secondDegreeRelationships,
  };

  return newData;
};

export default filterGraphData;
