import React from "react";
import { UIProps } from "components/base/types";
import { Pane } from "components/base/layout";

export const PageSection = (props: UIProps) => (
  <Pane
    marginTop={12}
    paddingTop={12}
    marginX={-35}
    paddingX={35}
    borderTop="1px solid whitesmoke"
    {...props}
  />
);

export default PageSection;
