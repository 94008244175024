import { COMBO_LABEL_COLORS } from "components/SharedGraph/constants";
import { darken, desaturate } from "polished";

export enum DataSuperType {
  Boolean = "BOOLEAN",
  Text = "TEXT",
  Datetime = "DATETIME",
  Number = "NUMBER",
  Object = "OBJECT",
  Array = "ARRAY",
  Variant = "VARIANT",
  Geography = "GEOGRAPHY",
  Other = "OTHER",
}

const getColor = (index: number) =>
  desaturate(0.2, darken(0.2, COMBO_LABEL_COLORS[index]));

export const DataSuperTypeConfig: Record<
  DataSuperType,
  { icon: string; color: string }
> = {
  [DataSuperType.Boolean]: {
    icon: "fa-check-square",
    color: getColor(0),
  },
  [DataSuperType.Text]: { icon: "fa-font", color: getColor(7) },
  [DataSuperType.Datetime]: { icon: "fa-clock", color: getColor(4) },
  [DataSuperType.Number]: { icon: "fa-hashtag", color: getColor(3) },
  [DataSuperType.Object]: {
    icon: "fa-brackets-curly",
    color: getColor(1),
  },
  [DataSuperType.Array]: { icon: "fa-brackets", color: getColor(5) },
  [DataSuperType.Variant]: {
    icon: "fa-asterisk",
    color: getColor(6),
  },
  [DataSuperType.Geography]: {
    icon: "fa-map-marker-alt",
    color: getColor(2),
  },
  [DataSuperType.Other]: {
    icon: "fa-ellipsis-h",
    color: getColor(0),
  },
};

export enum DataSpecificType {
  Boolean = "BOOLEAN",
  Text = "TEXT",
  TinyText = "TINYTEXT",
  MediumText = "MEDIUMTEXT",
  LongText = "LONGTEXT",
  String = "STRING",
  Char = "CHAR",
  VarChar = "VARCHAR",
  Binary = "BINARY",
  VarBinary = "VARBINARY",
  Bit = "BIT",
  Enum = "ENUM",
  Number = "NUMBER",
  Int = "INT",
  Integer = "INTEGER",
  TinyInt = "TINYINT",
  SmallInt = "SMALLINT",
  MediumInt = "MEDIUMINT",
  BigInt = "BIGINT",
  Float = "FLOAT",
  Decimal = "DECIMAL",
  Double = "DOUBLE",
  Date = "DATE",
  Datetime = "DATETIME",
  Time = "TIME",
  Timestamp = "TIMESTAMP",
  TimestampTZ = "TIMESTAMP_TZ",
  TimestampLTZ = "TIMESTAMP_LTZ",
  TimestampNTZ = "TIMESTAMP_NTZ",
  Object = "OBJECT",
  Record = "RECORD",
  Array = "ARRAY",
  Set = "SET",
  Variant = "VARIANT",
  Blob = "BLOB",
  TinyBlob = "TINYBLOB",
  MediumBlob = "MEDIUMBLOB",
  LongBlob = "LONGBLOB",
  Geography = "GEOGRAPHY",
}

export const DataSpecificTypeConfig: Record<
  DataSpecificType,
  { superType: DataSuperType }
> = {
  [DataSpecificType.Boolean]: { superType: DataSuperType.Boolean },
  [DataSpecificType.Text]: { superType: DataSuperType.Text },
  [DataSpecificType.TinyText]: { superType: DataSuperType.Text },
  [DataSpecificType.MediumText]: { superType: DataSuperType.Text },
  [DataSpecificType.LongText]: { superType: DataSuperType.Text },
  [DataSpecificType.String]: { superType: DataSuperType.Text },
  [DataSpecificType.Char]: { superType: DataSuperType.Text },
  [DataSpecificType.VarChar]: { superType: DataSuperType.Text },
  [DataSpecificType.Binary]: { superType: DataSuperType.Text },
  [DataSpecificType.VarBinary]: { superType: DataSuperType.Text },
  [DataSpecificType.Bit]: { superType: DataSuperType.Text },
  [DataSpecificType.Enum]: { superType: DataSuperType.Text },
  [DataSpecificType.Number]: { superType: DataSuperType.Number },
  [DataSpecificType.Int]: { superType: DataSuperType.Number },
  [DataSpecificType.Integer]: { superType: DataSuperType.Number },
  [DataSpecificType.TinyInt]: { superType: DataSuperType.Number },
  [DataSpecificType.SmallInt]: { superType: DataSuperType.Number },
  [DataSpecificType.MediumInt]: { superType: DataSuperType.Number },
  [DataSpecificType.BigInt]: { superType: DataSuperType.Number },
  [DataSpecificType.Float]: { superType: DataSuperType.Number },
  [DataSpecificType.Decimal]: { superType: DataSuperType.Number },
  [DataSpecificType.Double]: { superType: DataSuperType.Number },
  [DataSpecificType.Date]: { superType: DataSuperType.Datetime },
  [DataSpecificType.Datetime]: { superType: DataSuperType.Datetime },
  [DataSpecificType.Time]: { superType: DataSuperType.Datetime },
  [DataSpecificType.Timestamp]: { superType: DataSuperType.Datetime },
  [DataSpecificType.TimestampTZ]: { superType: DataSuperType.Datetime },
  [DataSpecificType.TimestampLTZ]: { superType: DataSuperType.Datetime },
  [DataSpecificType.TimestampNTZ]: { superType: DataSuperType.Datetime },
  [DataSpecificType.Object]: { superType: DataSuperType.Object },
  [DataSpecificType.Record]: { superType: DataSuperType.Object },
  [DataSpecificType.Array]: { superType: DataSuperType.Array },
  [DataSpecificType.Set]: { superType: DataSuperType.Array },
  [DataSpecificType.Variant]: { superType: DataSuperType.Variant },
  [DataSpecificType.Blob]: { superType: DataSuperType.Variant },
  [DataSpecificType.TinyBlob]: { superType: DataSuperType.Variant },
  [DataSpecificType.MediumBlob]: { superType: DataSuperType.Variant },
  [DataSpecificType.LongBlob]: { superType: DataSuperType.Variant },
  [DataSpecificType.Geography]: { superType: DataSuperType.Geography },
};

export const getSuperType = (dataType: DataSpecificType) =>
  DataSpecificTypeConfig[dataType]?.superType || DataSuperType.Other;
