import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import { ConnectionType } from "types/Connection";

export enum SourceStatus {
  Enabled = "enabled",
  Disabled = "disabled",
}

export enum SourceSyncStatus {
  Started = "started",
  Succeeded = "succeeded",
  Errored = "errored",
}

export interface SourceSyncEvent {
  createdAt: Date;
  description: object;
  id: string;
  status: SourceSyncStatus;
  updatedAt: Date;
}

export default class SourcesResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly name: string = "";
  readonly type: ConnectionType = ConnectionType.None;

  readonly config: any = {};
  readonly status: SourceStatus = SourceStatus.Enabled;
  readonly syncHistory: SourceSyncEvent[] = [];

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("sources");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static create<T extends typeof Resource>(this: T) {
    return super.create().extend({
      optimisticUpdate: (
        params: Readonly<object>,
        body: Readonly<object | string> | void
      ) => body,
    });
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      optimisticUpdate: (params: any, body: any) => ({
        id: params.id,
        ...body,
      }),
    });
  }
  static sync<T extends typeof Resource>(this: T) {
    return super.detail().extend({
      url({ id }) {
        return `${SourcesResource.urlRoot}/${id}/sync`;
      },
    });
  }
  static test<T extends typeof Resource>(this: T) {
    return super.detail().extend({
      url({ id }) {
        return `${SourcesResource.urlRoot}/${id}/test`;
      },
    });
  }
}
