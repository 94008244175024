import { Graph } from "@antv/g6";
import { Item } from "@antv/g6/lib/types";
import { INode } from "@antv/g6/lib/interface/item";
import { TableRelationshipEdge, TableNode } from "../types";

import { GRAY, BLACK, OPACITIES, EDGE_FONT_SIZES } from "../constants";

import { getTruncatedText } from "utils/graph";

export const showFullNodeLabel = (graph: Graph, node: Item | null) => {
  if (!node) {
    return;
  }
  const model = node.getModel() as TableNode;

  graph.updateItem(node, {
    label: model.fullLabel,
  });
};

export const hideFullNodeLabel = (graph: Graph, node: Item | null) => {
  if (!node) {
    return;
  }
  const model = node.getModel() as TableNode;
  try {
    graph.updateItem(node, {
      label: model.truncatedLabel,
    });
  } catch (e) {}
};

export const showEdgeLabel = (
  graph: Graph,
  edge: Item | null,
  edgeLength: number
) => {
  if (!edge) {
    return;
  }
  const model = edge.getModel() as TableRelationshipEdge;

  const fontSize = Math.min(
    Math.max(edgeLength / model.hiddenLabel.length, EDGE_FONT_SIZES.min),
    EDGE_FONT_SIZES.max
  );

  graph.updateItem(edge, {
    label: getTruncatedText(model.hiddenLabel, edgeLength, fontSize),
    labelCfg: {
      style: {
        fontSize: fontSize,
      },
    },
  });
};

export const hideEdgeLabel = (graph: Graph, edge: Item | null) => {
  if (!edge) {
    return;
  }
  graph.setItemState(edge, "hover", false);
  try {
    graph.updateItem(edge, {
      label: "",
    });
  } catch (e) {}
};

const updateGraphNodeLabelsColor = (
  graph: Graph,
  nodes: INode[] | string[],
  color: string
) => {
  nodes.forEach((node: INode | string) => {
    const model =
      typeof node == "string"
        ? graph.findById(node).getModel()
        : node.getModel();

    graph.updateItem(node, {
      labelCfg: {
        ...model.labelCfg,
        style: {
          ...model.labelCfg?.style,
          fill: color,
        },
      },
    });
  });
};
export const lightenGraphNodeLabels = (
  graph: Graph,
  nodes: INode[] | string[]
) => updateGraphNodeLabelsColor(graph, nodes, GRAY);
export const darkenGraphNodeLabels = (
  graph: Graph,
  nodes: INode[] | string[]
) => updateGraphNodeLabelsColor(graph, nodes, BLACK);
export const resetGraphNodeLabels = (graph: Graph, nodes: INode[] | string[]) =>
  updateGraphNodeLabelsColor(graph, nodes, BLACK);

const updateGraphNodeOpacity = (
  graph: Graph,
  nodes: INode[] | string[],
  opacity: number
) => {
  nodes.forEach((node: INode | string) => {
    graph.updateItem(node, {
      style: {
        opacity: opacity,
      },
    });
  });
};
export const lightenGraphNodeOpacity = (
  graph: Graph,
  nodes: INode[] | string[]
) => updateGraphNodeOpacity(graph, nodes, OPACITIES.inactive);
export const darkenGraphNodeOpacity = (
  graph: Graph,
  nodes: INode[] | string[]
) => updateGraphNodeOpacity(graph, nodes, OPACITIES.active);
export const resetGraphNodeOpacity = (
  graph: Graph,
  nodes: INode[] | string[]
) => updateGraphNodeOpacity(graph, nodes, OPACITIES.default);

export const resetGraph = (graph: Graph) => {
  const allNodes = graph.getNodes();
  resetGraphNodeLabels(graph, allNodes);
  resetGraphNodeOpacity(graph, allNodes);
};
