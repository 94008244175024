import React, { useContext } from "react";
import { useFetcher, useResource } from "rest-hooks";

import { Form, Select } from "antd";
import { toaster } from "evergreen-ui";

import { Store } from "antd/lib/form/interface";

import { Row } from "components/base/layout";
import { Button, withLoader, withNetworkErrorBoundary } from "components/base";

import GithubResource from "resources/github";
import SourcesResource from "resources/sources";

import { ConnectionType } from "types/Connection";

import ConnectionCardContext from "../../ConnectionCardContext";

const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const DbtSetupTab = () => {
  const { connection } = useContext(ConnectionCardContext);
  const { repositories, installationId } = useResource(
    GithubResource.detail(),
    {
      sourceId: connection.id,
    }
  );
  const { results: sources } = useResource(SourcesResource.list(), {});

  const updateSource = useFetcher(SourcesResource.partialUpdate());

  const onSaveConnection = (values: Store) => {
    const { repositoryId, branch, warehouseId } = values;
    updateSource(
      { id: connection.id },
      {
        config: {
          branch,
          installationId,
          repositoryId,
          warehouseId,
        },
      }
    );
    toaster.success("dbt configuration updated.");
  };

  const WAREHOUSE_TYPES = new Set([
    ConnectionType.Snowflake,
    ConnectionType.BigQuery,
    ConnectionType.Redshift,
  ]);
  const warehouseSources = sources.filter((source) =>
    WAREHOUSE_TYPES.has(source.type)
  );

  return (
    <Form
      {...formLayout}
      name={connection.id}
      labelAlign="left"
      colon={false}
      onFinish={onSaveConnection}
      initialValues={{
        repositoryId: connection.config.repositoryId,
        branch: connection.config.branch,
        warehouseId: connection.config.warehouseId,
      }}
    >
      <Form.Item
        label="Repository"
        name="repositoryId"
        rules={[{ required: true, message: "Repository required" }]}
      >
        <Select placeholder="Select repository" style={{ marginTop: 4 }}>
          {repositories.map((repository: any) => (
            <Select.Option key={repository.id} value={repository.id}>
              {repository.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.repositoryId !== currentValues.repositoryId
        }
      >
        {({ getFieldValue }) => {
          const selectedRepositoryId = getFieldValue("repositoryId");
          const selectedRepository = selectedRepositoryId
            ? repositories.find((repository: any) => repository.id)
            : null;

          return (
            <Form.Item
              label="Branch"
              name="branch"
              rules={[{ required: true, message: "Branch required" }]}
            >
              <Select
                placeholder="Select branch"
                style={{ marginTop: 4 }}
                disabled={!selectedRepository}
              >
                {selectedRepository &&
                  selectedRepository.branches.map((branch: any) => (
                    <Select.Option key={branch.name} value={branch.name}>
                      {branch.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item
        label="Warehouse"
        name="warehouseId"
        rules={[{ required: true, message: "Warehouse required" }]}
      >
        <Select placeholder="Select warehouse" style={{ marginTop: 4 }}>
          {warehouseSources.map((source, i) => (
            <Select.Option
              key={source.id || i}
              value={source.id || source.name}
            >
              {source.name} [{source.type}]
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Row>
        <Button
          type="primary"
          buttonColor="#1890ff"
          activeColor="#40a9ff"
          htmlType="submit"
          marginLeft="auto"
          marginTop={30}
        >
          Save
        </Button>
      </Row>
    </Form>
  );
};

const ErrorState = () => (
  <div>
    Error loading connection data — access to github may have been revoked.
  </div>
);

export default withLoader(withNetworkErrorBoundary(DbtSetupTab, ErrorState), {
  minHeight: 100,
});
