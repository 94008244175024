import React, { useContext } from "react";
import { useResource } from "rest-hooks";

import DatabasesResource from "resources/databases";
import SchemasResource from "resources/schemas";
import SidebarContext from "./SidebarContext";

import { withLoader } from "components/base";
import SidebarSchemaRow from "./SidebarSchemaRow";

const SidebarDatabaseSchemasList = withLoader(
  ({ database }: { database: DatabasesResource }) => {
    const { defaultEntities } = useContext(SidebarContext);
    const defaultSchemas = SchemasResource.inEntities(defaultEntities)?.filter(
      (schema) => schema.database.id === database.id
    );

    const { results: fetchedSchemas } = useResource(SchemasResource.list(), {
      databaseId: database.id,
    });

    const schemas = defaultSchemas || fetchedSchemas;

    const sortedSchemas = [...schemas.filter((schema) => !schema.hidden)].sort(
      (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }
    );

    return (
      <>
        {sortedSchemas?.map((schema) => (
          <SidebarSchemaRow
            key={schema.id}
            schema={schema}
            defaultOpen={!!defaultSchemas}
          />
        ))}
      </>
    );
  },
  {},
  () => <></>
);

export default SidebarDatabaseSchemasList;
