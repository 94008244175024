import React from "react";
import { useResource } from "rest-hooks";

import SourcesResource from "resources/sources";
import SlackIntegrationResource from "resources/slack-integration";

import { H2 } from "components/base/type";
import { Row } from "components/base/layout";

import ConnectionCard from "./ConnectionCard";
import SlackConnectionCard from "./SlackConnectionCard";

const YourConnectionsSection = ({
  connections,
}: {
  connections: SourcesResource[];
}) => {
  const { results: slackIntegrations } = useResource(
    SlackIntegrationResource.list(),
    {}
  );

  if (connections.length === 0 && slackIntegrations.length === 0) {
    return <></>;
  }

  return (
    <>
      <H2 marginBottom={41}>Your connections</H2>
      <Row wrap marginBottom={57} alignItems="flex-start">
        {connections.map((connection) => (
          <ConnectionCard key={connection.id} connection={connection} />
        ))}
        {slackIntegrations.map((slackIntegration) => (
          <SlackConnectionCard
            key={slackIntegration.id}
            slackIntegration={slackIntegration}
          />
        ))}
      </Row>
    </>
  );
};

export default YourConnectionsSection;
