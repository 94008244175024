import React from "react";

import { useTrackWithEntity } from "utils/analytics";

import { UIProps } from "components/base/types";
import { MiniIconButton } from "components/base";

const RequestEntityDocumentationButton = ({
  requestDocumentation,
  iconOnly,
  onClick,
  ...props
}: {
  requestDocumentation: () => void;
  iconOnly?: boolean;
} & UIProps) => {
  const trackWithEntity = useTrackWithEntity();

  return (
    <MiniIconButton
      onClick={() => {
        requestDocumentation();
        trackWithEntity("Requested documentation for entity");
        onClick?.();
      }}
      iconType="far"
      iconName="fa-file-signature"
      color="hsla(212, 25%, 12%, 0.6)"
      title="Request documentation"
      tooltipProps={{ placement: "topRight" }}
      {...props}
    >
      {iconOnly ? null : "Request"}
    </MiniIconButton>
  );
};

export default RequestEntityDocumentationButton;
