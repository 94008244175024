import React from "react";
import { Typography } from "./Typography";
import { TypographyProps } from "components/base/types";

export const H3 = (props: TypographyProps) => (
  <Typography
    is="h3"
    margin={0}
    fontSize={18}
    fontWeight={500}
    color="#171E26"
    {...props}
  />
);
