import React, { Suspense } from "react";
import { Spinner } from "evergreen-ui";
import { UIProps } from "components/base/types";
import { Row } from "components/base/layout";

export const withLoader = (
  Component: any,
  loaderProps?: object,
  LoaderComponent?: any
) => (props: any) => (
  <Suspense
    fallback={
      LoaderComponent ? (
        <LoaderComponent {...props} {...(loaderProps || {})} />
      ) : (
        <DefaultLoader {...(loaderProps || {})} />
      )
    }
  >
    <Component {...props} />
  </Suspense>
);

export const DefaultLoader = (props?: UIProps) => (
  <Row height="100%" minHeight={300} width="100%" center {...(props || {})}>
    <Spinner />
  </Row>
);

export default withLoader;
