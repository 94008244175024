import G6 from "@antv/g6";
import { GraphOptions } from "@antv/g6/lib/types";
import {
  NODE_SIZES,
  GRAY,
  BLACK,
  WHITE,
  GRAPH_FONT_SIZE,
  OPACITIES,
} from "components/SharedGraph/constants";

import CustomCombo from "./CustomCombo";

G6.Layout.registerLayout("comboForce", {}, CustomCombo);

export const getComboLayout = (
  linkDistance: number = 30,
  optimizePerformance: boolean = false,
  onTick: Function = () => {}
) => {
  return {
    type: "comboForce",
    linkDistance: 70,
    nodeSpacing: 70,
    comboSpacing: 400,
    optimizeRangeFactor: 0.1,
    gravity: 1000,
    comboGravity: 100,
    collideNodeStrength: 1.0,
    collideComboStrength: 1.0,

    maxIteration: optimizePerformance ? 200 : 200,
    preventNodeOverlap: true,
    preventComboOverlap: true,
    onTick: onTick,
    onLayoutEnd: onTick,
  };
};

export const getForceLayout = (
  linkDistance: number = 20,
  optimizePerformance: boolean = false,
  onTick: Function = () => {}
) => ({
  type: "force",
  nodeSpacing: (d: any) => 5,
  preventOverlap: true,
  clustering: true,
  clusterNodeStrength: -5,
  clusterEdgeDistance: 200,
  clusterNodeSize: 50,
  clusterFociStrength: 1.2,
});

export const getDagreLayout = (
  linkDistance: number = 20,
  optimizePerformance: boolean = false,
  onTick: Function = () => {}
) => ({
  type: "dagre",
  rankDir: "BT",
  sortByCombo: false,
  controlPoints: true,
});

const anchorPoints = Array(360)
  .fill(0)
  .map((_, theta) => {
    return [
      (Math.cos((theta * Math.PI) / 180) + 1) / 2,
      (Math.sin((theta * Math.PI) / 180) + 1) / 2,
    ];
  });

G6.registerNode(
  "customCircle",
  {
    getAnchorPoints: (cfg: any) => {
      return anchorPoints;
    },
    drawShape: (cfg: any, group: any) => {
      const color = cfg.error ? "#F4664A" : "#30BF78";
      const circle = group.addShape("circle", {
        attrs: {
          stroke: color,
          r: cfg.size / 2,
          opacity: OPACITIES.default,
          ...cfg.style,
        },
        name: "circle-shape",
        draggable: true,
      });
      const bbox = circle.getBBox();

      group.addShape("marker", {
        attrs: {
          x: bbox.maxX / 1.41 + 7,
          y: -bbox.maxY / 1.41 + 7,
          r: 6,
          opacity: 0,
          symbol: cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse,
          stroke: GRAY,
          fill: WHITE,
          lineWidth: 1,
          cursor: "pointer",
        },
        name: "collapse-icon",
      });

      return group;
    },
    afterDraw: (cfg: any, group: any) => {
      const icon = group.find(
        (element: any) => element.get("name") === "collapse-icon"
      );
      if (icon) {
        group.on("mouseenter", () => {
          icon.attr("opacity", 0.7);
        });
        group.on("mouseleave", () => {
          icon.attr("opacity", 0);
        });

        icon.on("mouseenter", () => {
          icon.attr("opacity", 1.0);
        });
      }
    },
  },
  "single-node"
);

const buildGraphConfig = (
  node: HTMLElement,
  linkDistance: number,
  numNodes: number,
  plugins: any[],
  onTick?: Function,
  layout?: string
): GraphOptions => {
  const { width, height } = node.getBoundingClientRect();

  const optimizePerformance = numNodes > 100;

  let layoutConfig: any = getComboLayout(
    linkDistance,
    optimizePerformance,
    onTick
  );
  if (layout === "force") {
    layoutConfig = getForceLayout(linkDistance, optimizePerformance, onTick);
  } else if (layout === "dagre") {
    layoutConfig = getDagreLayout(linkDistance, optimizePerformance, onTick);
  }

  return {
    container: node,
    width: width,
    height: height - 7,
    fitView: true,
    fitViewPadding: layout === "dagre" ? 10 : 80,
    fitCenter: true,
    animate: false,
    layout: layoutConfig,
    defaultNode: {
      type: layout === "dagre" ? "circle" : "customCircle",
      size: NODE_SIZES.default,
      style: {
        opacity: OPACITIES.default,
        radius: 50,
        stroke: "#69c0ff",
        fill: "#ffffff",
        cursor: "pointer",
        lineWidth: 2,
      },
      labelCfg: {
        style: {
          position: layout === "dagre" ? "bottom" : "center",
          fill: BLACK,
          fontSize: 8,
          fontWeight: 500,
          fontFamily: "Fira Code",
          cursor: "pointer",
        },
      },
    },
    defaultEdge: {
      type: "straight",
      style: {
        radius: 5,
        stroke: "#8C8F93",
        opacity: 0.2,
        endArrow: layout === "dagre" ? true : false,
      },
      labelCfg: {
        autoRotate: true,
        style: {
          fontFamily: "Inter var",
          fontSize: GRAPH_FONT_SIZE,
        },
      },
    },
    defaultCombo: {
      type: "rect",
      padding: 0,
      stroke: "white",
      fill: "white",
      style: {
        radius: 5,
        stroke: "white",
        fill: "white",
        cursor: "pointer",
      },
      labelCfg: {
        refY: 10,
        position: "top",
        style: {
          fill: BLACK,
          fontWeight: 500,
          fontSize: 50,
          fontFamily: "Fira Code",
        },
      },
    },
    modes: {
      default: [
        {
          type: "drag-combo",
          onlyChangeComboSize: true,
        },
        {
          type: "drag-canvas",
        },
        "collapse-expand-combo",
        "drag-node",
        {
          type: "zoom-canvas",
          // @ts-expect-error
          sensitivity: 1,
        },
      ],
    },
    plugins,
  };
};

export default buildGraphConfig;
