import { Graph } from "@antv/g6";
import { IG6GraphEvent } from "@antv/g6/lib/types";
import { IEdge } from "@antv/g6/lib/interface/item";
import { showEdgeLabel, hideEdgeLabel } from "./utils";

const initializeEdgeHoverListeners = (graph: Graph) => {
  graph.on("edge:mouseenter", (event: IG6GraphEvent) => {
    const edge = event.item as IEdge;
    const edgeModel = edge.getModel();
    const { startPoint, endPoint } = edgeModel;

    let edgeLength = 80;
    if (startPoint && endPoint) {
      edgeLength =
        Math.sqrt(
          Math.pow(Math.abs(startPoint.x - endPoint.x), 2) +
            Math.pow(Math.abs(startPoint.y - endPoint.y), 2)
        ) * 1.2;
    }

    showEdgeLabel(graph, edge, edgeLength);
  });

  graph.on("edge:mouseleave", (event: IG6GraphEvent) => {
    const edge = event.item;
    hideEdgeLabel(graph, edge);
  });
};

export default initializeEdgeHoverListeners;
