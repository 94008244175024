import React from "react";
import { Form } from "antd";

import { Input } from "components/base";

const BigQueryConnectionFormItems = () => (
  <>
    <Form.Item
      label="Nickname"
      name="name"
      rules={[{ required: true, message: "Nickname required" }]}
    >
      <Input placeholder={`BigQuery Prod`} />
    </Form.Item>
    <Form.Item
      label="Service account"
      name="serviceAccount"
      rules={[{ required: true, message: "Service account required" }]}
    >
      <Input.TextArea
        rows={14}
        placeholder={`e.g. { "type": "service_account", "project_id": "metaplane", "private_key_id": "xxx", "private_key": "-----BEGIN PRIVATE KEY-----xxx\n-----END PRIVATE KEY-----\n", "client_email": "metaplane-bigquery-owner@metaplane.iam.gserviceaccount.com", "client_id": "12346678", "auth_uri": "https://accounts.google.com/o/oauth2/auth", "token_uri": "https://oauth2.googleapis.com/token", "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs", "client_x509_cert_url": "https://www.googleapis.com/robot/v1/"}`}
      />
    </Form.Item>
  </>
);

export default BigQueryConnectionFormItems;
