import React from "react";
import { UIProps } from "components/base/types";
import { EntityTypeIcon, Icon as BaseIcon } from "components/base";
import { Span } from "components/base/layout";
import { TableType } from "./index";

const Icon = ({ type, ...props }: { type: TableType } & UIProps) => {
  return (
    <EntityTypeIcon type={type}>
      {type === TableType.Table ? (
        <BaseIcon
          fixedWidth
          name="fa-table"
          {...props}
          color="hsl(120 36% 40% / 1)"
        />
      ) : (
        <Span position="relative" {...props} color="hsl(305 41% 40% / 1)">
          <BaseIcon fixedWidth name="fa-table" />
          <BaseIcon
            fixedWidth
            name="fa-search"
            position="absolute"
            fontSize="0.63em"
            right={0}
            bottom={0}
            borderRadius={40}
            padding="0.1em"
            paddingTop="0.2em"
            background="white"
          />
        </Span>
      )}
    </EntityTypeIcon>
  );
};

export const buildIcon = (type: TableType) => (props: UIProps) => (
  <Icon type={type} {...props} />
);

export default Icon;
