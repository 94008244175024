import { GraphControlsType } from "./types";

export const NODE_SIZES = {
  min: 60,
  default: 60,
  max: 200,
};

export const FONT_SIZES = {
  min: 8,
  default: 8,
  max: 50,
};

export const EDGE_FONT_SIZES = {
  min: 4,
  default: 8,
  max: 8,
};

export const LINK_DISTANCE = 30;

export const GRAPH_FONT_SIZE = 10;

export const NODE_COLORS = [
  "#95C995", // hsl(120deg 32% 69%)
  "#B578B0", // hsl(305deg 29% 59%)
  "#F2A98A", // hsl(18deg 80% 75%)
  "#F7759F", // hsl(336deg 90% 68%)
  "#8BD9DE", // hsl(184deg 56% 71%)
  "#8CBEF2", // hsl(211deg 80% 75%)
  "#F2D58A", // hsl(43deg 80% 75%)
  "#9675f0", // hsl(256deg, 80%, 70%)
];

export const COMBO_COLORS = [
  "#DFEADF", // hsl(120, 21, 90)
  "#E5DAE4", // hsl(305, 17, 88)
  "#F0E3DE", // hsl(17, 37, 91)
  "#F1DFE7", // hsl(333, 39, 91)
  "#DEECEC", // hsl(180, 27, 90)
  "#E0E9F1", // hsl(208deg 38% 91%)
  "#F0EBDE", // hsl(43deg 37% 91%)
  "#e2dfec", // hsl(256deg, 27%, 90%)
];

export const COMBO_LABEL_COLORS = [
  "#5F9D61", // hsl(122deg 25% 49%)
  "#71236D", // hsl(303deg 53% 29%)
  "#E07849", // hsl(19deg 71% 58%)
  "#EB5C85", // hsl(343deg 78% 64%)
  "#57B7BD", // hsl(184deg 44% 54%)
  "#4891E0", // hsl(211deg 71% 58%)
  "#E0B549", // hsl(43deg 71% 58%)
  "#7149df", // hsl(256deg, 70%, 58%)
];

// 17, 43, = 26
// 43, 120, = 77
// 120, 180, = 60
// 180, 208, = 28
// 208, 256, = 48
// 256, 305, = 49
// 305, 333  = 28

export const LIGHT_GRAY = "#DDDDDF";
export const GRAY = "#8C8F93";
export const BLACK = "#212b36";
export const WHITE = "#FFFFFF";

export const DEFAULT_CONTROLS: GraphControlsType = {
  groupBySchema: true,
  includeSecondDegreeConnections: false,
  isNodeSizeDynamic: true,
  onlyCentralTables: true,
};

export const OPACITIES = {
  inactive: 0.4,
  default: 1.0,
  active: 1.0,
};

export const EDGE_SEPARATOR = "⨝";
