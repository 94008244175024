import { Util as AntVUtil } from "@antv/g6";

import { FONT_SIZES } from "components/SharedGraph/constants";

const getLabelLength = (text: string, fontSize: number) => {
  let currentWidth = 0;
  text.split("").forEach((letter, i) => {
    currentWidth += AntVUtil.getLetterWidth(letter, fontSize);
  });
  return currentWidth;
};

export const getLabelTextAndSize = (label: string, nodeSize: number) => {
  let finalLabel = label;
  let fontSize = FONT_SIZES.default;
  const diameter = nodeSize * 2 * 0.85;
  const padding = 0.2;
  const diameterWithPadding = diameter * (1 - padding);

  const words = label.split("_");
  const wordLengths = words.map((w: string) => w.length);
  const maxWordLength = Math.max(...wordLengths);

  // 1) Try to fit
  // If the fitted font of the unsplit font is less than the minimum, split it up
  if (getLabelLength(label, FONT_SIZES.min) <= diameterWithPadding) {
    fontSize = diameterWithPadding / label.length;
  }
  // 2) If it doens't fit
  else {
    // 2a) truncate if too many words
    const aspectRatio = 1.54;
    const lineSpacing = 1.5;
    const maxPercentageOfDiameter = 0.75;
    if (
      words.length * (FONT_SIZES.min * aspectRatio * lineSpacing) >
      diameterWithPadding * maxPercentageOfDiameter
    ) {
      const maxNumCharacters = diameterWithPadding / FONT_SIZES.min;
      finalLabel = label.substring(0, maxNumCharacters - 3) + "...";
      fontSize = FONT_SIZES.min;
    }
    // 2b) split into words if not
    else {
      fontSize = diameterWithPadding / maxWordLength;
      finalLabel = label.split("_").join("_\n");
    }
  }

  // Cap out at max size
  fontSize = Math.min(fontSize, FONT_SIZES.max);

  return {
    label: finalLabel,
    fontSize: fontSize,
  };
};
