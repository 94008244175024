import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "constants/style";

const GlobalStyle = createGlobalStyle`
  *,
  :after,
  :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    overscroll-behavior: none;
    font-size: 16px;
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-select-dropdown {
    z-index: 3001;
  }

  .ant-message {
    z-index: 4000;
  }

  .sortableContainer {
    z-index: 3001;
  }
`;

const GlobalThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export default GlobalThemeProvider;
