import React, { useContext } from "react";
import { useResource } from "rest-hooks";

import SchemasResource from "resources/schemas";
import TablesResource from "resources/tables";
import SidebarContext from "./SidebarContext";

import { withLoader } from "components/base";
import SidebarTableRow from "./SidebarTableRow";

const SidebarSchemaTablesList = withLoader(
  ({ schema }: { schema: SchemasResource }) => {
    const { defaultEntities } = useContext(SidebarContext);
    const defaultTables = TablesResource.inEntities(defaultEntities)?.filter(
      (table) => table.schema.id === schema.id
    );

    const { results: fetchedTables } = useResource(TablesResource.list(), {
      schemaId: schema.id,
    });

    const tables = defaultTables || fetchedTables;

    const sortedTables = [...tables.filter((table) => !table.hidden)].sort(
      (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }
    );

    return (
      <>
        {sortedTables?.map((table) => (
          <SidebarTableRow key={table.id} table={table} />
        ))}
      </>
    );
  },
  {},
  () => <></>
);

export default SidebarSchemaTablesList;
