import React, { useState, useEffect } from "react";
import { useFetcher, useResource } from "rest-hooks";

import { toaster } from "evergreen-ui";
import { SLACK_INTEGRATION_ID_STORAGE_KEY } from "constants/storageKeys";
import { usePrevious, useWaitForLocalStorageValue } from "utils/hooks";
import SlackIntegrationResource, {
  slackIntegrationAddToListAppender,
} from "resources/slack-integration";
import { IntegrationCard } from "./IntegrationCardGroup";
import { Connection } from "types/Connection";

const SLACK_CONNECT_URL =
  "https://slack.com/oauth/v2/authorize?client_id=387049038887.1522404796359&scope=channels:read,chat:write,commands,links:read,links:write,users:read,users:read.email,users.profile:read,groups:read&user_scope=";

const SlackIntegrationCard = ({ source }: { source: Connection }) => {
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);
  const slackAuthorizationObject = useWaitForLocalStorageValue({
    localStorageKey: SLACK_INTEGRATION_ID_STORAGE_KEY,
    shouldBeginPolling: !!connectionWindow,
  });
  const prevSlackAuthorizationObject = usePrevious(slackAuthorizationObject);
  const createSlackIntegration = useFetcher(SlackIntegrationResource.create());
  const slackIntegrationsResourceList = SlackIntegrationResource.list();

  const { results: slackIntegrations } = useResource(
    slackIntegrationsResourceList,
    {}
  );

  const hasSlackAuthorizationObjectChanged =
    prevSlackAuthorizationObject !== slackAuthorizationObject;

  useEffect(() => {
    if (
      slackAuthorizationObject &&
      hasSlackAuthorizationObjectChanged &&
      connectionWindow
    ) {
      const { code, state }: { code: string; state: string } = JSON.parse(
        slackAuthorizationObject
      );
      createSlackIntegration(
        {},
        {
          code,
          state,
        },
        [[slackIntegrationsResourceList, {}, slackIntegrationAddToListAppender]]
      )
        .catch((error) => {
          toaster.danger(
            "Sorry, we had trouble connecting to your Slack. Can you please try again?"
          );
        })
        .finally(() => {
          window.localStorage.removeItem(SLACK_INTEGRATION_ID_STORAGE_KEY);
          connectionWindow.close();
          setConnectionWindow(null);
        });
    }
  }, [
    slackAuthorizationObject,
    connectionWindow,
    createSlackIntegration,
    slackIntegrationsResourceList,
    hasSlackAuthorizationObjectChanged,
  ]);

  return (
    <IntegrationCard
      disabled={source.disabled || slackIntegrations.length > 0}
      onClick={() => {
        window.localStorage.removeItem(SLACK_INTEGRATION_ID_STORAGE_KEY);
        setConnectionWindow(
          window.open(SLACK_CONNECT_URL, "_blank", "height=800,width=525")
        );
      }}
    >
      <IntegrationCard.Image
        logoFileName={source.logoFileName}
        maxWidth={source.maxWidth}
      />
    </IntegrationCard>
  );
};

export default SlackIntegrationCard;
