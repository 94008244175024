import React from "react";
import { UIProps } from "components/base/types";
import DataTypeIcon from "components/DataTypeIcon";
import { DataSpecificType } from "types/dataType";

const Icon = ({
  dataType,
  ...props
}: { dataType: DataSpecificType } & UIProps) => {
  return <DataTypeIcon dataType={dataType} {...props} />;
};

export const buildIcon = (dataType: DataSpecificType) => (props: UIProps) => (
  <Icon dataType={dataType} {...props} />
);

export default Icon;
