import React from "react";

import { useResource, useFetcher } from "rest-hooks";

import DocumentationRequestResource, {
  DocumentationRequestStatus,
} from "resources/documentation-request";
import UsersResource from "resources/users";
import { enforceCasing, getTimeFromNow } from "utils";

import { H4, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import { Icon, Link, PersonRow } from "components/base";
import EntityContext from "contexts/EntityContext";
import ResolveEntityDocumentationButton from "components/RequestEntityDocumentationController/ResolveEntityDocumentationButton";

const DocumentationRequestCard = ({
  documentationRequest,
  organizationsUsers,
}: {
  documentationRequest: DocumentationRequestResource;
  organizationsUsers: UsersResource[];
}) => {
  const entity = documentationRequest.entityResource!;

  const resolveDocumentation = useFetcher(
    DocumentationRequestResource.resolve()
  );
  const isResolved =
    documentationRequest.status === DocumentationRequestStatus.Resolved;

  const EntityIcon = entity.Icon;

  const requesterUser = organizationsUsers.find(
    (user) => user.id === documentationRequest.createdByUserId
  );
  const resolverUser = organizationsUsers.find(
    (user) => user.id === documentationRequest.resolvedByUserId
  );

  return (
    <EntityContext.Provider value={{ entity }}>
      <Pane
        paddingX={22}
        paddingY={8}
        border="1px solid hsla(210, 25%, 90%, 1)"
        borderRadius={3}
        marginBottom={10}
        opacity={isResolved ? 0.5 : 1}
      >
        <Row>
          <Pane fontSize={14}>
            <Row
              centerY
              paddingBottom={2}
              opacity={0.8}
              textTransform="uppercase"
            >
              {entity.breadcrumbs
                .map<React.ReactNode>((parentEntity) => (
                  <Link to={parentEntity.catalogUrlPath}>
                    <Pane fontSize={12}>
                      {<Code>{parentEntity.name}</Code>}
                    </Pane>
                  </Link>
                ))
                .reduce((prev, curr) => [
                  prev,
                  <Span fontSize={12} marginX={5}>
                    /
                  </Span>,
                  curr,
                ])}
            </Row>

            <Link to={entity.catalogUrlPath}>
              <Row centerY marginTop={4} paddingY={1}>
                <EntityIcon marginRight={8} marginTop={1} fontSize={14} />
                <Span fontWeight={500}>{enforceCasing(entity.name)}</Span>
              </Row>
            </Link>

            <Pane>
              <div
                dangerouslySetInnerHTML={{
                  __html: documentationRequest.comment.commentHtml,
                }}
              />
            </Pane>
          </Pane>
          <Pane marginLeft="auto">
            <Pane>
              <Row
                centerY
                fontSize={14}
                transform="scale(0.9)"
                transformOrigin="right"
                opacity={0.8}
                justifyContent="flex-end"
              >
                {requesterUser && (
                  <PersonRow name={requesterUser.fullName} marginRight={0} />
                )}
                <Span marginX={5} fontSize={12} opacity={0.8}>
                  &bull;
                </Span>
                <Span opacity={0.6}>
                  {getTimeFromNow(documentationRequest.createdAt)}
                </Span>
              </Row>

              <Row justifyContent="flex-end" marginTop={4}>
                {isResolved ? (
                  resolverUser && (
                    <Row
                      fontSize={14}
                      transform="scale(0.9)"
                      transformOrigin="right"
                      opacity={0.8}
                      centerY
                    >
                      <Icon name="fa-check" marginRight={6} /> Resolved by
                      <PersonRow
                        name={resolverUser.fullName}
                        marginRight={0}
                        marginLeft={6}
                      />
                      <Span marginX={5} fontSize={12} opacity={0.8}>
                        &bull;
                      </Span>
                      <Span opacity={0.6}>
                        {getTimeFromNow(documentationRequest.updatedAt)}
                      </Span>
                    </Row>
                  )
                ) : (
                  <ResolveEntityDocumentationButton
                    color="#198A51"
                    resolveDocumentation={() =>
                      resolveDocumentation({ id: documentationRequest.id }, {})
                    }
                  />
                )}
              </Row>
            </Pane>
          </Pane>
        </Row>
      </Pane>
    </EntityContext.Provider>
  );
};

const sortDocumentationRequestsChronologicallyPendingFirst = (
  documentationRequestA: DocumentationRequestResource,
  documentationRequestB: DocumentationRequestResource
) => {
  if (documentationRequestA.status === documentationRequestB.status) {
    if (documentationRequestA.createdAt < documentationRequestB.createdAt) {
      return 1;
    } else if (
      documentationRequestA.createdAt > documentationRequestB.createdAt
    ) {
      return -1;
    }
    return 0;
  }
  if (documentationRequestA.status === DocumentationRequestStatus.Resolved) {
    return 1;
  }
  return -1;
};

const DocumentationRequestsSection = () => {
  const { results: organizationsUsers } = useResource(UsersResource.list(), {});

  const { results: documentationRequests } = useResource(
    DocumentationRequestResource.list(),
    {}
  );

  const sortedDocumentationRequests = [...documentationRequests].sort(
    sortDocumentationRequestsChronologicallyPendingFirst
  );

  return (
    <Pane flexGrow={1}>
      <Row>
        <H4 marginBottom={20}>Documentation requests</H4>
      </Row>
      {sortedDocumentationRequests.map((documentationRequest) => (
        <DocumentationRequestCard
          key={documentationRequest.id}
          documentationRequest={documentationRequest}
          organizationsUsers={organizationsUsers}
        />
      ))}
    </Pane>
  );
};

export default DocumentationRequestsSection;
