import React, { useState, RefObject } from "react";
import { useFetcher } from "rest-hooks";
import { useHistory, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

import { useDebouncedCallback } from "use-debounce";
import { getTimeFromNow, getHumanReadableDatetime } from "utils";
import { useKeyPress } from "utils/hooks";

import SearchResource from "resources/search";
import EntityType from "resources/entity-type";
import { UIProps } from "components/base/types";

import { AutoComplete, Input } from "antd";
import Select, { SelectValue } from "antd/es/select";
import { Link, Tag } from "components/base";
import { Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";

const buildEmptySearchResult = (query: string) => ({
  value: "",
  url: "#",
  label: <Row>No databases, schemas, tables or columns found for {query}</Row>,
});

function buildSearchResults(
  query: string,
  searchResults: SearchResource[],
  isGraphRoute: boolean
) {
  if (searchResults.length < 1) {
    return [buildEmptySearchResult(query)];
  }

  return searchResults
    .filter(
      (searchResultObject) =>
        !isGraphRoute ||
        searchResultObject.type === EntityType.Table ||
        searchResultObject.type === EntityType.Column
    )
    .map((searchResultObject) => {
      const searchResult = SearchResource.fromJS(searchResultObject);
      const url =
        (isGraphRoute
          ? searchResult.getGraphUrlPath()
          : searchResult.getCatalogUrlPath()) || "#";
      return {
        value: searchResult.getSlug() || "",
        url,
        label: (
          <Link active={false} unstyled={true} to={url}>
            <Row color="#171E26">
              <Code
                textTransform="uppercase"
                fontSize={11}
                opacity={0.5}
                color="#171E26"
              >
                {searchResult.getSlug()}
              </Code>
              {searchResult.attributes.sourceUpdatedAt && (
                <Span
                  marginLeft="auto"
                  opacity={0.9}
                  fontSize={12}
                  title={getHumanReadableDatetime(
                    searchResult.attributes.sourceUpdatedAt
                  )}
                >
                  {getTimeFromNow(searchResult.attributes.sourceUpdatedAt)}
                </Span>
              )}
            </Row>
            <Row color="#171E26">
              <Row
                fontWeight={500}
                flexShrink={1}
                textOverflow="ellipsis"
                overflow="hidden"
                textTransform="lowercase"
              >
                {searchResult.getName()}
                {searchResult.attributes.tags?.length > 0 && (
                  <Row marginLeft={8}>
                    {searchResult.attributes.tags.map((tagName, index) => (
                      <Tag key={index} marginLeft={2}>
                        {tagName}
                      </Tag>
                    ))}
                  </Row>
                )}
              </Row>
              <Code
                marginLeft="auto"
                textTransform="uppercase"
                fontSize={11}
                opacity={0.5}
                color="#171E26"
              >
                {searchResult.type}
              </Code>
            </Row>
          </Link>
        ),
      };
    });
}

const SearchBar = (props: UIProps) => {
  const isGraphRouteFullPath = !!useRouteMatch({
    path: "/relationships",
    sensitive: true,
  });
  const isGraphRouteShortPath = !!useRouteMatch({
    path: "/r",
    sensitive: true,
  });
  const isGraphRoute = isGraphRouteShortPath || isGraphRouteFullPath;

  const ref = React.useRef<Select<SelectValue>>(null);
  const divRef = React.useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<
    { value: string; label: JSX.Element }[]
  >([]);
  const [isSearching, setIsSearching] = useState(false);
  const search = useFetcher(SearchResource.list());
  const debouncedSearch = useDebouncedCallback((query, isGraphRoute) => {
    setIsSearching(true);
    search({ query }).then((response) => {
      setOptions(buildSearchResults(query, response.results, isGraphRoute));
      setIsSearching(false);
    });
  }, 500);

  const pressedSearchKey = useKeyPress("k", true);
  const pressedSearchKeyInSearch = useKeyPress("k", true, divRef);
  const pressedEscKey = useKeyPress("Escape", false, divRef);
  React.useEffect(() => {
    if (pressedSearchKey && ref.current) {
      ref.current.focus();
    }
  }, [ref, pressedSearchKey]);

  React.useEffect(() => {
    if ((pressedEscKey || pressedSearchKeyInSearch) && ref.current) {
      ref.current.blur();
    }
  }, [ref, pressedEscKey, pressedSearchKeyInSearch]);

  return (
    <Pane {...props} ref={divRef}>
      <SearchInput
        searchRef={ref}
        options={options}
        isSearching={isSearching}
        className={isGraphRoute && "relationship-search"}
        onSearch={(query) =>
          query && debouncedSearch.callback(query, isGraphRoute)
        }
        placeholder={
          isGraphRoute
            ? "Search tables in graph..."
            : "Search databases, tables, columns, schemas, and services..."
        }
        onSelect={() => setOptions([])}
        onBlur={() => setOptions([])}
      />
    </Pane>
  );
};

const SearchInput = ({
  options,
  searchRef,
  className,
  onSearch,
  isSearching,
  placeholder,
  onSelect,
  onBlur,
}: {
  options: { value: string; label: JSX.Element }[];
  searchRef: RefObject<Select<SelectValue>>;
  className: string | false;
  isSearching: boolean;
  placeholder: string;
  onSearch: (query: string) => void;
  onSelect: () => void;
  onBlur: () => void;
}) => {
  const [inputQuery, setInputQuery] = useState("");
  const history = useHistory();
  return (
    <AutoComplete
      ref={searchRef}
      className={classNames("header-search-bar", className)}
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      onSearch={onSearch}
      options={options}
      onSelect={(value: string, option: any) => {
        history.push(option?.url);
        setInputQuery("");
        onSelect();
      }}
      value={inputQuery}
      onChange={(query) => setInputQuery(query)}
      onBlur={() => {
        setInputQuery("");
        onBlur();
      }}
    >
      <Input.Search
        loading={isSearching}
        size="large"
        placeholder={placeholder}
        style={{
          fontFamily: `"Fira Code", source-code-pro, Menlo, Monaco, Consolas,
      "Courier New", monospace`,
        }}
      />
    </AutoComplete>
  );
};

export default SearchBar;
