import React, { useState, useEffect } from "react";

import { Graph } from "@antv/g6";
import { TableNode } from "components/SharedGraph/types";

import LineageResource from "resources/lineage";

import { Row } from "components/base/layout";

import buildGraphData from "./buildGraphData";
import buildGraphConfig from "./buildGraphConfig";
import filterGraphData from "./filterGraphData";

import GraphContext from "components/SharedGraph/GraphContext";
import GraphRenderer from "components/SharedGraph/GraphRenderer";
import EntityDetailBox from "components/SharedGraph/EntityDetailBox";
import initializeNodeClickListeners from "components/SharedGraph/listeners/initializeNodeClickListeners";

const LineageGraphController = ({
  lineage,
}: {
  lineage: LineageResource[];
}) => {
  const [graph, setGraph] = useState<Graph | null>(null);

  const allGraphData = React.useMemo(() => buildGraphData(lineage), [lineage]);

  const [focusedNodeId, setFocusedNodeId] = useState("");

  const filteredGraphData = React.useMemo(
    () => filterGraphData(allGraphData, focusedNodeId),
    [allGraphData, focusedNodeId]
  );

  const focusedNode = React.useMemo(
    () =>
      allGraphData.nodes.find((node: TableNode) => node.id === focusedNodeId),
    [allGraphData, focusedNodeId]
  );

  const onClickNode = (node: TableNode) => {
    setFocusedNodeId((nodeId) => (node.id === nodeId ? "" : node.id));
  };

  useEffect(() => {
    if (graph) {
      initializeNodeClickListeners(graph, onClickNode);
    }
  }, [graph]);

  useEffect(() => {
    if (graph) {
      graph.changeData(filteredGraphData);
    }
  }, [graph, filteredGraphData]);

  return (
    <GraphContext.Provider value={{ isGraphActive: true }}>
      <Row flexGrow={1} position="relative">
        <GraphRenderer
          graphHasData={allGraphData.nodes.length > 0}
          graphData={allGraphData}
          setGraph={setGraph}
          graph={graph}
          buildGraphConfig={buildGraphConfig}
        />
        {focusedNode && <EntityDetailBox focusedNode={focusedNode} />}
      </Row>
    </GraphContext.Provider>
  );
};

export default LineageGraphController;
