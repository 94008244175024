import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";

export default class GithubResource extends AuthenticatedResource {
  readonly sourceId: string = "";
  readonly installationId: string = "";
  readonly repositories: {
    id: string;
    name: string;
    branches: {
      name: string;
    }[];
  }[] = [];

  pk() {
    return this.installationId?.toString();
  }

  static urlRoot = buildApiUri("dbt");

  static detail<T extends typeof Resource>(this: T) {
    return super.detail().extend({
      schema: this,
      url({ sourceId }) {
        return `${GithubResource.urlRoot}/github/?sourceId=${sourceId}`;
      },
    });
  }
}
