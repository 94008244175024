import React, { useState } from "react";
import { Store } from "antd/lib/form/interface";
import { useResource, useFetcher } from "rest-hooks";

import SourcesResource from "resources/sources";

import { Pane } from "components/base/layout";
import { MetaTitle } from "components/base";

import { Connection } from "types/Connection";
import YourConnectionsSection from "./YourConnectionsSection";
import AvailableConnectionsSection from "./AvailableConnectionsSection";
import AddConnectionDrawer from "./AddConnectionDrawer";

const ConnectionsPage = () => {
  const [selectedConnection, setConnection] = useState<Connection>();

  const sourcesResourceList = SourcesResource.list();
  const { results: connections } = useResource(sourcesResourceList, {});
  const createSource = useFetcher(SourcesResource.create());

  const onSaveConnection = (values: Store) => {
    if (!selectedConnection) {
      throw new Error("No connection selected");
    }

    const { name, ...credentials } = values;

    createSource(
      {},
      {
        type: selectedConnection.type,
        name,
        credentials,
      },
      [
        [
          sourcesResourceList,
          {},
          (
            newSourceId: string,
            sourceIds: { results: string[] } | undefined
          ) => ({
            ...sourceIds,
            results: [
              ...(sourceIds?.results ? sourceIds.results : []),
              newSourceId,
            ],
          }),
        ],
      ]
    );
    setConnection(undefined);
  };

  return (
    <Pane paddingX={72} paddingY={45}>
      <MetaTitle>Connections</MetaTitle>
      <YourConnectionsSection connections={connections} />
      <AvailableConnectionsSection setConnection={setConnection} />
      <AddConnectionDrawer
        selectedConnection={selectedConnection}
        onSaveConnection={onSaveConnection}
        onClose={() => {
          setConnection(undefined);
        }}
      />
    </Pane>
  );
};

export default ConnectionsPage;
