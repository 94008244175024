import React from "react";

import { UIProps } from "components/base/types";
import { MiniIconButton } from "components/base";

export const EntityDocumentationRequestedLabel = ({
  iconOnly,
  ...props
}: {
  iconOnly?: boolean;
} & UIProps) => (
  <MiniIconButton
    iconType="far"
    iconName="fa-exclamation-circle"
    color="hsl(36deg 80% 39% / 60%)"
    className="alwaysVisible"
    disabled
    title="Documentation requested"
    {...props}
  >
    {iconOnly ? null : "Requested"}
  </MiniIconButton>
);

export default EntityDocumentationRequestedLabel;
