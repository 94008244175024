import { StrictGraphData, CentralityMeasuresType } from "./types";

export function calculateDegreeCentrality(graphData: StrictGraphData) {
  let edgeCounts: CentralityMeasuresType = {};
  const { edges } = graphData;

  edges.forEach((edge) => {
    const sourceNodeId = edge.source;
    const targetNodeId = edge.target;

    if (sourceNodeId in edgeCounts) {
      edgeCounts[sourceNodeId] += 1;
    } else {
      edgeCounts[sourceNodeId] = 1;
    }

    if (targetNodeId in edgeCounts) {
      edgeCounts[targetNodeId] += 1;
    } else {
      edgeCounts[targetNodeId] = 1;
    }
  });
  return edgeCounts;
}
