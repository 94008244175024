import { createContext } from "react";
import TagsResource from "resources/tags";

export const EntityTagsContext = createContext<{
  availableTags: TagsResource[];
  entitysTagIds: string[];
}>({
  availableTags: [],
  entitysTagIds: [],
});

export default EntityTagsContext;
