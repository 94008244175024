import React, { useState } from "react";

import { useResource } from "rest-hooks";

import TablesResource from "resources/tables";

import { getSortedEntitiesByName } from "utils";

import { withLoader } from "components/base";
import ShowHiddenEntitiesPane from "components/ShowHiddenEntitiesPane";
import TableRow from "./TableRow";

const SchemaTablesList = ({
  schemaId,
  expandable,
  onClick,
}: {
  schemaId: string;
  expandable?: boolean;
  onClick?: (table: TablesResource) => void;
}) => {
  const { results: tables } = useResource(TablesResource.list(), {
    schemaId,
    take: 500,
  });
  const [showHiddenTables, setShowHiddenTables] = useState(false);

  const sortedTables = getSortedEntitiesByName<TablesResource>(tables);

  const hiddenTables = sortedTables.filter((table) => table.hidden);
  const visibleTables = sortedTables.filter((table) => !table.hidden);

  return (
    <>
      {visibleTables.map((table: TablesResource) => (
        <TableRow key={table.id} table={table} />
      ))}
      {!showHiddenTables && !!hiddenTables.length && (
        <ShowHiddenEntitiesPane
          numHiddenEntities={hiddenTables.length}
          showHiddenEntities={() => setShowHiddenTables(true)}
        />
      )}
      {showHiddenTables &&
        hiddenTables.map((table: TablesResource) => (
          <TableRow key={table.id} table={table} />
        ))}
    </>
  );
};

export default withLoader(SchemaTablesList, { minHeight: 100 });
