interface Role {
  name: string;
  permissions: string[];
}

export class User {
  readonly id: string;
  readonly authProviderUserId: string | undefined;
  readonly accessToken: string | undefined;
  readonly email: string = "";
  readonly organization: { domain: string; id: string };
  readonly role: Role | undefined;

  private constructor({
    id,
    authProviderUserId,
    accessToken,
    email,
    organization,
    role,
  }: {
    id: string;
    authProviderUserId: string;
    accessToken: string;
    email: string;
    organization: { domain: string; id: string };
    role: Role;
  }) {
    this.id = id;
    this.authProviderUserId = authProviderUserId;
    this.accessToken = accessToken;
    this.email = email;
    this.organization = organization;
    this.role = role;
  }

  get canWriteDescriptions() {
    return this.role?.permissions.includes("write:descriptions") || false;
  }

  get canWriteTags() {
    return this.role?.permissions.includes("write:tags") || false;
  }

  get canWriteOwners() {
    return this.role?.permissions.includes("write:owners") || false;
  }

  get canWriteRelationships() {
    return this.role?.permissions.includes("write:relationships") || false;
  }

  get canWriteEntityVisibility() {
    return this.role?.permissions.includes("write:descriptions") || false;
  }

  get canWriteEntityImportance() {
    return this.role?.permissions.includes("write:descriptions") || false;
  }

  get canResolveDocumentation() {
    return this.role?.permissions.includes("write:descriptions") || false;
  }

  get isMetaplaneSuperAdmin() {
    return this.email.includes("@metaplane.dev");
  }

  get canViewSQLBuilderMode() {
    return (
      this.isMetaplaneSuperAdmin ||
      this.organization?.domain === "ginkgobioworks.com"
    );
  }

  static fromJS(object: {
    id: string;
    authProviderUserId: string;
    accessToken: string;
    email: string;
    organization: { domain: string; id: string };
    role: Role;
  }) {
    const user = new User(object);
    return user;
  }
}
