import { createContext } from "react";
import EntitiesResource from "resources/entities";

export const SidebarContext = createContext<{
  defaultEntities?: EntitiesResource[];
}>({
  defaultEntities: undefined,
});

export default SidebarContext;
