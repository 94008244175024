import React from "react";
import { useParams } from "react-router-dom";
import { useResource, useFetcher } from "rest-hooks";

import {
  enforceCasing,
  getHumanReadableDatetime,
  getTimeFromNow,
  getSortedEntitiesByName,
} from "utils";

import SchemasResource from "resources/schemas";
import CustomField from "types/CustomField";

import { MetaTitle, Stat, Link } from "components/base";
import { H2, H4, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import SchemaTablesList from "components/SchemaTablesList";
import RequestEntityDocumentationController from "components/RequestEntityDocumentationController";
import EntityContext from "contexts/EntityContext";
import {
  ProtectedEntityDescription,
  ProtectedEntityImportanceToggle,
} from "components/protected";

const SchemaPage = () => {
  const { databaseName, schemaName } = useParams<{
    databaseName: string;
    schemaName: string;
  }>();

  const [
    {
      results: [schema],
    },
  ] = useResource([
    SchemasResource.detailByName(),
    {
      name: schemaName,
      databaseName,
    },
  ]);

  const updateSchema = useFetcher(SchemasResource.partialUpdate());
  const requestDocumentation = useFetcher(
    SchemasResource.requestDocumentation()
  );
  const resolveDocumentation = useFetcher(
    SchemasResource.resolveDocumentation()
  );

  const sortedCustomFields = getSortedEntitiesByName<CustomField>(
    schema.customFields
  );

  return (
    <EntityContext.Provider value={{ entity: schema }}>
      <Row>
        <Pane paddingX={35} paddingY={22} flexGrow={1}>
          <MetaTitle>{enforceCasing(schema.name)}</MetaTitle>
          <Row>
            <Pane>
              <Row
                centerY
                marginBottom={2}
                opacity={0.8}
                textTransform="uppercase"
              >
                <Link to={schema.databaseResource.catalogUrlPath}>
                  <Pane fontSize={12}>
                    {<Code>{schema.database.name}</Code>}
                  </Pane>
                </Link>
                <Span fontSize={12} marginX={5}>
                  /
                </Span>
                <Pane fontSize={12}>{<Code>Schema</Code>}</Pane>
              </Row>

              <H2 display="flex" alignItems="center" marginBottom={21}>
                <SchemasResource.Icon marginRight={8} fontSize={17} />
                {enforceCasing(schema.name)}
              </H2>
            </Pane>
            <Pane marginLeft="auto">
              <Row>
                <Stat name="Tables">{schema.statistics.table_count}</Stat>
                {schema.sourceCreatedAt && (
                  <Stat
                    name="Created"
                    title={getHumanReadableDatetime(schema.sourceCreatedAt)}
                  >
                    {getTimeFromNow(schema.sourceCreatedAt)}
                  </Stat>
                )}
                {schema.sourceUpdatedAt && (
                  <Stat
                    name="Updated"
                    title={getHumanReadableDatetime(schema.sourceUpdatedAt)}
                  >
                    {getTimeFromNow(schema.sourceUpdatedAt)}
                  </Stat>
                )}

                {sortedCustomFields.map((customField) => (
                  <Stat name={customField.name}>{customField.value}</Stat>
                ))}
                <RequestEntityDocumentationController
                  documentationRequests={schema.documentationRequests}
                  requestDocumentation={() => {
                    requestDocumentation({ id: schema.id }, {});
                  }}
                  resolveDocumentation={() => {
                    resolveDocumentation({ id: schema.id }, {});
                  }}
                />
                <ProtectedEntityImportanceToggle
                  marginLeft={8}
                  isImportant={schema.isImportant}
                  toggleEntityIsImportant={(isImportant: boolean) =>
                    updateSchema({ id: schema.id }, { isImportant })
                  }
                />
              </Row>
            </Pane>
          </Row>
          <Row>
            <Pane width={450}>
              <ProtectedEntityDescription
                paddingBottom={10}
                description={schema.description}
                onChange={(description) => {
                  updateSchema({ id: schema.id }, { description });
                }}
              />
            </Pane>
          </Row>

          <Pane marginTop={10}>
            <H4>Tables</H4>

            <Pane marginTop={16} borderTop="1px solid #eee">
              <SchemaTablesList schemaId={schema.id} />
            </Pane>
          </Pane>
        </Pane>
      </Row>
    </EntityContext.Provider>
  );
};

export default SchemaPage;
