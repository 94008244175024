import React from "react";
import { Pane, Row, Span } from "components/base/layout";
import { Icon } from "components/base";
import { Code } from "components/base/type";

const TableMenuItemContents = ({
  tableName,
  schemaName,
  databaseName,
  isImportant,
}: {
  tableName: string;
  schemaName: string;
  databaseName: string;
  isImportant?: boolean;
}) => (
  <Pane>
    <Row color="#171E26" centerY>
      <Code
        textTransform="uppercase"
        fontSize={11}
        opacity={0.5}
        color="#171E26"
      >
        {databaseName}.{schemaName}
      </Code>
      {isImportant && (
        <Icon
          marginTop={-2}
          marginLeft={6}
          fontSize={6}
          type="fas"
          name="fa-circle"
          color={"hsl(51 100% 48% / 1)"}
          title="Marked as important"
        />
      )}
    </Row>
    <Row color="#171E26">
      <Span
        fontWeight={500}
        flexShrink={1}
        textOverflow="ellipsis"
        overflow="hidden"
        textTransform="lowercase"
      >
        {tableName}
      </Span>
    </Row>
  </Pane>
);

export default TableMenuItemContents;
