import { buildApiUri } from "resources/api-uri";
import { useAuth } from "authentication";

export const useTrackEvent = () => {
  const { user } = useAuth();
  return (eventName: string, properties: Record<string, unknown>) =>
    fetch(buildApiUri("track/event"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        organizationId: user.organization.id,
        eventName,
        properties,
      }),
    });
};
