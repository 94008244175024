import { Resource } from "@rest-hooks/rest";
import { OutputBlockData } from "quantifai-editorjs";

import { buildApiUri } from "./api-uri";
import AuthenticatedResource from "./AuthenticatedResource";
import EntitiesResource from "./entities";
import EntityType from "./entity-type";
import SchemasResource from "./schemas";
import TablesResource from "./tables";
import ColumnsResource from "./columns";

export default class DocumentationRequestResource extends AuthenticatedResource {
  readonly id?: string = undefined;
  readonly entity: { id: string } | undefined = undefined;
  readonly createdByUserId: string | undefined = undefined;
  readonly resolvedByUserId: string | undefined = undefined;
  readonly createdAt: Date = new Date();
  readonly updatedAt: Date | undefined = undefined;
  readonly status: "pending" | "resolved" | undefined = undefined;
  readonly targetType:
    | EntityType.Schema
    | EntityType.Table
    | EntityType.Column
    | undefined = undefined;
  readonly comment: {
    commentBlocks: OutputBlockData[];
    commentHtml: string;
    commentText: string;
  } = {
    commentBlocks: [],
    commentHtml: "",
    commentText: "",
  };

  static urlRoot = buildApiUri("documentation-requests");

  pk() {
    return this.id?.toString();
  }

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static resolve<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      url({ id }) {
        return `${DocumentationRequestResource.urlRoot}/${id}/resolve`;
      },
    });
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      optimisticUpdate: (params: any, body: any) => ({
        id: params.id,
        ...body,
      }),
    });
  }

  get entityResource() {
    if (this.targetType && this.entity) {
      return EntitiesResource.getEntityResource(
        this.targetType,
        this.entity
      ) as SchemasResource | TablesResource | ColumnsResource;
    }
    return undefined;
  }
}

export enum DocumentationRequestStatus {
  Pending = "pending",
  Resolved = "resolved",
}
