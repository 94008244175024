import { Util as AntVUtil } from "@antv/g6";

export const getTruncatedTextOld = (
  text: string,
  maxWidth: number,
  fontSize: number
) => {
  let currentWidth = 0;
  let truncatedText = text;
  const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese characters and letters
  text.split("").forEach((letter, i) => {
    if (currentWidth > maxWidth) return;
    if (pattern.test(letter)) {
      // Chinese characters
      currentWidth += fontSize;
    } else {
      // get the width of single letter according to the fontSize
      currentWidth += AntVUtil.getLetterWidth(letter, fontSize);
    }
    if (currentWidth > maxWidth) {
      truncatedText = `${text.substr(0, i)}\n${text.substr(i)}`;
    }
  });
  return truncatedText;
};

export const getTruncatedText = (
  text: string,
  maxWidth: number,
  fontSize: number
) => {
  let currentWidth = 0;
  let truncatedText = text;
  const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese characters and letters
  text.split("").forEach((letter, i) => {
    if (currentWidth > maxWidth) return;
    if (pattern.test(letter)) {
      // Chinese characters
      currentWidth += fontSize;
    } else {
      // get the width of single letter according to the fontSize
      currentWidth += AntVUtil.getLetterWidth(letter, fontSize);
    }
    if (currentWidth > maxWidth) {
      truncatedText = `${text.substr(0, i)}\n${text.substr(i)}`;
    }
  });
  return truncatedText;
};
