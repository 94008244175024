import { createContext } from "react";

const SidebarExpandableSectionContext = createContext<{
  active: boolean;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  active: false,
  expanded: false,
  setExpanded: () => {},
});

export default SidebarExpandableSectionContext;
