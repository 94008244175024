import React from "react";
import { MiniIconButton } from "components/base";
import { UIProps } from "components/base/types";
import { useEntityType } from "contexts/EntityContext";

export const EntityVisibilityToggle = ({
  isHidden,
  onToggle,
  ...props
}: {
  isHidden: boolean;
  onToggle: (newIsHidden: boolean) => void;
} & UIProps) => {
  const entityType = useEntityType();
  return (
    <MiniIconButton
      title={`${isHidden ? "Unhide" : "Hide"} ${entityType}`}
      onClick={() => {
        onToggle(!isHidden);
      }}
      iconType="fa"
      iconName={isHidden ? "fa-eye" : "fa-eye-slash"}
      tooltipProps={{ placement: "topRight" }}
      {...props}
    />
  );
};

export default EntityVisibilityToggle;
