import React from "react";
import styled from "styled-components";

import { Pane } from "components/base/layout";
import { useEntityType } from "contexts/EntityContext";

export const ShowHiddenEntitiesPane = ({
  numHiddenEntities,
  showHiddenEntities,
}: {
  numHiddenEntities: number;
  showHiddenEntities: () => void;
}) => {
  const entityType = useEntityType();
  return (
    <StyledTogglePane
      onClick={() => showHiddenEntities()}
      paddingY={10}
      paddingX={43}
      fontSize={12}
      fontWeight={500}
      cursor="pointer"
      backgroundColor="rgba(0, 0, 0, 0.02)"
    >
      View {numHiddenEntities} hidden {entityType}s
    </StyledTogglePane>
  );
};

const StyledTogglePane = styled(Pane)`
  opacity: 0.5;

  &:hover {
    opacity: 0.7;
  }
`;

export default ShowHiddenEntitiesPane;
