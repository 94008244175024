import React, { useState } from "react";

import { useResource } from "rest-hooks";

import DatabasesResource from "resources/databases";
import SchemasResource from "resources/schemas";

import { getSortedEntitiesByName } from "utils";

import { withLoader } from "components/base";
import ShowHiddenEntitiesPane from "components/ShowHiddenEntitiesPane";
import SchemaRow from "./SchemaRow";

const DatabaseSchemasList = ({ database }: { database: DatabasesResource }) => {
  const { results: schemas } = useResource(SchemasResource.list(), {
    databaseId: database.id,
  });
  const [showHiddenSchemas, setShowHiddenSchemas] = useState(false);

  const sortedSchemas = getSortedEntitiesByName<SchemasResource>(schemas);

  const hiddenSchemas = sortedSchemas.filter((schema) => schema.hidden);
  const visibleSchemas = sortedSchemas.filter((schema) => !schema.hidden);

  return (
    <>
      {visibleSchemas.map((schema: SchemasResource) => (
        <SchemaRow key={schema.id} schema={schema} />
      ))}
      {!showHiddenSchemas && !!hiddenSchemas.length && (
        <ShowHiddenEntitiesPane
          numHiddenEntities={hiddenSchemas.length}
          showHiddenEntities={() => setShowHiddenSchemas(true)}
        />
      )}
      {showHiddenSchemas &&
        hiddenSchemas.map((schema: SchemasResource) => (
          <SchemaRow key={schema.id} schema={schema} />
        ))}
    </>
  );
};

export default withLoader(DatabaseSchemasList);
