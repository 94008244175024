import React from "react";

import SourcesResource from "resources/sources";

import { Pane } from "components/base/layout";

import ConnectionCardHeader from "./ConnectionCardHeader";
import ConnectionCardTabs from "./ConnectionCardTabs";

import ConnectionCardContext from "./ConnectionCardContext";

const ConnectionCard = ({ connection }: { connection: SourcesResource }) => {
  return (
    <ConnectionCardContext.Provider value={{ connection }}>
      <Pane
        paddingTop={22}
        paddingX={22}
        paddingBottom={14}
        border="1px solid hsla(210, 25%, 90%, 1)"
        borderRadius={6}
        width={350}
        marginRight={24}
        marginBottom={24}
      >
        <ConnectionCardHeader />
        <ConnectionCardTabs />
      </Pane>
    </ConnectionCardContext.Provider>
  );
};

export default ConnectionCard;
