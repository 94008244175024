/// <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    Cohere: {
      identify: (userId: string, traits?: object) => void;
    };
  }
}

export const identifyUserForSegment = (
  userId: string,
  traits?: Object,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void
) => {
  if (window.Cohere?.identify) {
    window.Cohere.identify(userId, traits);
  }

  if (window.analytics?.identify) {
    return window.analytics?.identify(userId, traits, options, callback);
  }
};

export default identifyUserForSegment;
