import { Graph } from "@antv/g6";
import { IG6GraphEvent } from "@antv/g6/lib/types";
import { INode } from "@antv/g6/lib/interface/item";
import { TableRelationshipEdge } from "../types";
import {
  showFullNodeLabel,
  hideFullNodeLabel,
  showEdgeLabel,
  hideEdgeLabel,
  lightenGraphNodeLabels,
  lightenGraphNodeOpacity,
  darkenGraphNodeLabels,
  darkenGraphNodeOpacity,
  resetGraph,
} from "./utils";

const initializeNodeHoverListeners = (graph: Graph) => {
  graph.on("node:mouseenter", (event: IG6GraphEvent) => {
    if (!event.item) {
      return;
    }
    const node = event.item as INode;
    const nodeModel = node.getModel();
    const edges = node.getEdges();
    node.toFront();
    const connectedNodeIdsSet = new Set<string>();

    graph.setItemState(node, "active", true);

    edges.forEach((edge) => {
      const edgeModel = edge.getModel();
      const {
        source: sourceNodeId,
        target: targetNodeId,
      } = edgeModel as TableRelationshipEdge;
      connectedNodeIdsSet.add(sourceNodeId);
      connectedNodeIdsSet.add(targetNodeId);
      edge.toFront();

      let edgeLength = 80;
      const { startPoint, endPoint } = edgeModel;
      if (startPoint && endPoint) {
        edgeLength =
          Math.sqrt(
            Math.pow(Math.abs(startPoint.x - endPoint.x), 2) +
              Math.pow(Math.abs(startPoint.y - endPoint.y), 2)
          ) * 1.2;
      }
      showEdgeLabel(graph, edge, edgeLength);
    });
    showFullNodeLabel(graph, node);

    const allNodes = graph.getNodes();
    lightenGraphNodeLabels(graph, allNodes);
    lightenGraphNodeOpacity(graph, allNodes);

    const connectedNodeIds = Array.from(connectedNodeIdsSet);
    darkenGraphNodeLabels(graph, [nodeModel.id!, ...connectedNodeIds]);
    darkenGraphNodeOpacity(graph, [nodeModel.id!, ...connectedNodeIds]);
  });

  graph.on("node:mouseleave", (event: IG6GraphEvent) => {
    if (!event.item) {
      return;
    }
    const node = event.item as INode;
    const edges = node.getEdges();
    edges.forEach((edge) => hideEdgeLabel(graph, edge));
    resetGraph(graph);

    hideFullNodeLabel(graph, node);
  });
};

export default initializeNodeHoverListeners;
