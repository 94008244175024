import React, { useState } from "react";

import { useAuth } from "authentication";
import DocumentationRequest from "resources/documentation-request";
import EntityDocumentationRequestedLabel from "./EntityDocumentationRequestedLabel";
import RequestEntityDocumentationButton from "./RequestEntityDocumentationButton";
import ResolveEntityDocumentationButton from "./ResolveEntityDocumentationButton";
import UpdateRequestEntityDocumentationButton from "./UpdateRequestEntityDocumentationButton";

import { Row } from "components/base/layout";
import { UIProps } from "components/base/types";

const RequestEntityDocumentationController = ({
  documentationRequests,
  requestDocumentation,
  resolveDocumentation,
  iconOnly,
  ...props
}: {
  documentationRequests: DocumentationRequest[];
  requestDocumentation: () => void;
  resolveDocumentation: () => void;
  iconOnly?: boolean;
} & UIProps) => {
  const { user } = useAuth();
  const [justRequestedDocumentation, setJustRequestedDocumentation] = useState(
    false
  );

  const pendingDocumentationRequests = (documentationRequests || []).filter(
    (docRequest) => docRequest.status === "pending"
  );
  const pendingDocumentationRequestByUser = pendingDocumentationRequests.find(
    (docRequest) => docRequest.createdByUserId === user.id
  );

  const hasPendingDocumentationRequests =
    pendingDocumentationRequests.length > 0;

  return (
    <Row {...props}>
      {hasPendingDocumentationRequests && (
        <EntityDocumentationRequestedLabel iconOnly={iconOnly} />
      )}

      {pendingDocumentationRequestByUser ? (
        <UpdateRequestEntityDocumentationButton
          documentationRequest={pendingDocumentationRequestByUser}
          iconOnly={iconOnly}
          marginLeft={8}
          autoOpen={justRequestedDocumentation}
        />
      ) : (
        <RequestEntityDocumentationButton
          requestDocumentation={requestDocumentation}
          iconOnly={iconOnly}
          marginLeft={hasPendingDocumentationRequests ? 8 : 0}
          onClick={() => setJustRequestedDocumentation(true)}
        />
      )}

      {hasPendingDocumentationRequests && user.canResolveDocumentation && (
        <ResolveEntityDocumentationButton
          marginLeft={8}
          resolveDocumentation={resolveDocumentation}
          iconOnly={iconOnly}
        />
      )}
    </Row>
  );
};

export default RequestEntityDocumentationController;
