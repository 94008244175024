import React, { useState, useContext } from "react";
import { useFetcher } from "rest-hooks";
import classNames from "classnames";
import styled from "styled-components";
import { toaster } from "evergreen-ui";

import SourcesResource from "resources/sources";

import { Pane } from "components/base/layout";
import { Icon } from "components/base";
import { UIProps } from "components/base/types";

import ConnectionCardContext from "../ConnectionCardContext";

const SyncButton = (props: UIProps) => {
  const { connection } = useContext(ConnectionCardContext);
  const syncSource = useFetcher(SourcesResource.sync());

  const [isSyncing, setIsSyncing] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const onButtonClick = async () => {
    if (!isSyncing) {
      setIsSyncing(true);
      try {
        await syncSource({ id: connection.id });
        setIsComplete(true);
        setIsSyncing(false);
      } catch (e) {
        toaster.danger("Attempt to sync failed -- please try again.");
        console.error(e);
        setIsSyncing(false);
      }
    }
  };

  let buttonText = "Sync";
  if (isComplete) {
    buttonText = "Synced";
  } else if (isSyncing) {
    buttonText = "Syncing...";
  }

  return (
    <StyledSyncButton
      fontSize={12}
      borderRadius={40}
      paddingY={1}
      border="1px solid currentcolor"
      paddingX={8}
      color="white"
      fontWeight={500}
      textTransform="uppercase"
      letterSpacing={0.3}
      whiteSpace="nowrap"
      {...props}
      onClick={onButtonClick}
      className={classNames(isSyncing && "syncing", isComplete && "complete")}
    >
      {buttonText}{" "}
      <Icon
        name={isComplete ? "fa-check" : "fa-sync-alt"}
        type="far"
        className={classNames(isSyncing && "fa-spin")}
      />
    </StyledSyncButton>
  );
};

const StyledSyncButton = styled(Pane)`
  background-color: white;
  color: hsla(209, 100%, 55%, 1);
  transition: color 0.2s ease, background-color 0.2s ease;
  &:not(.syncing) {
    cursor: pointer;
  }
  &:hover {
    color: hsla(209, 100%, 35%, 1);
  }

  &.syncing,
  &.syncing:hover {
    color: hsla(209, 50%, 63%, 1);
  }

  &.complete,
  &.complete:hover {
    background-color: #3dbf29;
    color: white;
    border-color: #3dbf29;
  }
`;

export default SyncButton;
