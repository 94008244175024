import React from "react";
import classNames from "classnames";
import { Icon, MiniButton, Tooltip } from "components/base";
import { TooltipProps } from "components/base/Tooltip";
import { Span } from "components/base/layout";
import { UIProps } from "components/base/types";
import { useIsOnboarding } from "utils/hooks";

export type MiniIconButtonProps = {
  iconName?: string;
  iconType?: string;
  iconProps?: UIProps;
  tooltipProps?: Partial<TooltipProps>;
  noExpansion?: boolean;
} & UIProps;

export const MiniIconButton = ({
  title,
  iconName,
  iconType,
  iconProps,
  tooltipProps,
  className,
  children,
  noExpansion,
  ...props
}: MiniIconButtonProps) => {
  const isOnboarding = useIsOnboarding();
  return (
    <Tooltip title={title} {...tooltipProps}>
      <MiniButton
        className={classNames("visibleOnParentHover", className)}
        {...props}
      >
        {iconName && (
          <Icon
            type={iconType}
            name={iconName}
            fixedWidth
            minWidth={15}
            minHeight={12}
            opacity={0.8}
            flexShrink={0}
            {...iconProps}
          />
        )}
        {!children && !noExpansion && isOnboarding && (
          <Span marginLeft={iconName ? 6 : 0}>{title}</Span>
        )}
        {children && <Span marginLeft={iconName ? 6 : 0}>{children}</Span>}
      </MiniButton>
    </Tooltip>
  );
};

export default MiniIconButton;
