import React from "react";
import styled from "styled-components";
import { UIProps } from "components/base/types";
import { Row } from "components/base/layout";
import classNames from "classnames";

export enum Size {
  MD = "md",
  LG = "lg",
}

const SIZE_STYLES = {
  [Size.MD]: {
    fontSize: 12,
    height: 24,
    paddingY: 0,
    paddingX: 6,
  },
  [Size.LG]: {
    fontSize: 13,
    height: "auto",
    paddingY: 4,
    paddingX: 8,
  },
};

export const MiniButton = ({
  size,
  active,
  className,
  disabled,
  ...props
}: { size?: Size; active?: boolean; disabled?: boolean } & UIProps) => (
  <StyledMiniButton
    role="button"
    outline="none"
    tabIndex="0"
    userSelect="none"
    transition="background 20ms ease-in 0s"
    cursor={disabled ? "default" : "pointer"}
    display="inline-flex"
    center
    borderRadius={3}
    fontWeight={600}
    color={disabled ? "hsla(212, 25%, 12%, 0.4)" : "hsla(212, 25%, 12%, 0.6)"}
    className={classNames(
      className,
      active && "active",
      disabled && "disabled"
    )}
    {...SIZE_STYLES[size || Size.MD]}
    {...props}
  />
);

const StyledMiniButton = styled(Row)`
  &:not(.disabled) {
    &:hover {
      background: hsla(212, 25%, 12%, 0.08);
    }
    &.active {
      color: rgb(46, 170, 220);
      background-color: rgba(46, 170, 220, 0.07);
      &:hover {
        background-color: rgba(46, 170, 220, 0.1);
      }
    }
  }
`;

MiniButton.Size = Size;

export default MiniButton;
