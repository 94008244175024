import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import { TagEntityType } from "./tag-entity-type";

enum TagCreatedMethod {
  User = "user",
  Auto = "auto",
}

export default class TagsResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly name: string = "";
  readonly type: TagEntityType | undefined = undefined;
  readonly createdMethod: TagCreatedMethod | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("tags");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this] },
    });
  }
}

export const entityTagsListUpdater = (
  response: { tag: any },
  sourceIds: { results: string[] } | undefined
) => ({
  ...sourceIds,
  results: [...(sourceIds?.results ? sourceIds.results : []), response.tag],
});
