import React from "react";

import { TableNode } from "../types";

import { withLoader, OverlayPanel } from "components/base";
import { Column } from "components/base/layout";

import EntityDetailBoxContents from "./EntityDetailBoxContents";
import EntityDetailBoxContentsPlaceholder from "./EntityDetailBoxContentsPlaceholder";

const EntityDetailBoxContentsLoader = withLoader(
  EntityDetailBoxContents,
  {},
  EntityDetailBoxContentsPlaceholder
);

const EntityDetailBox = ({ focusedNode }: { focusedNode: TableNode }) => (
  <OverlayPanel.Positioner
    is={Column}
    top={20}
    right={20}
    bottom={20}
    width={400}
  >
    <OverlayPanel paddingTop={20} paddingX={20} paddingBottom={0}>
      <EntityDetailBoxContentsLoader focusedNode={focusedNode} />
    </OverlayPanel>
  </OverlayPanel.Positioner>
);

export default EntityDetailBox;
