import { useEntityIdentifiers } from "contexts/EntityContext";
import { useTrackEvent } from "./useTrackEvent";

export const useTrackWithEntity = () => {
  const { entityId, entityType } = useEntityIdentifiers();
  const trackEvent = useTrackEvent();
  return (event: string, properties?: Record<string, unknown>) =>
    trackEvent(event, { entityId, entityType, ...properties });
};

export default useTrackWithEntity;
