import React from "react";
import { FormInstance } from "antd/es/form";
import { Connection, ConnectionType } from "types/Connection";
import BigQueryConnectionFormItems from "./BigQueryConnectionFormItems";
import DbtConnectionFormItems from "./DbtConnectionFormItems";
import SnowflakeConnectionFormItems from "./SnowflakeConnectionFormItems";
import StandardConnectionFormItems from "./StandardConnectionFormItems";

const ConnectionFormItems = ({
  connection,
  form,
}: {
  connection: Connection;
  form: FormInstance;
}) => {
  switch (connection.type) {
    case ConnectionType.BigQuery:
      return <BigQueryConnectionFormItems />;
    case ConnectionType.Snowflake:
      return <SnowflakeConnectionFormItems />;
    case ConnectionType.Dbt:
      return <DbtConnectionFormItems form={form} />;
    case ConnectionType.MySQL:
      return (
        <StandardConnectionFormItems
          connection={connection}
          portPlaceholder={3306}
        />
      );
    default:
      return <StandardConnectionFormItems connection={connection} />;
  }
};

export default ConnectionFormItems;
