import React, { useState, useEffect } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Pane, Column, Row, Span } from "components/base/layout";
import { Code } from "components/base/type";
import { Icon, Link, MiniButton, OverlayPanel, Tooltip } from "components/base";
import { UIProps } from "components/base/types";
import {
  TableNode,
  GraphControlsType,
  StrictGraphData,
} from "components/SharedGraph/types";

const GraphControls = ({
  currentGraphData,
  controls,
  setControls,
  hiddenSchemaIds,
  setHiddenSchemaIds,
  hiddenTableIds,
  setHiddenTableIds,
  focusedNode,
}: {
  controls: GraphControlsType;
  setControls: React.Dispatch<React.SetStateAction<GraphControlsType>>;
  currentGraphData: StrictGraphData | undefined;
  hiddenSchemaIds: string[];
  setHiddenSchemaIds: React.Dispatch<string[]>;
  hiddenTableIds: string[];
  setHiddenTableIds: React.Dispatch<string[]>;
  focusedNode?: TableNode;
}) => {
  return (
    <OverlayPanel.Positioner
      is={Column}
      top={20}
      left={20}
      right={500}
      bottom={240}
    >
      <GraphControlOverlayPanel
        controls={controls}
        setControls={setControls}
        hasFocusedNode={!!focusedNode}
      />
      <FilterOverlayPanel
        currentGraphData={currentGraphData}
        hiddenSchemaIds={hiddenSchemaIds}
        setHiddenSchemaIds={setHiddenSchemaIds}
        hiddenTableIds={hiddenTableIds}
        setHiddenTableIds={setHiddenTableIds}
        focusedNode={focusedNode}
      />
    </OverlayPanel.Positioner>
  );
};

const GraphControlOverlayPanel = ({
  controls,
  setControls,
  hasFocusedNode,
}: {
  controls: GraphControlsType;
  setControls: React.Dispatch<React.SetStateAction<GraphControlsType>>;
  hasFocusedNode: boolean;
}) => (
  <OverlayPanel
    is={Row}
    centerY
    paddingY={12}
    width="fit-content"
    flexShrink={0}
  >
    <MiniButton
      active={controls.groupBySchema}
      size={MiniButton.Size.LG}
      marginRight={8}
      onClick={() =>
        setControls((controls) => ({
          ...controls,
          groupBySchema: !controls.groupBySchema,
        }))
      }
    >
      Group by schema
    </MiniButton>
    <MiniButton
      active={controls.onlyCentralTables}
      size={MiniButton.Size.LG}
      onClick={() =>
        setControls((controls) => ({
          ...controls,
          onlyCentralTables: !controls.onlyCentralTables,
        }))
      }
    >
      Only central tables
    </MiniButton>

    {hasFocusedNode && (
      <Tooltip title="Show nodes 2 degrees away" placement="bottom">
        <div>
          <MiniButton
            active={controls.includeSecondDegreeConnections}
            size={MiniButton.Size.LG}
            marginLeft={16}
            onClick={() =>
              setControls((controls) => ({
                ...controls,
                includeSecondDegreeConnections: !controls.includeSecondDegreeConnections,
              }))
            }
          >
            Extend depth
          </MiniButton>
        </div>
      </Tooltip>
    )}
  </OverlayPanel>
);

const FilterOverlayPanel = ({
  currentGraphData,
  hiddenSchemaIds,
  setHiddenSchemaIds,
  hiddenTableIds,
  setHiddenTableIds,
  focusedNode,
}: {
  currentGraphData: StrictGraphData | undefined;
  hiddenSchemaIds: string[];
  setHiddenSchemaIds: React.Dispatch<string[]>;
  hiddenTableIds: string[];
  setHiddenTableIds: React.Dispatch<string[]>;
  focusedNode?: TableNode;
}) => {
  const hasFocusedNode = !!focusedNode;
  const [isSchemaSectionOpen, setIsSchemaSectionOpen] = useState(
    !hasFocusedNode
  );
  const [isTableSectionOpen, setIsTableSectionOpen] = useState(hasFocusedNode);

  useEffect(() => {
    if (hasFocusedNode) {
      setIsSchemaSectionOpen(false);
      setIsTableSectionOpen(true);
    } else {
      setIsSchemaSectionOpen(true);
      setIsTableSectionOpen(false);
    }
  }, [hasFocusedNode]);

  const graphData = currentGraphData || { combos: [], nodes: [], edges: [] };

  const { combos: schemaCombos, nodes: tableNodes } = graphData;

  const sortedSchemaCombos = [...(schemaCombos || [])].sort(
    (schemaComboA, schemaComboB) => {
      if (schemaComboA.name > schemaComboB.name) return 1;
      if (schemaComboA.name < schemaComboB.name) return -1;
      return 0;
    }
  );

  const filteredTableNodes = tableNodes.filter((tableNode) => {
    if (hiddenSchemaIds.includes(tableNode.schemaId)) {
      return false;
    }
    return true;
  });

  const sortedFilteredTableNodes = [...filteredTableNodes].sort(
    (tableNodeA, tableNodeB) => {
      if (tableNodeA.tableName > tableNodeB.tableName) return 1;
      if (tableNodeA.tableName < tableNodeB.tableName) return -1;
      return 0;
    }
  );

  const selectedSchemaIds = (schemaCombos || [])
    .map((schemaCombo) => schemaCombo.id)
    .filter((schemaId) => !hiddenSchemaIds.includes(schemaId));

  const selectedTableIds = filteredTableNodes
    .map((tableNode) => tableNode.id)
    .filter((tableId) => !hiddenTableIds.includes(tableId));

  return (
    <OverlayPanel
      is={Column}
      marginTop={20}
      width="fit-content"
      minWidth={160}
      maxWidth={300}
      paddingY={0}
    >
      <AccordionHeader
        isOpen={isSchemaSectionOpen}
        setIsOpen={setIsSchemaSectionOpen}
        flexShrink={0}
      >
        Schemas
      </AccordionHeader>
      {isSchemaSectionOpen && (
        <>
          <Pane overflowY="scroll" flexShrink={0} marginX={-12}>
            {sortedSchemaCombos.map((schemaCombo) => {
              const isSelected = selectedSchemaIds.includes(schemaCombo.id);
              const isSchemaOfFocusedNode =
                schemaCombo.id === focusedNode?.schemaId;
              return (
                <MenuItem
                  centerY
                  fontSize={13}
                  textTransform="lowercase"
                  fontWeight={500}
                  paddingY={5}
                  paddingX={16}
                  className={classNames(
                    "onHoverParent",
                    isSchemaOfFocusedNode && "disabled",
                    selectedSchemaIds.includes(schemaCombo.id) && "active"
                  )}
                  onClick={() => {
                    if (!isSchemaOfFocusedNode) {
                      setHiddenSchemaIds(
                        isSelected
                          ? [...hiddenSchemaIds, schemaCombo.id]
                          : hiddenSchemaIds.filter(
                              (schemaId) => schemaId !== schemaCombo.id
                            )
                      );
                    }
                  }}
                >
                  <Pane
                    borderRadius={2}
                    height={8}
                    width={8}
                    flexShrink={0}
                    backgroundColor={
                      schemaCombo.labelCfg?.style?.fill || "transparent"
                    }
                    marginRight={8}
                    marginTop={1}
                  />
                  <Pane overflow="hidden" textOverflow="ellipsis">
                    <Code enforceCasing fontSize={12}>
                      {schemaCombo.name}
                    </Code>
                  </Pane>
                  {isSchemaOfFocusedNode ? (
                    <Pane
                      marginLeft="auto"
                      paddingLeft={26}
                      fontSize={11}
                      opacity={0.4}
                      textTransform="uppercase"
                    >
                      Current
                    </Pane>
                  ) : (
                    <Pane
                      className="visibleOnParentHover"
                      marginLeft="auto"
                      paddingLeft={26}
                      opacity={0.5}
                    >
                      <Icon
                        name="fa-times"
                        transition="0.2s ease transform"
                        transform={`rotate(${
                          selectedSchemaIds.includes(schemaCombo.id) ? 0 : 45
                        }deg)`}
                      />
                    </Pane>
                  )}
                </MenuItem>
              );
            })}
          </Pane>
          <Pane>
            <Row
              fontSize={12}
              color="rgba(55, 53, 47, 0.5)"
              paddingY={8}
              borderBottom="1px solid #eee"
              marginX={-12}
              paddingX={12}
            >
              <Span marginRight={22}>
                {selectedSchemaIds.length} of {sortedSchemaCombos.length}{" "}
                schemas visible
              </Span>

              <Link
                marginLeft="auto"
                onClick={() => setHiddenSchemaIds([])}
                disabled={
                  selectedSchemaIds.length === sortedSchemaCombos.length
                }
              >
                All
              </Link>
              <Link
                marginLeft={8}
                onClick={() =>
                  setHiddenSchemaIds(
                    sortedSchemaCombos.map((schemaCombo) => schemaCombo.id)
                  )
                }
                disabled={selectedSchemaIds.length === 0}
              >
                None
              </Link>
            </Row>
          </Pane>
        </>
      )}
      <AccordionHeader
        isOpen={isTableSectionOpen}
        setIsOpen={setIsTableSectionOpen}
      >
        Tables
      </AccordionHeader>
      {isTableSectionOpen && (
        <>
          <Pane overflowY="scroll" marginX={-12}>
            {sortedFilteredTableNodes.map((tableNode) => {
              const isSelected = selectedTableIds.includes(tableNode.id);
              const isFocusedNode = tableNode.id === focusedNode?.id;
              const schemaCombo = sortedSchemaCombos.find(
                (schemaCombo) => schemaCombo.id === tableNode.schemaId
              );
              return (
                <MenuItem
                  centerY
                  fontSize={13}
                  textTransform="lowercase"
                  fontWeight={500}
                  paddingY={5}
                  paddingX={16}
                  className={classNames(
                    "onHoverParent",
                    isFocusedNode && "disabled",
                    isSelected && "active"
                  )}
                  onClick={() => {
                    if (!isFocusedNode) {
                      setHiddenTableIds(
                        isSelected
                          ? [...hiddenTableIds, tableNode.id]
                          : hiddenTableIds.filter(
                              (tableId) => tableId !== tableNode.id
                            )
                      );
                    }
                  }}
                >
                  <Pane
                    borderRadius={40}
                    flexShrink={0}
                    height={8}
                    width={8}
                    border={`2px solid ${
                      schemaCombo?.labelCfg?.style?.fill || "transparent"
                    }`}
                    marginRight={8}
                    marginTop={1}
                  />
                  <Pane overflow="hidden" textOverflow="ellipsis">
                    <Code enforceCasing fontSize={12}>
                      {tableNode.tableName}
                    </Code>
                  </Pane>
                  {isFocusedNode ? (
                    <Pane
                      marginLeft="auto"
                      paddingLeft={26}
                      fontSize={11}
                      opacity={0.4}
                      textTransform="uppercase"
                    >
                      Current
                    </Pane>
                  ) : (
                    <Pane
                      className="visibleOnParentHover"
                      marginLeft="auto"
                      paddingLeft={26}
                      opacity={0.5}
                    >
                      <Icon
                        name="fa-times"
                        transition="0.2s ease transform"
                        transform={`rotate(${isSelected ? 0 : 45}deg)`}
                      />
                    </Pane>
                  )}
                </MenuItem>
              );
            })}
          </Pane>

          <Pane>
            <Row
              fontSize={12}
              color="rgba(55, 53, 47, 0.5)"
              paddingY={8}
              marginX={-12}
              paddingX={12}
            >
              <Span marginRight={22}>
                {selectedTableIds.length} of {sortedFilteredTableNodes.length}{" "}
                tables visible
              </Span>

              <Link
                marginLeft="auto"
                onClick={() => setHiddenTableIds([])}
                disabled={
                  selectedTableIds.length === sortedFilteredTableNodes.length
                }
              >
                All
              </Link>
              <Link
                marginLeft={8}
                onClick={() =>
                  setHiddenTableIds(
                    sortedFilteredTableNodes.map((tableNode) => tableNode.id)
                  )
                }
                disabled={selectedTableIds.length === 0}
              >
                None
              </Link>
            </Row>
          </Pane>
        </>
      )}
    </OverlayPanel>
  );
};

const AccordionHeader = ({
  isOpen,
  setIsOpen,
  children,
  ...props
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
} & UIProps) => (
  <Hoverable
    centerY
    fontSize={13}
    fontWeight={600}
    onClick={() => setIsOpen(!isOpen)}
    marginX={-12}
    paddingX={13}
    paddingY={12}
    {...props}
  >
    {children}
    <Icon
      type="fas"
      fixedWidth
      name={isOpen ? "fa-caret-down" : "fa-caret-right"}
      fontSize={12}
      opacity={0.6}
      marginLeft="auto"
      marginRight={-2}
    />
  </Hoverable>
);

const Hoverable = styled(Row)`
  transition: 0.2s ease all;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &.disabled {
    cursor: default;
    &:hover {
      background-color: white;
    }
  }
`;

const MenuItem = styled(Hoverable)`
  opacity: 0.35;
  filter: grayscale(1);
  &:hover {
    opacity: 0.6;
    filter: grayscale(0.5);
  }
  &.active,
  &.active:hover {
    filter: grayscale(0);
    opacity: 1;
  }
`;

export default GraphControls;
