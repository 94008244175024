import React, { ReactNode } from "react";
import styled from "styled-components";

import { Pane, Column, Row } from "components/base/layout";
import { Image } from "components/base";

export const IntegrationCard = (props: {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <StyledCard
    marginRight={25}
    marginBottom={25}
    center
    width={262}
    height={110}
    paddingX={20}
    backgroundColor="#f5f7f9"
    borderRadius={6}
    paddingY={10}
    opacity={props.disabled ? 0.2 : 1}
    pointerEvents={props.disabled ? "none" : "all"}
    {...props}
  />
);

IntegrationCard.Image = (props: {
  logoFileName: string;
  maxWidth?: number;
}) => (
  <Image
    width="100%"
    maxWidth={props.maxWidth || 225}
    maxHeight={90}
    height="auto"
    src={`/integration-logos/${props.logoFileName}`}
  />
);

export const IntegrationCardGroup = (props: {
  children: ReactNode;
  marginRight?: number;
}) => <Pane maxWidth={574} {...props} />;
IntegrationCardGroup.CardsRow = (props?: { children: ReactNode }) => (
  <Row marginTop={29} marginBottom={40} wrap {...props} />
);

const StyledCard = styled(Column)`
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #eff2f5;
  }
`;

export default IntegrationCardGroup;
