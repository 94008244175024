import { useState, useEffect } from "react";

export const useWaitForLocalStorageValue = ({
  localStorageKey,
  shouldBeginPolling,
}: {
  localStorageKey: string;
  shouldBeginPolling?: boolean;
}) => {
  const [timerCounter, setTimerCounter] = useState(0);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (shouldBeginPolling) {
      const localStorageValue = window.localStorage.getItem(localStorageKey);
      if (!localStorageValue) {
        window.setTimeout(() => {
          setTimerCounter((count) => count + 1);
        }, 1000);
      } else {
        setValue(localStorageValue);
      }
    }
  }, [shouldBeginPolling, timerCounter, localStorageKey]);

  if (!shouldBeginPolling) {
    return undefined;
  }

  return value;
};
