import React from "react";
import { NetworkErrorBoundary } from "rest-hooks";

export const withNetworkErrorBoundary = (
  Component: any,
  fallbackComponent?: any
) => (props: any) => (
  <NetworkErrorBoundary
    fallbackComponent={fallbackComponent || DefaultErrorState}
  >
    <Component {...props} />
  </NetworkErrorBoundary>
);

const DefaultErrorState = () => <div>Error loading data.</div>;

export default withNetworkErrorBoundary;
