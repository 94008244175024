import React, { useState } from "react";

import { useFetcher } from "rest-hooks";
import TablesResource from "resources/tables";
import TagsResource, { entityTagsListUpdater } from "resources/tags";
import { TagEntityType } from "resources/tag-entity-type";

import {
  getNumberAsShorthandString,
  getTimeFromNow,
  getSortedEntitiesByName,
} from "utils";
import { useAuth } from "authentication";

import { Tooltip } from "antd";
import {
  EntityRow,
  Icon,
  Link,
  MiniButton,
  MiniIconButton,
  Stat,
} from "components/base";
import {
  ProtectedEntityOwnerSelect,
  ProtectedEntityTagsSelect,
  ProtectedEntityImportanceToggle,
} from "components/protected";
import { H2, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import RequestEntityDocumentationController from "components/RequestEntityDocumentationController";
import EntityVisibilityToggle from "components/EntityVisibilityToggle";
import EntityContext from "contexts/EntityContext";
import { ProtectedEntityDescription } from "components/protected";
import CustomField from "types/CustomField";

const TableRow = ({ table }: { table: TablesResource }) => {
  const { user } = useAuth();
  const tagsResourceList = TagsResource.list();
  const addTagToTable = useFetcher(TablesResource.addTag());
  const createAndAddTagToTable = useFetcher(
    TablesResource.createAndAddTagToTable()
  );
  const removeTagFromTable = useFetcher(TablesResource.removeTag());

  const tableOwner = table.entityUserRelations.find(
    (entityUserRelation) => entityUserRelation.relationType === "owner"
  );
  const addTableOwner = useFetcher(TablesResource.addOwner());
  const removeTableOwner = useFetcher(TablesResource.removeOwner());
  const updateTable = useFetcher(TablesResource.partialUpdate());

  const requestDocumentation = useFetcher(
    TablesResource.requestDocumentation()
  );
  const resolveDocumentation = useFetcher(
    TablesResource.resolveDocumentation()
  );

  const sortedCustomFields = getSortedEntitiesByName<CustomField>(
    table.customFields
  );
  const [hasExpandedDescription, setHasExpandedDescription] = useState(false);
  const hasDescription = table.description?.descriptionText?.length > 0;

  return (
    <EntityContext.Provider value={{ entity: table }}>
      <EntityRow className="onHoverParent" borderBottom="1px solid #F2F2F2">
        <Pane width="100%">
          <Row>
            <Pane whiteSpace="nowrap" minWidth={300}>
              <Row opacity={table.hidden ? 0.5 : 1} alignItems="flex-end">
                <H2
                  fontWeight={500}
                  fontSize={14}
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <TablesResource.Icon
                    fontSize={12}
                    marginRight={5}
                    type={table.type}
                  />
                  <Tooltip color="black" title={`Go to ${table.type} page`}>
                    <Link highlight to={table.catalogUrlPath}>
                      <MiniButton
                        fontSize="inherit"
                        fontWeight="inherit"
                        color="inherit"
                      >
                        <Code enforceCasing>{table.name}</Code>
                        <Icon
                          className="visibleOnParentHover"
                          name="fa-arrow-right"
                          marginLeft={7}
                          transform="rotate(-45deg)"
                          fontSize={10}
                          fontWeight={600}
                          opacity={0.8}
                        />
                      </MiniButton>
                    </Link>
                  </Tooltip>
                </H2>
                {user.canWriteDescriptions &&
                  !hasDescription &&
                  !hasExpandedDescription && (
                    <MiniIconButton
                      marginLeft={6}
                      iconType="far"
                      iconName="fa-pencil"
                      title="Add description"
                      onClick={() => setHasExpandedDescription(true)}
                    >
                      Description
                    </MiniIconButton>
                  )}
              </Row>
              <Pane paddingLeft={26} fontSize={12} opacity={0.5} marginTop={2}>
                <Span>
                  Updated {getTimeFromNow(table.sourceUpdatedAt)} &bull;{" "}
                  {getNumberAsShorthandString(table.statistics.col_count || 0)}{" "}
                  Cols{" "}
                  {table.isTable && (
                    <>
                      &bull;{" "}
                      {getNumberAsShorthandString(
                        table.statistics.row_count || 0
                      )}{" "}
                      Rows
                    </>
                  )}
                </Span>
              </Pane>
            </Pane>

            <Row marginLeft="auto" centerY>
              <ProtectedEntityTagsSelect
                tagIds={table.tagIds}
                tagEntityType={TagEntityType.table}
                addTagToEntity={(tagId: string) =>
                  addTagToTable({ id: table.id }, { tagId })
                }
                createAndAddTagToEntity={(newTagName: string) =>
                  createAndAddTagToTable({ id: table.id }, { newTagName }, [
                    [tagsResourceList, {}, entityTagsListUpdater],
                  ])
                }
                removeTagFromEntity={(tagId: string) =>
                  removeTagFromTable({ table, tagId }, {})
                }
              />
              {sortedCustomFields.map((customField) => (
                <Stat name={customField.name}>{customField.value}</Stat>
              ))}

              <ProtectedEntityOwnerSelect
                marginLeft={8}
                value={tableOwner?.userId}
                selectUser={(selectedUserId) => {
                  addTableOwner({}, { id: table.id, userId: selectedUserId });
                }}
                deselectUser={(selectedUserId) =>
                  removeTableOwner({ id: table.id, userId: selectedUserId })
                }
                selectedUserIds={table.entityUserRelations
                  .filter(
                    (entityUserRelation) =>
                      entityUserRelation.relationType === "owner"
                  )
                  .map((entityUserRelation) => entityUserRelation.userId)}
              />
              <RequestEntityDocumentationController
                marginLeft={8}
                iconOnly
                documentationRequests={table.documentationRequests}
                requestDocumentation={() => {
                  requestDocumentation({ id: table.id }, {});
                }}
                resolveDocumentation={() => {
                  resolveDocumentation({ id: table.id }, {});
                }}
              />
              <ProtectedEntityImportanceToggle
                marginLeft={8}
                isImportant={table.isImportant}
                toggleEntityIsImportant={(isImportant: boolean) =>
                  updateTable({ id: table.id }, { isImportant })
                }
              />

              {user.canWriteEntityVisibility && (
                <EntityVisibilityToggle
                  marginLeft={8}
                  isHidden={table.hidden}
                  onToggle={(hidden) =>
                    updateTable({ id: table.id }, { hidden })
                  }
                />
              )}
            </Row>
          </Row>

          {(hasDescription || hasExpandedDescription) && (
            <Row paddingLeft={26} paddingRight={20} fontSize={14} centerY>
              <ProtectedEntityDescription
                description={table.description}
                autofocus={hasExpandedDescription}
                noDefer={hasExpandedDescription}
                onChange={(description) => {
                  updateTable({ id: table.id }, { description });
                }}
              />
            </Row>
          )}
        </Pane>
      </EntityRow>
    </EntityContext.Provider>
  );
};

export default TableRow;
