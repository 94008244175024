import mix from "@antv/util/lib/mix";
let _util = require("@antv/util");
let _graphic = require("@antv/g6/lib/util/graphic");

let CustomCombo = (function () {
  // Initialize layout parameters
  function CustomCombo() {
    this.center = [0, 0];
    this.maxIteration = 100;
    this.gravity = 10;
    this.comboGravity = 10;
    this.linkDistance = 10;
    this.alpha = 1;
    this.alphaMin = 0.001;
    this.alphaDecay = 1 - Math.pow(this.alphaMin, 1 / 300);
    this.alphaTarget = 0;
    this.velocityDecay = 0.6;
    this.edgeStrength = 0.2;
    this.nodeStrength = 30;
    this.preventOverlap = false;
    this.preventNodeOverlap = false;
    this.preventComboOverlap = false;
    this.collideStrength = undefined;
    this.nodeCollideStrength = undefined;
    this.comboCollideStrength = undefined;
    this.optimizeRangeFactor = 1;
    this.onTick = function () {};
    this.onLayoutEnd = function () {};
    this.depthAttractiveForceScale = 0.5;
    this.depthRepulsiveForceScale = 2;
    this.nodes = [];
    this.edges = [];
    this.combos = [];
    this.comboTrees = [];
    this.width = 300;
    this.height = 300;
    this.bias = [];
    this.nodeMap = {};
    this.oriComboMap = {};
    this.nodeIdxMap = {};
    this.comboMap = {};
    this.previousLayouted = false;
    this.positions = [];
    this.destroyed = false;

    this.maxComboDistance = 1;

    this.minNudge = 0.1;
    this.maxNudge = 20;

    this.maxComboSize = 1;
  }

  CustomCombo.prototype.getDefaultCfg = function () {
    return {
      maxIteration: 100,
      center: [0, 0],
      gravity: 10,
      speed: 1,
      comboGravity: 30,
      preventOverlap: false,
      preventComboOverlap: true,
      preventNodeOverlap: true,
      nodeSpacing: undefined,
      collideStrength: undefined,
      nodeCollideStrength: 1.0,
      comboCollideStrength: 0.5,
      comboSpacing: 20,
      comboPadding: 10,
      edgeStrength: 0.2,
      nodeStrength: 30,
      linkDistance: 10,
    };
  };

  CustomCombo.prototype.init = function (data) {
    let self = this;
    self.nodes = data.nodes || [];
    self.edges = data.edges || [];
    self.combos = data.combos || [];
  };

  CustomCombo.prototype.execute = function () {
    let self = this;
    let nodes = self.nodes;
    let center = self.center;
    self.comboTree = {
      id: "comboTreeRoot",
      depth: -1,
      children: self.comboTrees,
    };

    if (!nodes || nodes.length === 0) {
      return;
    }

    if (nodes.length === 1) {
      nodes[0].x = center[0];
      nodes[0].y = center[1];
      return;
    }

    self.initVals(); // layout

    self.run();
    self.onLayoutEnd();
  };

  CustomCombo.prototype.run = function () {
    let self = this;
    let nodes = self.nodes;
    let maxIteration = self.previousLayouted
      ? self.maxIteration / 5
      : self.maxIteration;

    if (!self.width && typeof window !== "undefined") {
      self.width = window.innerWidth;
    }

    if (!self.height && typeof window !== "undefined") {
      self.height = window.innerHeight;
    }

    let center = self.center;
    let velocityDecay = self.velocityDecay; // init the positions to make the nodes with same combo gather around the combo

    let comboMap = self.comboMap;
    if (!self.previousLayouted) self.initPos(comboMap);

    let _loop_1 = function _loop_1(i) {
      let displacements = [];
      nodes.forEach(function (_, j) {
        displacements[j] = {
          x: 0,
          y: 0,
        };
      });
      self.applyCalculate(displacements); // gravity for combos

      self.applyComboCenterForce(displacements); // move

      nodes.forEach(function (n, j) {
        if (!(0, _util.isNumber)(n.x) || !(0, _util.isNumber)(n.y)) return;
        n.x += displacements[j].x * velocityDecay;
        n.y += displacements[j].y * velocityDecay;
      });
      self.alpha += (self.alphaTarget - self.alpha) * self.alphaDecay;
      self.onTick();
    }; // iterate

    for (let i = 0; i < maxIteration; i++) {
      _loop_1(i);
    } // move to center

    let meanCenter = [0, 0];
    nodes.forEach(function (n) {
      if (!(0, _util.isNumber)(n.x) || !(0, _util.isNumber)(n.y)) return;
      meanCenter[0] += n.x;
      meanCenter[1] += n.y;
    });
    meanCenter[0] /= nodes.length;
    meanCenter[1] /= nodes.length;
    let centerOffset = [center[0] - meanCenter[0], center[1] - meanCenter[1]];
    nodes.forEach(function (n, j) {
      if (!(0, _util.isNumber)(n.x) || !(0, _util.isNumber)(n.y)) return;
      n.x += centerOffset[0];
      n.y += centerOffset[1];
    }); // arrange the empty combo

    self.combos.forEach(function (combo) {
      let mapped = comboMap[combo.id];

      if (mapped && mapped.empty) {
        combo.x = mapped.cx || combo.x;
        combo.y = mapped.cy || combo.y;
      }
    });
    self.previousLayouted = true;
  };

  CustomCombo.prototype.initVals = function () {
    let self = this;
    let edges = self.edges;
    let nodes = self.nodes;
    let combos = self.combos;
    let count = {};
    let nodeMap = {};
    let nodeIdxMap = {};
    nodes.forEach(function (node, i) {
      nodeMap[node.id] = node;
      nodeIdxMap[node.id] = i;
    });
    self.nodeMap = nodeMap;
    self.nodeIdxMap = nodeIdxMap;
    let oriComboMap = {};
    combos.forEach(function (combo) {
      oriComboMap[combo.id] = combo;
    });
    self.oriComboMap = oriComboMap;
    self.comboMap = self.getComboMap();
    let preventOverlap = self.preventOverlap;
    self.preventComboOverlap = self.preventComboOverlap || preventOverlap;
    self.preventNodeOverlap = self.preventNodeOverlap || preventOverlap;
    let collideStrength = self.collideStrength;

    if (collideStrength) {
      self.comboCollideStrength = collideStrength;
      self.nodeCollideStrength = collideStrength;
    } // get edge bias

    for (let i = 0; i < edges.length; ++i) {
      if (count[edges[i].source]) count[edges[i].source]++;
      else count[edges[i].source] = 1;
      if (count[edges[i].target]) count[edges[i].target]++;
      else count[edges[i].target] = 1;
    }

    let bias = [];

    for (let i = 0; i < edges.length; ++i) {
      bias[i] =
        count[edges[i].source] /
        (count[edges[i].source] + count[edges[i].target]);
    }

    this.bias = bias;
    let nodeSize = self.nodeSize;
    let nodeSpacing = self.nodeSpacing;
    let nodeSizeFunc;
    let nodeSpacingFunc; // nodeSpacing to function

    if ((0, _util.isNumber)(nodeSpacing)) {
      nodeSpacingFunc = function nodeSpacingFunc() {
        return nodeSpacing;
      };
    } else if ((0, _util.isFunction)(nodeSpacing)) {
      nodeSpacingFunc = nodeSpacing;
    } else {
      nodeSpacingFunc = function nodeSpacingFunc() {
        return 0;
      };
    }

    this.nodeSpacing = nodeSpacingFunc; // nodeSize to function

    if (!nodeSize) {
      nodeSizeFunc = function nodeSizeFunc(d) {
        if (d.size) {
          if ((0, _util.isArray)(d.size)) {
            let res = d.size[0] > d.size[1] ? d.size[0] : d.size[1];
            return res / 2;
          }

          return d.size / 2;
        }

        return 10;
      };
    } else if ((0, _util.isFunction)(nodeSize)) {
      nodeSizeFunc = function nodeSizeFunc(d) {
        return nodeSize(d);
      };
    } else if ((0, _util.isArray)(nodeSize)) {
      let larger = nodeSize[0] > nodeSize[1] ? nodeSize[0] : nodeSize[1];
      let radius_1 = larger / 2;

      nodeSizeFunc = function nodeSizeFunc(d) {
        return radius_1;
      };
    } else {
      // number type
      let radius_2 = nodeSize / 2;

      nodeSizeFunc = function nodeSizeFunc(d) {
        return radius_2;
      };
    }

    this.nodeSize = nodeSizeFunc; // comboSpacing to function

    let comboSpacing = self.comboSpacing;
    let comboSpacingFunc;

    if ((0, _util.isNumber)(comboSpacing)) {
      comboSpacingFunc = function comboSpacingFunc() {
        return comboSpacing;
      };
    } else if ((0, _util.isFunction)(comboSpacing)) {
      comboSpacingFunc = comboSpacing;
    } else {
      // null type
      comboSpacingFunc = function comboSpacingFunc() {
        return 0;
      };
    }

    this.comboSpacing = comboSpacingFunc; // comboPadding to function

    let comboPadding = self.comboPadding;
    let comboPaddingFunc;

    if ((0, _util.isNumber)(comboPadding)) {
      comboPaddingFunc = function comboPaddingFunc() {
        return comboPadding;
      };
    } else if ((0, _util.isArray)(comboPadding)) {
      comboPaddingFunc = function comboPaddingFunc() {
        return Math.max.apply(null, comboPadding);
      };
    } else if ((0, _util.isFunction)(comboPadding)) {
      comboPaddingFunc = comboPadding;
    } else {
      // null type
      comboPaddingFunc = function comboPaddingFunc() {
        return 0;
      };
    }

    this.comboPadding = comboPaddingFunc; // linkDistance to function

    let linkDistance = this.linkDistance;
    let linkDistanceFunc;

    if (!linkDistance) {
      linkDistance = 10;
    }

    if ((0, _util.isNumber)(linkDistance)) {
      linkDistanceFunc = function linkDistanceFunc(d) {
        return linkDistance;
      };
    } else {
      linkDistanceFunc = linkDistance;
    }

    this.linkDistance = linkDistanceFunc; // linkStrength to function

    let edgeStrength = this.edgeStrength;
    let edgeStrengthFunc;

    if (!edgeStrength) {
      edgeStrength = 1;
    }

    if ((0, _util.isNumber)(edgeStrength)) {
      edgeStrengthFunc = function edgeStrengthFunc(d) {
        return edgeStrength;
      };
    } else {
      edgeStrengthFunc = edgeStrength;
    }

    this.edgeStrength = edgeStrengthFunc; // nodeStrength to function

    let nodeStrength = this.nodeStrength;
    let nodeStrengthFunc;

    if (!nodeStrength) {
      nodeStrength = 30;
    }

    if ((0, _util.isNumber)(nodeStrength)) {
      nodeStrengthFunc = function nodeStrengthFunc(d) {
        return nodeStrength;
      };
    } else {
      nodeStrengthFunc = nodeStrength;
    }

    this.nodeStrength = nodeStrengthFunc;
  };

  CustomCombo.prototype.initPos = function (comboMap) {
    let self = this;
    let nodes = self.nodes;
    nodes.forEach(function (node, i) {
      if (node.comboId) {
        let combo = comboMap[node.comboId];
        node.x = combo.cx + 100 / (i + 1);
        node.y = combo.cy + 100 / (i + 1);
      } else {
        node.x = 100 / (i + 1);
        node.y = 100 / (i + 1);
      }
    });
  };

  CustomCombo.prototype.getComboMap = function () {
    let self = this;
    let nodeMap = self.nodeMap;
    let nodeIdxMap = self.nodeIdxMap;
    let comboTrees = self.comboTrees;
    let oriComboMap = self.oriComboMap;
    let comboMap = {};

    (comboTrees || []).forEach(function (ctree) {
      let treeChildren = [];
      (0, _graphic.traverseTreeUp)(ctree, function (treeNode) {
        if (treeNode.itemType === "node") return true; // skip it

        if (!oriComboMap[treeNode.id]) return true; // means it is hidden, skip it

        if (comboMap[treeNode.id] === undefined) {
          let combo = {
            name: treeNode.id,
            cx: 0,
            cy: 0,
            count: 0,
            depth: self.oriComboMap[treeNode.id].depth,
            children: [],
          };
          comboMap[treeNode.id] = combo;
        }

        let children = treeNode.children;

        if (children) {
          children.forEach(function (child) {
            if (!comboMap[child.id] && !nodeMap[child.id]) return true; // means it is hidden

            treeChildren.push(child);
          });
        }

        let c = comboMap[treeNode.id];
        c.cx = 0;
        c.cy = 0; // In order to layout the empty combo, add a virtual node to it

        if (treeChildren.length === 0) {
          c.empty = true;
          let oriCombo = oriComboMap[treeNode.id];
          let idx = Object.keys(nodeMap).length;
          let virtualNodeId = treeNode.id + "-visual-child-" + idx;
          let vnode = {
            id: virtualNodeId,
            x: oriCombo.x,
            y: oriCombo.y,
            depth: c.depth + 1,
            itemType: "node",
          };
          self.nodes.push(vnode);
          nodeMap[virtualNodeId] = vnode;
          nodeIdxMap[virtualNodeId] = idx;
          c.cx = oriCombo.x;
          c.cy = oriCombo.y;
          treeChildren.push(vnode);
        }

        treeChildren.forEach(function (child) {
          c.count++;

          if (child.itemType !== "node") {
            let childCombo = comboMap[child.id];
            if ((0, _util.isNumber)(childCombo.cx)) c.cx += childCombo.cx;
            if ((0, _util.isNumber)(childCombo.cy)) c.cy += childCombo.cy;
            return;
          }

          let node = nodeMap[child.id]; // means the node is hidden, skip it

          if (!node) return;

          if ((0, _util.isNumber)(node.x)) {
            c.cx += node.x;
          }

          if ((0, _util.isNumber)(node.y)) {
            c.cy += node.y;
          }
        });
        c.cx /= c.count;
        c.cy /= c.count;
        c.children = treeChildren;
        return true;
      });
    });
    return comboMap;
  };

  CustomCombo.prototype.applyComboCenterForce = function (displacements) {
    let self = this;
    let gravity = self.gravity;
    let comboGravity = self.comboGravity || gravity;
    let alpha = this.alpha;
    let comboTrees = self.comboTrees;
    let nodeIdxMap = self.nodeIdxMap;
    let nodeMap = self.nodeMap;
    let comboMap = self.comboMap;
    (comboTrees || []).forEach(function (ctree) {
      (0, _graphic.traverseTreeUp)(ctree, function (treeNode) {
        if (treeNode.itemType === "node") return true; // skip it

        let combo = comboMap[treeNode.id]; // means the combo is hidden, skip it

        if (!combo) return true;
        let c = comboMap[treeNode.id]; // higher depth the combo, larger the gravity

        let gravityScale = (c.depth + 1) * 0.5; // apply combo center force for all the descend nodes in this combo
        // and update the center position and count for this combo

        let comboX = c.cx;
        let comboY = c.cy;
        c.cx = 0;
        c.cy = 0;
        c.children.forEach(function (child) {
          if (child.itemType !== "node") {
            let childCombo = comboMap[child.id];
            if (childCombo && (0, _util.isNumber)(childCombo.cx))
              c.cx += childCombo.cx;
            if (childCombo && (0, _util.isNumber)(childCombo.cy))
              c.cy += childCombo.cy;
            return;
          }

          let node = nodeMap[child.id];
          let vecX = node.x - comboX || 0.005;
          let vecY = node.y - comboY || 0.005;
          let l = Math.sqrt(vecX * vecX + vecY * vecY);
          let childIdx = nodeIdxMap[node.id];
          let params = ((comboGravity * alpha) / l) * gravityScale;
          displacements[childIdx].x -= vecX * params;
          displacements[childIdx].y -= vecY * params;
          if ((0, _util.isNumber)(node.x)) c.cx += node.x;
          if ((0, _util.isNumber)(node.y)) c.cy += node.y;
        });
        c.cx /= c.count;
        c.cy /= c.count;
        return true;
      });
    });
  };

  CustomCombo.prototype.applyCalculate = function (displacements) {
    let self = this;
    let comboMap = self.comboMap;
    let nodes = self.nodes; // store the vx, vy, and distance to reduce dulplicate calculation

    let vecMap = {};
    nodes.forEach(function (v, i) {
      nodes.forEach(function (u, j) {
        if (i < j) return;
        let vx = v.x - u.x || 0.005;
        let vy = v.y - u.y || 0.005;
        let vl2 = vx * vx + vy * vy;
        let vl = Math.sqrt(vl2);
        if (vl2 < 1) vl2 = vl;
        vecMap[v.id + "-" + u.id] = {
          vx: vx,
          vy: vy,
          vl2: vl2,
          vl: vl,
        };
        vecMap[u.id + "-" + v.id] = {
          vx: -vx,
          vy: -vy,
          vl2: vl2,
          vl: vl,
        };
      });
    }); // get the sizes of the combos

    self.updateComboSizes(comboMap);
    self.calRepulsive(displacements, vecMap);
    self.calAttractive(displacements, vecMap);
    let preventComboOverlap = self.preventComboOverlap;
    if (preventComboOverlap) self.comboNonOverlapping(displacements, comboMap);
  };

  /**
   * Update the sizes of the combos according to their children
   * Used for combos nonoverlap, but not re-render the combo shapes
   */
  CustomCombo.prototype.updateComboSizes = function (comboMap) {
    let self = this;
    let comboTrees = self.comboTrees;
    let nodeMap = self.nodeMap;
    let nodeSize = self.nodeSize;
    let comboSpacing = self.comboSpacing;
    let comboPadding = self.comboPadding;

    (comboTrees || []).forEach(function (ctree) {
      let treeChildren = [];
      (0, _graphic.traverseTreeUp)(ctree, function (treeNode) {
        if (treeNode.itemType === "node") return true; // skip it

        let c = comboMap[treeNode.id]; // means the combo is hidden, skip it

        if (!c) return;
        let children = treeNode.children;

        if (children) {
          children.forEach(function (child) {
            // means the combo is hidden.
            if (!comboMap[child.id] && !nodeMap[child.id]) return;
            treeChildren.push(child);
          });
        }

        c.minX = Infinity;
        c.minY = Infinity;
        c.maxX = -Infinity;
        c.maxY = -Infinity;
        treeChildren.forEach(function (child) {
          if (child.itemType !== "node") return true; // skip it

          let node = nodeMap[child.id];
          if (!node) return true; // means it is hidden

          let r = nodeSize(node);
          let nodeMinX = node.x - r;
          let nodeMinY = node.y - r;
          let nodeMaxX = node.x + r;
          let nodeMaxY = node.y + r;
          if (c.minX > nodeMinX) c.minX = nodeMinX;
          if (c.minY > nodeMinY) c.minY = nodeMinY;
          if (c.maxX < nodeMaxX) c.maxX = nodeMaxX;
          if (c.maxY < nodeMaxY) c.maxY = nodeMaxY;
        });
        let minSize = self.oriComboMap[treeNode.id].size;
        if ((0, _util.isArray)(minSize)) minSize = minSize[0];
        let maxLength = Math.max(
          c.maxX - c.minX,
          c.maxY - c.minY,
          isNaN(minSize) ? 0 : minSize
        );
        c.r = maxLength / 2 + comboSpacing(c) / 2 + comboPadding(c);
        return true;
      });
    });
  };

  /**
   * prevent the overlappings among combos
   */
  CustomCombo.prototype.comboNonOverlapping = function (
    displacements,
    comboMap
  ) {
    let self = this;
    let comboTree = self.comboTree;
    let nodeIdxMap = self.nodeIdxMap;
    let nodeMap = self.nodeMap;
    let minNudge = self.minNudge;
    let maxNudge = self.maxNudge;

    (0, _graphic.traverseTreeUp)(comboTree, function (treeNode) {
      if (
        !comboMap[treeNode.id] &&
        !nodeMap[treeNode.id] &&
        treeNode.id !== "comboTreeRoot"
      )
        return; // means it is hidden

      let children = treeNode.children; // 同个子树下的子 combo 间两两对比

      if (children && children.length > 1) {
        children.forEach(function (v, i) {
          if (v.itemType === "node") return; // skip it

          let cv = comboMap[v.id];
          if (!cv) return; // means it is hidden, skip it

          children.forEach(function (u, j) {
            if (i <= j) return;
            if (u.itemType === "node") return; // skip it

            let cu = comboMap[u.id];
            if (!cu) return; // means it is hidden, skip it

            let vx = cv.cx - cu.cx || 0.005; // Combo x distnace
            let vy = cv.cy - cu.cy || 0.005; // Combo y distance
            let l = vx * vx + vy * vy; // Squared distance between centers
            let rv = cv.r; // First radius
            let ru = cu.r; // Second radius
            let r = rv + ru; // Combined radii
            let ru2 = ru * ru;
            let rv2 = rv * rv; // overlapping

            let sqrtl = Math.sqrt(l);

            let maxComboDistance = self.width * self.maxComboDistance;
            if (sqrtl > maxComboDistance) {
              let vnodes = v.children; // Nodes in first combo
              if (!vnodes || vnodes.length === 0) return; // skip it

              let unodes_1 = u.children; // Nodes in second combo
              if (!unodes_1 || unodes_1.length === 0) return; // skip it

              // Distance between centers
              let ll = (sqrtl - maxComboDistance) / sqrtl;

              let xl_1 = vx * ll;
              let yl_1 = vy * ll;

              let minNudgePerVNode = minNudge / v.children.length;
              let maxNudgePerVNode = maxNudge / v.children.length;
              let minNudgePerUNode = minNudge / u.children.length;
              let maxNudgePerUNode = maxNudge / u.children.length;

              let vComboNudgeX = Math.min(
                Math.max(xl_1, minNudgePerVNode),
                maxNudgePerVNode
              );
              let vComboNudgeY = Math.min(
                Math.max(yl_1, minNudgePerVNode),
                maxNudgePerVNode
              );
              let uComboNudgeX = Math.min(
                Math.max(xl_1, minNudgePerUNode),
                maxNudgePerUNode
              );
              let uComboNudgeY = Math.min(
                Math.max(yl_1, minNudgePerUNode),
                maxNudgePerUNode
              );

              vnodes.forEach(function (vn) {
                if (vn.itemType !== "node") return; // skip it

                if (!nodeMap[vn.id]) return; // means it is hidden, skip it
                unodes_1.forEach(function (un) {
                  if (un.itemType !== "node") return;
                  if (!nodeMap[un.id]) return; // means it is hidden, skip it

                  let vindex = nodeIdxMap[vn.id];
                  displacements[vindex].x -= vComboNudgeX; // Shift all nodes based on overlap amount
                  displacements[vindex].y -= vComboNudgeY;

                  let uindex = nodeIdxMap[un.id];
                  displacements[uindex].x += uComboNudgeX;
                  displacements[uindex].y += uComboNudgeY;
                });
              });
            }

            if (sqrtl < r) {
              // If distance between centers is less than combined radii

              let vnodes = v.children; // Nodes in first combo
              if (!vnodes || vnodes.length === 0) return; // skip it

              let unodes_1 = u.children; // Nodes in second combo
              if (!unodes_1 || unodes_1.length === 0) return; // skip it

              let sqrtl = Math.sqrt(l); // Distance between centers
              let ll = ((r - sqrtl) / sqrtl) * 0.5; //
              let xl_1 = vx * ll;
              let yl_1 = vy * ll;
              let rratio_1 = ru2 / (rv2 + ru2);
              let irratio_1 = 1 - rratio_1; // 两兄弟 combo 的子节点上施加斥力

              let minNudgePerVNode = minNudge / v.children.length;
              let maxNudgePerVNode = maxNudge / v.children.length;
              let minNudgePerUNode = minNudge / u.children.length;
              let maxNudgePerUNode = maxNudge / u.children.length;

              let vComboNudgeX = Math.min(
                Math.max(xl_1 * rratio_1, minNudgePerVNode),
                maxNudgePerVNode
              );
              let vComboNudgeY = Math.min(
                Math.max(yl_1 * rratio_1, minNudgePerVNode),
                maxNudgePerVNode
              );
              let uComboNudgeX = Math.min(
                Math.max(xl_1 * irratio_1, minNudgePerUNode),
                maxNudgePerUNode
              );
              let uComboNudgeY = Math.min(
                Math.max(yl_1 * irratio_1, minNudgePerUNode),
                maxNudgePerUNode
              );

              vnodes.forEach(function (vn) {
                if (vn.itemType !== "node") return; // skip it

                if (!nodeMap[vn.id]) return; // means it is hidden, skip it
                unodes_1.forEach(function (un) {
                  if (un.itemType !== "node") return;
                  if (!nodeMap[un.id]) return; // means it is hidden, skip it

                  let uindex = nodeIdxMap[un.id];

                  let vindex = nodeIdxMap[vn.id];
                  displacements[vindex].x += vComboNudgeX; // Shift all nodes based on overlap amount
                  displacements[vindex].y += vComboNudgeY;

                  displacements[uindex].x -= uComboNudgeX;
                  displacements[uindex].y -= uComboNudgeY;
                });
              });
            }
          });
        });
      }

      return true;
    });
  };

  /**
   * Calculate the repulsive force between each node pair
   * @param displacements The array stores the displacements for nodes
   * @param vecMap The map stores vector between each node pair
   */
  CustomCombo.prototype.calRepulsive = function (displacements, vecMap) {
    let self = this;
    let nodes = self.nodes;
    let max = self.width * self.optimizeRangeFactor;
    let nodeStrength = self.nodeStrength;
    let alpha = self.alpha;
    let nodeCollideStrength = self.nodeCollideStrength;
    let preventNodeOverlap = self.preventNodeOverlap;
    let nodeSizeFunc = self.nodeSize;
    let nodeSpacingFunc = self.nodeSpacing;
    let scale = self.depthRepulsiveForceScale;
    let center = self.center;

    // For each node
    nodes.forEach(function (v, i) {
      if (!v.x || !v.y) return; // center gravity

      if (center) {
        let gravity = self.gravity;
        let vecX = v.x - center[0] || 0.005;
        let vecY = v.y - center[1] || 0.005;
        let l = Math.sqrt(vecX * vecX + vecY * vecY);
        displacements[i].x -= (vecX * gravity * alpha) / l;
        displacements[i].y -= (vecY * gravity * alpha) / l;
      }

      // For every other node
      nodes.forEach(function (u, j) {
        if (i === j) {
          return;
        }

        if (!u.x || !u.y) return;
        let _a = vecMap[v.id + "-" + u.id],
          vl2 = _a.vl2,
          vl = _a.vl;
        if (vl > max) return;
        let _b = vecMap[v.id + "-" + u.id],
          vx = _b.vx,
          vy = _b.vy;
        let depthDiff = Math.abs(u.depth - v.depth) + 1 || 1;
        if (u.comboId !== v.comboId) depthDiff++;
        let depthParam = depthDiff ? Math.pow(scale, depthDiff) : 1;
        let params = ((nodeStrength(u) * alpha) / vl2) * depthParam;
        displacements[i].x += vx * params;
        displacements[i].y += vy * params; // prevent node overlappings

        // Node overlap detection
        if (i < j && preventNodeOverlap) {
          let ri = nodeSizeFunc(v) + nodeSpacingFunc(v);
          let rj = nodeSizeFunc(u) + nodeSpacingFunc(u);
          let r = ri + rj;

          if (vl2 < r * r) {
            let ll = ((r - vl) / vl) * nodeCollideStrength;
            let rj2 = rj * rj;
            let rratio = rj2 / (ri * ri + rj2);
            let xl = vx * ll;
            let yl = vy * ll;
            displacements[i].x += xl * rratio;
            displacements[i].y += yl * rratio;
            rratio = 1 - rratio;
            displacements[j].x -= xl * rratio;
            displacements[j].y -= yl * rratio;
          }
        }
      });
    });
  };

  /**
   * Calculate the attractive force between the node pair with edge
   * @param displacements The array stores the displacements for nodes
   * @param vecMap The map stores vector between each node pair
   */
  CustomCombo.prototype.calAttractive = function (displacements, vecMap) {
    let self = this;
    let edges = self.edges;
    let linkDistance = self.linkDistance;
    let alpha = self.alpha;
    let edgeStrength = self.edgeStrength;
    let bias = self.bias;
    let scale = self.depthAttractiveForceScale;
    edges.forEach(function (e, i) {
      if (!e.source || !e.target || e.source === e.target) return;
      let uIndex = self.nodeIdxMap[e.source];
      let vIndex = self.nodeIdxMap[e.target];
      let u = self.nodeMap[e.source];
      let v = self.nodeMap[e.target];
      let depthDiff = Math.abs(u.depth - v.depth);

      if (u.comboId === v.comboId) {
        depthDiff = depthDiff / 2;
      }

      let depthParam = depthDiff ? Math.pow(scale, depthDiff) : 1;

      if (u.comboId !== v.comboId && depthParam === 1) {
        depthParam = scale / 2;
      } else if (u.comboId === v.comboId) {
        depthParam = 2;
      }

      if (
        !(0, _util.isNumber)(v.x) ||
        !(0, _util.isNumber)(u.x) ||
        !(0, _util.isNumber)(v.y) ||
        !(0, _util.isNumber)(u.y)
      )
        return;
      let _a = vecMap[e.target + "-" + e.source],
        vl = _a.vl,
        vx = _a.vx,
        vy = _a.vy;
      let l =
        ((vl - linkDistance(e)) / vl) * alpha * edgeStrength(e) * depthParam;
      let vecX = vx * l;
      let vecY = vy * l;
      let b = bias[i];
      displacements[vIndex].x -= vecX * b;
      displacements[vIndex].y -= vecY * b;
      displacements[uIndex].x += vecX * (1 - b);
      displacements[uIndex].y += vecY * (1 - b);
    });
  };

  CustomCombo.prototype.destroy = function () {
    let self = this;
    self.positions = null;
    self.nodes = null;
    self.edges = null;
    self.destroyed = true;
  };

  CustomCombo.prototype.updateCfg = function (cfg) {
    let self = this;
    mix(self, cfg);
  };

  CustomCombo.prototype.layout = function (data) {
    let self = this;
    self.init(data);
    self.execute();
  };

  return CustomCombo;
})();

export default CustomCombo;
