import React from "react";
import { useAuth } from "authentication";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth();
  loginWithRedirect();
  return <></>;
};

export default LoginPage;
