import React, { useContext } from "react";
import { Menu } from "antd";
import { MenuItemProps as AntdMenuItemProps } from "antd/es/menu/MenuItem";

import { Pane, Row } from "components/base/layout";
import Icon from "components/base/Icon";

import SelectContext from "./SelectContext";

type MenuItemProps = {
  value: any;
} & AntdMenuItemProps;

export const SelectMenuItem = ({
  value,
  children,
  ...props
}: MenuItemProps) => {
  const { selectedValue, toggleSelectedValue } = useContext(SelectContext);
  const isSelected = Array.isArray(selectedValue)
    ? selectedValue.includes(value)
    : selectedValue === value;

  return (
    <Menu.Item
      {...props}
      onClick={() => toggleSelectedValue(value)}
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        boxShadow: isSelected
          ? "inset 0 0 0 30px hsla(0, 0%, 0%, 0.05)"
          : undefined,
      }}
    >
      <Row centerY>
        {children}
        {isSelected && (
          <Pane className="onHover" marginLeft="auto" paddingLeft={10}>
            <Icon name="fa-times" opacity={0.5} />
          </Pane>
        )}
      </Row>
    </Menu.Item>
  );
};

export default SelectMenuItem;
