import React from "react";

import { useResource, useFetcher } from "rest-hooks";
import { useParams } from "react-router-dom";

import {
  enforceCasing,
  getNumberAsShorthandString,
  getHumanReadableDatetime,
  getTimeFromNow,
  getSortedEntitiesByName,
} from "utils";
import CustomField from "types/CustomField";

import TablesResource from "resources/tables";
import RelationshipsResource from "resources/relationships";

import TagsResource, { entityTagsListUpdater } from "resources/tags";
import { TagEntityType } from "resources/tag-entity-type";

import { Link, MetaTitle, PageSection, Stat } from "components/base";
import { H2, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import {
  ProtectedEntityOwnerSelect,
  ProtectedEntityTagsSelect,
  ProtectedEntityDescription,
  ProtectedEntityImportanceToggle,
} from "components/protected";
import RequestEntityDocumentationController from "components/RequestEntityDocumentationController";
import EntityContext from "contexts/EntityContext";
import TableColumnsSection from "./TableColumnsSection";
import TableGraphSection from "./TableGraphSection";

const TablePage = () => {
  const { tableName, schemaName, databaseName } = useParams<{
    tableName: string;
    schemaName: string;
    databaseName: string;
  }>();

  const {
    results: [table],
  } = useResource(TablesResource.detailByName(), {
    name: tableName,
    schemaName,
    databaseName,
  });

  const { results: relationships } = useResource(RelationshipsResource.list(), {
    entityType: "column",
    tableId: table.id,
    includeSecondDegreeNeighbors: false,
  });

  const tagsResourceList = TagsResource.list();
  const addTagToTable = useFetcher(TablesResource.addTag());
  const createAndAddTagToTable = useFetcher(
    TablesResource.createAndAddTagToTable()
  );
  const removeTagFromTable = useFetcher(TablesResource.removeTag());
  const addTableOwner = useFetcher(TablesResource.addOwner());
  const removeTableOwner = useFetcher(TablesResource.removeOwner());

  const updateTable = useFetcher(TablesResource.partialUpdate());
  const requestDocumentation = useFetcher(
    TablesResource.requestDocumentation()
  );
  const resolveDocumentation = useFetcher(
    TablesResource.resolveDocumentation()
  );

  const tableOwner = table.entityUserRelations.find(
    (entityUserRelation) => entityUserRelation.relationType === "owner"
  );

  const title = `${enforceCasing(table.schema.name)}.${enforceCasing(
    table.name
  )}`;

  const sortedCustomFields = getSortedEntitiesByName<CustomField>(
    table.customFields
  );

  return (
    <EntityContext.Provider value={{ entity: table }}>
      <Row height="100%">
        <Pane paddingX={35} paddingY={22} flexGrow={1} overflowY="scroll">
          <MetaTitle>{title}</MetaTitle>
          <Row>
            <Pane>
              <Row
                centerY
                marginBottom={2}
                opacity={0.8}
                textTransform="uppercase"
              >
                <Link to={table.schemaResource.databaseResource.catalogUrlPath}>
                  <Pane fontSize={12}>
                    {<Code>{table.schema.database.name}</Code>}
                  </Pane>
                </Link>
                <Span fontSize={12} marginX={5}>
                  /
                </Span>
                <Link to={table.schemaResource.catalogUrlPath}>
                  <Pane fontSize={12}>{<Code>{table.schema.name}</Code>}</Pane>
                </Link>
                <Span fontSize={12} marginX={5}>
                  /
                </Span>
                <Pane fontSize={12}>{<Code>{table.type}</Code>}</Pane>
              </Row>

              <H2 display="flex" alignItems="center" marginBottom={21}>
                <TablesResource.Icon
                  marginRight={8}
                  type={table.type}
                  fontSize={17}
                />
                {enforceCasing(table.name)}
              </H2>
            </Pane>
            <Row marginLeft="auto" alignItems="flex-start">
              <Row centerY>
                <ProtectedEntityOwnerSelect
                  value={tableOwner?.userId}
                  selectUser={(selectedUserId) => {
                    addTableOwner({}, { id: table.id, userId: selectedUserId });
                  }}
                  deselectUser={(selectedUserId) =>
                    removeTableOwner({ id: table.id, userId: selectedUserId })
                  }
                  selectedUserIds={table.entityUserRelations
                    .filter(
                      (entityUserRelation) =>
                        entityUserRelation.relationType === "owner"
                    )
                    .map((entityUserRelation) => entityUserRelation.userId)}
                />
                <RequestEntityDocumentationController
                  marginLeft={8}
                  documentationRequests={table.documentationRequests}
                  requestDocumentation={() => {
                    requestDocumentation({ id: table.id }, {});
                  }}
                  resolveDocumentation={() => {
                    resolveDocumentation({ id: table.id }, {});
                  }}
                />

                <ProtectedEntityImportanceToggle
                  marginLeft={8}
                  isImportant={table.isImportant}
                  toggleEntityIsImportant={(isImportant: boolean) =>
                    updateTable({ id: table.id }, { isImportant })
                  }
                />
              </Row>
            </Row>
          </Row>
          <Row>
            <Pane minWidth={450} minHeight={44}>
              <ProtectedEntityDescription
                paddingBottom={10}
                description={table.description}
                onChange={(description) => {
                  updateTable({ id: table.id }, { description });
                }}
              />
            </Pane>
          </Row>
          <PageSection flexShrink={0}>
            <Row centerY>
              <Stat name="Cols" title={table.statistics.col_count || 0}>
                {getNumberAsShorthandString(table.statistics.col_count || 0)}
              </Stat>
              {table.isTable && (
                <Stat name="Rows" title={table.statistics.row_count || 0}>
                  {getNumberAsShorthandString(table.statistics.row_count || 0)}
                </Stat>
              )}
              {table.sourceCreatedAt && (
                <Stat
                  name="Created"
                  title={getHumanReadableDatetime(table.sourceCreatedAt)}
                >
                  {getTimeFromNow(table.sourceCreatedAt)}
                </Stat>
              )}
              {table.sourceUpdatedAt && (
                <Stat
                  name="Updated"
                  title={getHumanReadableDatetime(table.sourceUpdatedAt)}
                >
                  {getTimeFromNow(table.sourceUpdatedAt)}
                </Stat>
              )}

              {sortedCustomFields.map((customField) => (
                <Stat key={customField.id} name={customField.name}>
                  {customField.value}
                </Stat>
              ))}

              <ProtectedEntityTagsSelect
                tagIds={table.tagIds}
                tagEntityType={TagEntityType.table}
                addTagToEntity={(tagId: string) =>
                  addTagToTable({ id: table.id }, { tagId })
                }
                createAndAddTagToEntity={(newTagName: string) =>
                  createAndAddTagToTable({ id: table.id }, { newTagName }, [
                    [tagsResourceList, {}, entityTagsListUpdater],
                  ])
                }
                removeTagFromEntity={(tagId: string) =>
                  removeTagFromTable({ table, tagId }, {})
                }
                marginLeft="auto"
              />
            </Row>
          </PageSection>
          <TableColumnsSection tableId={table.id} />
        </Pane>

        {relationships.length < 14 && <TableGraphSection table={table} />}
      </Row>
    </EntityContext.Provider>
  );
};

export default TablePage;
