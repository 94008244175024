import React, { useState } from "react";
import { useFetcher } from "rest-hooks";

import ColumnsResource from "resources/columns";
import TablesResource from "resources/tables";
import TagsResource, { entityTagsListUpdater } from "resources/tags";
import { TagEntityType } from "resources/tag-entity-type";

import { Icon, MiniIconButton, Link, Tooltip } from "components/base";
import { Code } from "components/base/type";
import { Row, Pane, Span } from "components/base/layout";

import { useAuth } from "authentication";
import {
  ProtectedEntityTagsSelect,
  ProtectedEntityDescription,
  ProtectedEntityImportanceToggle,
} from "components/protected";
import EntityContext from "contexts/EntityContext";
import EditEntityRelationshipsButton from "components/EditEntityRelationshipsButton";
import DataTypeIcon from "components/DataTypeIcon";
import { DataSpecificType } from "types/dataType";
import GraphContext from "components/SharedGraph/GraphContext";
import RequestEntityDocumentationController from "components/RequestEntityDocumentationController";

import RelationshipsResource, {
  entityRelationshipsAddToListUpdater,
  entityRelationshipsRemoveFromListUpdater,
} from "resources/relationships";

const ColumnRow = ({ column }: { column: ColumnsResource }) => {
  const { user } = useAuth();
  const { isGraphActive: isGraphChild } = React.useContext(GraphContext);
  const tagsResourceList = TagsResource.list();
  const relationshipsResourceList = RelationshipsResource.list();
  const addTagToColumn = useFetcher(ColumnsResource.addTag());
  const createAndAddTagToColumn = useFetcher(
    ColumnsResource.createAndAddTagToColumn()
  );
  const updateColumn = useFetcher(ColumnsResource.partialUpdate());
  const removeTagFromColumn = useFetcher(ColumnsResource.removeTag());

  const addRelationship = useFetcher(ColumnsResource.addRelationship());
  const removeRelationship = useFetcher(ColumnsResource.removeRelationship());

  const requestDocumentation = useFetcher(
    ColumnsResource.requestDocumentation()
  );
  const resolveDocumentation = useFetcher(
    ColumnsResource.resolveDocumentation()
  );

  const [hasExpandedRelationships, setHasExpandedRelationships] = useState(
    false
  );
  const [hasExpandedDescription, setHasExpandedDescription] = useState(false);

  if (!column.id) {
    return <></>;
  }

  const relationships =
    !hasExpandedRelationships && column.columnRelationships.length > 3
      ? column.columnRelationships.slice(0, 2)
      : column.columnRelationships;

  const numHiddenRelationships =
    column.columnRelationships.length - relationships.length;

  const hasDescription = column.description?.descriptionText?.length > 0;

  return (
    <EntityContext.Provider value={{ entity: column }}>
      <Pane borderBottom="1px solid #F2F2F2">
        <Pane paddingY={7} className="onHoverParent">
          <Row width="100%" minHeight={33} alignItems="start">
            <Pane paddingRight={42} paddingY={4} minWidth={0}>
              <Row centerY minHeight={24}>
                <DataTypeIcon
                  marginRight={12}
                  flexShrink={0}
                  dataType={column.type as DataSpecificType}
                />
                <Code
                  fontSize={13}
                  fontWeight={500}
                  flexShrink={0}
                  paddingRight={16}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  maxWidth="100%"
                  enforceCasing
                >
                  {column.name}
                </Code>
                {user.canWriteRelationships && (
                  <EditEntityRelationshipsButton
                    marginRight={6}
                    iconOnly={isGraphChild}
                    entityId={column.id}
                    selectedRelationships={column.columnRelationships}
                    removeRelationship={(relationshipId: string) => {
                      removeRelationship({ column, relationshipId }, {}, [
                        [
                          relationshipsResourceList,
                          { entityType: "column" },
                          entityRelationshipsRemoveFromListUpdater(
                            relationshipId
                          ),
                        ],
                      ]);
                    }}
                    addRelationship={(
                      childId: string,
                      relationshipType: string
                    ) => {
                      addRelationship(
                        { id: column.id },
                        {
                          childId,
                          type: relationshipType,
                        },
                        [
                          [
                            relationshipsResourceList,
                            { entityType: "column" },
                            entityRelationshipsAddToListUpdater,
                          ],
                        ]
                      );
                    }}
                  />
                )}
                {user.canWriteDescriptions &&
                  !hasDescription &&
                  !hasExpandedDescription && (
                    <MiniIconButton
                      iconType="far"
                      iconName="fa-pencil"
                      title="Add description"
                      onClick={() => setHasExpandedDescription(true)}
                    >
                      {!isGraphChild && "Description"}
                    </MiniIconButton>
                  )}
              </Row>
              {relationships.map((relation) => (
                <Row
                  key={relation.id}
                  fontSize={12}
                  paddingLeft={26}
                  paddingBottom={2}
                  width="fit-content"
                  maxWidth={"calc(100% + 80px)"}
                >
                  <Tooltip
                    placement={isGraphChild ? "left" : "right"}
                    title={
                      <Pane whiteSpace="nowrap" fontSize={13}>
                        <Span opacity={0.65} marginRight={2}>
                          Go to{" "}
                        </Span>
                        <Code color="white">
                          {TablesResource.buildSlug({
                            tableName: relation.column.table.name,
                            schemaName: relation.column.table.schema.name,
                            databaseName:
                              relation.column.table.schema.database.name,
                          })}
                        </Code>
                      </Pane>
                    }
                  >
                    <Link
                      fontWeight={400}
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      to={
                        isGraphChild
                          ? relation.column.tableResource.graphUrlPath
                          : relation.column.tableResource.catalogUrlPath
                      }
                    >
                      <Icon
                        name="fa-level-up"
                        className="fa-rotate-90 highlightOnHover"
                        fixedWidth
                      />
                      <Code marginLeft={8} enforceCasing>
                        {`${relation.column.table.schema.name}.${relation.column.table.name}.${relation.column.name}`}
                      </Code>
                    </Link>
                  </Tooltip>
                </Row>
              ))}
              {numHiddenRelationships > 0 && (
                <Row
                  fontSize={12}
                  paddingLeft={26}
                  paddingBottom={2}
                  width="fit-content"
                  maxWidth={"calc(100% + 80px)"}
                >
                  <Link
                    fontWeight={400}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    onClick={() => setHasExpandedRelationships(true)}
                  >
                    Show {numHiddenRelationships} more relationship
                    {numHiddenRelationships !== 1 && "s"}
                  </Link>
                </Row>
              )}
            </Pane>
            <Row marginLeft="auto" paddingY={4} centerY flexShrink={0}>
              <ProtectedEntityTagsSelect
                minWidth={0}
                iconOnly={isGraphChild}
                tagIds={column.tagIds}
                tagEntityType={TagEntityType.column}
                addTagToEntity={(tagId: string) =>
                  addTagToColumn({ id: column.id }, { tagId })
                }
                createAndAddTagToEntity={(newTagName: string) =>
                  createAndAddTagToColumn({ id: column.id }, { newTagName }, [
                    [tagsResourceList, {}, entityTagsListUpdater],
                  ])
                }
                removeTagFromEntity={(tagId: string) =>
                  removeTagFromColumn({ column, tagId }, {})
                }
              />
              <RequestEntityDocumentationController
                marginLeft={8}
                iconOnly
                documentationRequests={column.documentationRequests}
                requestDocumentation={() => {
                  requestDocumentation({ id: column.id }, {});
                }}
                resolveDocumentation={() => {
                  resolveDocumentation({ id: column.id }, {});
                }}
              />
              <ProtectedEntityImportanceToggle
                marginLeft={8}
                isImportant={column.isImportant}
                toggleEntityIsImportant={(isImportant: boolean) =>
                  updateColumn({ id: column.id }, { isImportant })
                }
              />
            </Row>
          </Row>
          {(hasDescription || hasExpandedDescription) && (
            <Row
              paddingLeft={27}
              paddingRight={16}
              opacity={0.8}
              minHeight={26}
              position="relative"
            >
              <ProtectedEntityDescription
                fontSize={13}
                marginTop={-5}
                description={column.description}
                autofocus={hasExpandedDescription}
                noDefer={hasExpandedDescription}
                onChange={(description) => {
                  updateColumn({ id: column.id }, { description });
                }}
              />
            </Row>
          )}
        </Pane>
      </Pane>
    </EntityContext.Provider>
  );
};

export default ColumnRow;
