import { createContext, useContext } from "react";
import EntityType from "resources/entity-type";

export const EntityContext = createContext<{
  entity: {
    id: string;
    entityType: EntityType;
  };
}>({
  entity: {
    id: "",
    entityType: EntityType.Database,
  },
});

export const useEntityType = () => {
  const {
    entity: { entityType },
  } = useContext(EntityContext);
  return entityType;
};

export const useEntityIdentifiers = () => {
  const {
    entity: { id: entityId, entityType },
  } = useContext(EntityContext);
  return { entityId, entityType };
};

export default EntityContext;
