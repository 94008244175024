import React from "react";

import { useResource, useFetcher } from "rest-hooks";
import { useParams } from "react-router-dom";

import { MetaTitle, Stat } from "components/base";
import { H2, H4, Code } from "components/base/type";
import { Pane, Row } from "components/base/layout";

import { enforceCasing, getTimeFromNow, getHumanReadableDatetime } from "utils";

import DatabasesResource from "resources/databases";
import DatabaseSchemasList from "components/DatabaseSchemasList";
import EntityContext from "contexts/EntityContext";
import { ProtectedEntityDescription } from "components/protected";

const DatabasePage = () => {
  const { databaseName } = useParams<{
    databaseName: string;
  }>();

  const [
    {
      results: [database],
    },
  ] = useResource([
    DatabasesResource.detailByName(),
    {
      name: databaseName,
    },
  ]);

  const updateDatabase = useFetcher(DatabasesResource.updateShape());

  return (
    <EntityContext.Provider value={{ entity: database }}>
      <Pane paddingX={35} paddingY={22}>
        <MetaTitle>{enforceCasing(database.name)}</MetaTitle>
        <Row>
          <Pane>
            <Row
              centerY
              marginBottom={2}
              opacity={0.8}
              textTransform="uppercase"
            >
              <Pane fontSize={12}>{<Code>Database</Code>}</Pane>
            </Row>
            <H2 display="flex" alignItems="center" marginBottom={21}>
              <DatabasesResource.Icon fontSize={17} marginRight={8} />
              {enforceCasing(database.name)}
            </H2>
          </Pane>
          <Pane marginLeft={200}>
            <Row>
              <Stat name="Schemas">{database.statistics.schema_count}</Stat>
              <Stat name="Tables">{database.statistics.table_count}</Stat>
              {database.sourceCreatedAt && (
                <Stat
                  name="Created"
                  title={getHumanReadableDatetime(database.sourceCreatedAt)}
                >
                  {getTimeFromNow(database.sourceCreatedAt)}
                </Stat>
              )}
              {database.sourceUpdatedAt && (
                <Stat
                  name="Updated"
                  title={getHumanReadableDatetime(database.sourceUpdatedAt)}
                >
                  {getTimeFromNow(database.sourceUpdatedAt)}
                </Stat>
              )}
            </Row>
          </Pane>
        </Row>
        <Row>
          <Pane width={650}>
            <ProtectedEntityDescription
              paddingBottom={20}
              description={database.description}
              onChange={(description) => {
                updateDatabase({ id: database.id }, { description });
              }}
            />
          </Pane>
        </Row>

        <Pane marginTop={10}>
          <H4>Schemas</H4>
          <Pane marginTop={16} borderTop="1px solid #eee">
            <DatabaseSchemasList database={database} />
          </Pane>
        </Pane>
      </Pane>
    </EntityContext.Provider>
  );
};

export default DatabasePage;
