import React from "react";
import { Row } from "./Row";
import { UIProps } from "components/base/types";

interface IColumnProps {
  centerX?: boolean;
  centerY?: boolean;
  center?: boolean;
}

export const Column = React.forwardRef<HTMLDivElement, IColumnProps & UIProps>(
  ({ centerX, centerY, center, ...props }, ref) => {
    const alignItems: string | undefined =
      centerX || center ? "center" : undefined;
    const justifyContent: string | undefined =
      centerY || center ? "center" : undefined;

    return (
      <Row
        ref={ref}
        display="flex"
        flexDirection="column"
        alignItems={alignItems}
        justifyContent={justifyContent}
        {...props}
      />
    );
  }
);
