import React from "react";
import { UIProps } from "components/base/types";
import { Row } from "components/base/layout";
import PersonIcon from "components/base/PersonIcon";

export const PersonRow = ({
  name,
  ...rowProps
}: { name: string } & UIProps) => {
  return (
    <Row centerY color="#171e26" fontWeight={400} fontSize={14} {...rowProps}>
      <PersonIcon
        name={name}
        size={PersonIcon.Size.S}
        marginRight={6}
        noTooltip
      />
      {name}
    </Row>
  );
};

export default PersonRow;
