import React from "react";
import { Form } from "antd";

import { Input } from "components/base";

import { Connection } from "types/Connection";

const StandardConnectionFormItems = ({
  connection,
  portPlaceholder,
}: {
  connection: Connection;
  portPlaceholder?: number;
}) => (
  <>
    <Form.Item
      label="Nickname"
      name="name"
      rules={[{ required: true, message: "Nickname required" }]}
    >
      <Input placeholder={`e.g. ${connection.name} Prod`} />
    </Form.Item>
    <Form.Item
      label="Host"
      name="host"
      rules={[{ required: true, message: "Host required" }]}
    >
      <Input placeholder={`e.g. 127.0.0.1`} />
    </Form.Item>
    <Form.Item
      label="Port"
      name="port"
      rules={[{ required: true, message: "Port required" }]}
    >
      <Input placeholder={`e.g. ${portPlaceholder || 5345}`} />
    </Form.Item>
    <Form.Item
      label="Database"
      name="db"
      rules={[{ required: true, message: "Database required" }]}
    >
      <Input placeholder={`database name`} />
    </Form.Item>
    <Form.Item
      label="Username"
      name="username"
      rules={[{ required: true, message: "Username required" }]}
    >
      <Input placeholder={`user name`} />
    </Form.Item>
    <Form.Item
      label="Password"
      name="password"
      rules={[{ required: true, message: "Password required" }]}
    >
      <Input.Password placeholder={`password`} />
    </Form.Item>
  </>
);

export default StandardConnectionFormItems;
