import React from "react";
import { Form } from "antd";

import { Input } from "components/base";

const StandardConnectionFormItems = () => (
  <>
    <Form.Item
      label="Nickname"
      name="name"
      rules={[{ required: true, message: "Nickname required" }]}
    >
      <Input placeholder={`Snowflake Prod`} />
    </Form.Item>
    <Form.Item
      label="Account"
      name="host"
      rules={[{ required: true, message: "Snowflake account required" }]}
    >
      <Input placeholder={`e.g. example.us-east-1`} />
    </Form.Item>
    <Form.Item
      label="Username"
      name="username"
      rules={[{ required: true, message: "Username required" }]}
    >
      <Input placeholder={`e.g. analytics`} />
    </Form.Item>
    <Form.Item
      label="Password"
      name="password"
      rules={[{ required: true, message: "Password required" }]}
    >
      <Input.Password placeholder={`password`} />
    </Form.Item>
    <Form.Item
      label="Database"
      name="db"
      rules={[{ required: true, message: "Database name required" }]}
    >
      <Input placeholder={`e.g. example_production`} />
    </Form.Item>
    <Form.Item
      label="Warehouse"
      name="wh"
      rules={[{ required: true, message: "Snowflake warehouse required" }]}
    >
      <Input placeholder={`e.g. warehouse_name`} />
    </Form.Item>
  </>
);

export default StandardConnectionFormItems;
