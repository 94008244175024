import React, { useContext } from "react";
import TagsResource from "resources/tags";
import { Tag } from "components/base";
import EntityTagsContext from "./EntityTagsContext";

const Tags = () => {
  const { availableTags, entitysTagIds } = useContext(EntityTagsContext);
  const entitysTags = availableTags.filter((tag) =>
    entitysTagIds.includes(tag.id)
  );
  return (
    <>
      {entitysTags.map((tag: TagsResource) => (
        <Tag key={tag.id}>{tag.name}</Tag>
      ))}
    </>
  );
};

export default Tags;
