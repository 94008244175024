import React, { useContext } from "react";
import { ConnectionType } from "types/Connection";
import DbtSetupTab from "./DbtSetupTab";
import StandardSetupTab from "./StandardSetupTab";
import ConnectionCardContext from "../../ConnectionCardContext";

const SetupTab = () => {
  const { connection } = useContext(ConnectionCardContext);

  switch (connection.type) {
    case ConnectionType.Dbt:
      return <DbtSetupTab />;
    default:
      return <StandardSetupTab />;
  }
};

export default SetupTab;
