import React from "react";
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";
import Box from "ui-box";
import { UIProps } from "components/base/types";

import styled from "styled-components";

interface INavLinkStyleProps {
  defaultOpacity?: number;
  to: string;
}

const StyledNavLink = styled<React.FC<INavLinkStyleProps>>(
  ({ defaultOpacity, ...rest }) => <RouterNavLink {...rest} />
)`
  display: block;
  color: ${(props) => props.theme["$black-0"]};
  font-weight: 500;
  font-size: 14px;
  opacity: ${(props) =>
    props.defaultOpacity !== undefined ? props.defaultOpacity : 0.5};

  &[disabled] {
    color: ${(props) => props.theme["$black-0"]};
    opacity: 0.15;
  }

  &:hover {
    color: ${(props) => props.theme["$black-0"]};
    opacity: 0.8;
  }

  &.active,
  &.active:hover {
    opacity: 1;
  }
`;

export type NavLinkProps = RouterNavLinkProps & INavLinkStyleProps & UIProps;

export const NavLink = (props: NavLinkProps) => (
  <Box is={StyledNavLink} paddingY={18} paddingX={8} {...props} />
);

export default NavLink;
