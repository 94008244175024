import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useResource, useFetcher } from "rest-hooks";
import TablesResource from "resources/tables";
import SearchResource from "resources/search";
import RelationshipsResource from "resources/relationships";
import EntitiesResource from "resources/entities";
import { Pane, Row, Span } from "components/base/layout";
import { Code } from "components/base/type";
import { Select, MiniIconButton, withLoader } from "components/base";
import NetworkGraphController from "components/NetworkGraphController";
import TableMenuItemContents from "components/TableMenuItemContents";

import { PrismAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useAuth } from "authentication";

const TableGraph = ({ table }: { table: TablesResource }) => {
  const history = useHistory();
  const { user } = useAuth();
  const [isBuildMode, setIsBuildMode] = useState(false);

  const { results: relationships } = useResource(RelationshipsResource.list(), {
    entityType: "column",
    tableId: table.id,
    includeSecondDegreeNeighbors: isBuildMode,
  });

  const { results: entities } = useResource(
    EntitiesResource.listImportant(),
    {}
  );

  const search = useFetcher(SearchResource.list());
  const [searchedItems, setSearchedItems] = React.useState<SearchResource[]>(
    []
  );

  const [destinationTable, setDestinationTable] = useState<
    | {
        id: string;
        tableName: string;
        schemaName: string;
        databaseName: string;
      }
    | undefined
  >();

  const destinationTableId = destinationTable?.id;

  const getShortestPathRelationships = useFetcher(
    RelationshipsResource.getShortestPathRelationships()
  );

  const [renderedRelationships, setRenderedRelationships] = useState<
    RelationshipsResource[]
  >([]);

  const [builtSQL, setBuiltSQL] = useState<string>("");

  React.useEffect(() => {
    setRenderedRelationships(relationships);
  }, [relationships]);

  const setDestinationTableAndRerender = React.useCallback(
    async (selectedDestinationTable) => {
      const newDestinationTable =
        selectedDestinationTable?.id !== destinationTableId
          ? selectedDestinationTable
          : undefined;
      setDestinationTable(newDestinationTable);
      if (newDestinationTable) {
        const results = (await getShortestPathRelationships({
          fromTableId: table.id,
          toTableId: newDestinationTable.id,
        })) as any;

        const mappedPathwayRelationships = results?.relationships[0]?.map(
          (rel: any) => rel?.originalEdge
        );
        setRenderedRelationships(mappedPathwayRelationships);
        setBuiltSQL(results?.sql[0]);
      } else {
        setRenderedRelationships(relationships);
        setBuiltSQL("");
      }
    },
    [relationships, table, destinationTableId, getShortestPathRelationships]
  );

  if (!relationships || !relationships.length) {
    return null;
  }

  const onSearch = async (input: string) => {
    if (!input) {
      setSearchedItems([]);
      return;
    }

    const { results } = await search({
      query: input,
      entityType: "table",
    });

    setSearchedItems(
      results.filter(
        (searchResult: SearchResource) =>
          searchResult.type === "table" && searchResult.entityId !== table.id
      )
    );
  };

  return (
    <Row height="100%" position="relative">
      <Row position="absolute" left={20} right={20} top={20} zIndex={1}>
        {user.canViewSQLBuilderMode && (
          <MiniIconButton
            iconType="far"
            iconName="fa-hammer"
            title="Enter SQL build mode"
            active={!!isBuildMode}
            onClick={() => setIsBuildMode((isBuildMode) => !isBuildMode)}
          >
            SQL builder mode
          </MiniIconButton>
        )}
        {isBuildMode && (
          <Select
            value={destinationTableId}
            onChange={async (selectedValue: string) => {
              if (selectedValue === destinationTableId) {
                setDestinationTableAndRerender(undefined);
              } else {
                const selectedTableSearchResult = searchedItems.find(
                  (searchResult) => searchResult.entityId
                );
                if (selectedTableSearchResult) {
                  setDestinationTableAndRerender({
                    id: selectedTableSearchResult.entityId,
                    tableName:
                      selectedTableSearchResult.attributes.tableName || "",
                    schemaName:
                      selectedTableSearchResult.attributes.schemaName || "",
                    databaseName:
                      selectedTableSearchResult.attributes.databaseName || "",
                  });
                }
              }
            }}
            searchPlaceholder="Search tables..."
            onSearch={onSearch}
            menuItems={
              searchedItems.length
                ? searchedItems.map((searchResult) => (
                    <Select.MenuItem
                      key={searchResult.entityId}
                      value={searchResult.entityId}
                    >
                      <TableMenuItemContents
                        tableName={searchResult.attributes.tableName || ""}
                        schemaName={searchResult.attributes.schemaName || ""}
                        databaseName={
                          searchResult.attributes.databaseName || ""
                        }
                      />
                    </Select.MenuItem>
                  ))
                : entities
                    .filter((entity) => entity.isTable)
                    .map((entity) => (
                      <Select.MenuItem key={entity.id} value={entity.id}>
                        <TableMenuItemContents
                          tableName={entity.tableResource.name || ""}
                          schemaName={entity.tableResource.schema.name || ""}
                          databaseName={
                            entity.tableResource.schema.database.name || ""
                          }
                          isImportant={entity.tableResource.isImportant}
                        />
                      </Select.MenuItem>
                    ))
            }
          >
            <MiniIconButton
              iconType="far"
              iconName="fa-search"
              title="Find path to another table"
              marginLeft={6}
            >
              Find table
            </MiniIconButton>
          </Select>
        )}
        {destinationTable && (
          <Pane marginLeft="auto">
            <Span
              marginLeft={12}
              fontSize={12}
              opacity={0.8}
              backgroundColor="#eee"
              paddingY={7}
              paddingX={9}
            >
              Path to{" "}
              <Code color="inherit" fontWeight={500} enforceCasing>
                {`${destinationTable.schemaName}.${destinationTable.tableName}`}
              </Code>
            </Span>
          </Pane>
        )}
      </Row>
      <Row height={builtSQL ? "calc(100% - 180px)" : "100%"} flexGrow={1}>
        <NetworkGraphController
          key={isBuildMode + "graph"}
          relationships={renderedRelationships}
          tableName={table.name}
          schemaName={table.schema.name}
          databaseName={table.schema.database.name}
          defaultControls={{
            groupBySchema: false,
            includeSecondDegreeConnections: false,
            isNodeSizeDynamic: false,
            onlyCentralTables: false,
          }}
          layout="dagre"
          hideGraphControls
          hideMiniMap
          onClickNodeCallback={({
            id,
            tableName,
            schemaName,
            databaseName,
          }) => {
            console.log("clicked", {
              id,
              tableName,
              schemaName,
              databaseName,
            });
            if (!isBuildMode) {
              if (id !== table.id) {
                history.push(
                  TablesResource.buildCatalogUrlPath({
                    tableName,
                    schemaName,
                    databaseName,
                  })
                );
              }
            } else {
              setDestinationTableAndRerender({
                id,
                tableName,
                schemaName,
                databaseName,
              });
            }
          }}
          hideDetailBox
        />
      </Row>
      {builtSQL && (
        <Pane
          position="absolute"
          bottom={-6}
          left={0}
          right={0}
          padding={0}
          fontFamily="Fira Code"
          fontSize={12}
          backgroundColor="rgb(40, 42, 54)"
          paddingTop="0.5em"
          paddingX="1em"
          paddingBottom="1em"
        >
          <SyntaxHighlighter language="sql" style={dracula}>
            {builtSQL}
          </SyntaxHighlighter>
        </Pane>
      )}
    </Row>
  );
};

export default withLoader(TableGraph);
