import { Graph } from "@antv/g6";
import { IG6GraphEvent } from "@antv/g6/lib/types";
import { TableNode } from "../types";

const initializeNodeClickListeners = (
  graph: Graph,
  onClickNode: (node: TableNode) => void,
  onClickHideNode?: (node: TableNode) => void
) => {
  graph.on("node:click", (event: IG6GraphEvent) => {
    const item = event.item;
    if (item) {
      if (event.target.get("name") === "collapse-icon") {
        onClickHideNode && onClickHideNode(item.getModel() as TableNode);
        event.stopPropagation();
      } else {
        const selectedNodeId = item._cfg?.id;
        if (selectedNodeId) {
          onClickNode(item.getModel() as TableNode);
        }
      }
    }
  });
};

export default initializeNodeClickListeners;
