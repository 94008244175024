import { Resource } from "@rest-hooks/rest";
import { AuthContext } from "authentication";

abstract class AuthenticatedResource extends Resource {
  static getFetchInit = (init: RequestInit) => {
    const {
      user: { accessToken },
    } = (AuthContext as any)._currentValue;

    return {
      ...init,
      headers: {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };
}

export default AuthenticatedResource;
