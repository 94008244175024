import React from "react";
import { useResource } from "rest-hooks";
import { useLocalStorage } from "@rehooks/local-storage";
import { Pane } from "components/base/layout";
import { Resizable } from "re-resizable";
import TablesResource from "resources/tables";
import RelationshipsResource from "resources/relationships";
import { GRAPH_SIDEBAR_WIDTH_STORAGE_KEY } from "constants/storageKeys";
import PageSectionColumn from "pages/CatalogPage/PageSectionColumn";
import TableGraph from "./TableGraph";

const TableGraphSection = ({ table }: { table: TablesResource }) => {
  const [userGraphSidebarWidth, setUserGraphSidebarWidth] = useLocalStorage(
    GRAPH_SIDEBAR_WIDTH_STORAGE_KEY
  );

  const { results: relationships } = useResource(RelationshipsResource.list(), {
    entityType: "column",
    tableId: table.id,
  });

  if (!relationships || !relationships.length) {
    return null;
  }

  return (
    <Pane
      is={Resizable}
      defaultSize={{ width: userGraphSidebarWidth || "400px", height: "auto" }}
      enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStop={(
        e: MouseEvent | TouchEvent,
        direction: string,
        ref: HTMLElement,
        delta: object
      ) => {
        setUserGraphSidebarWidth(ref.offsetWidth + "px");
      }}
      flexShrink={0}
      overflow="scroll"
    >
      <PageSectionColumn
        fontSize={14}
        padding={0}
        overflow="visible"
        borderLeft="1px solid #eee"
        height="calc(100vh - 63px)"
      >
        <TableGraph key={table.id} table={table} />
      </PageSectionColumn>
    </Pane>
  );
};

export default TableGraphSection;
