import React from "react";
import { useAuth } from "authentication";
import { Spinner } from "evergreen-ui";

import { Row } from "components/base/layout";

import UnauthenticatedApp from "apps/UnauthenticatedApp";
import AuthenticatedApp from "apps/AuthenticatedApp";

const App = () => {
  const { error, isLoading, isAuthenticated } = useAuth();

  if (error) {
    console.error(error);
    return <>error</>;
  }

  if (isLoading) {
    return (
      <Row center height="100vh">
        <Spinner />
      </Row>
    );
  }

  if (!isAuthenticated) {
    return <UnauthenticatedApp />;
  }

  return <AuthenticatedApp />;
};

export default App;
