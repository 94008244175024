import React from "react";
import Tooltip from "components/base/Tooltip";
import { capitalize } from "utils";

export const EntityTypeIcon = ({
  type,
  children,
}: {
  type: string;
  children: React.ReactElement;
}) => {
  return <Tooltip title={capitalize(type)}>{children}</Tooltip>;
};

export default EntityTypeIcon;
