import React from "react";
import AntdTooltip, { TooltipProps as AntdTooltipProps } from "antd/es/tooltip";

export type TooltipProps = AntdTooltipProps &
  Partial<Pick<AntdTooltipProps, "title">>;

export const Tooltip = ({ title, ...props }: TooltipProps) => {
  if (title) {
    return <AntdTooltip color="black" title={title} {...props} />;
  }
  return <>{props.children}</>;
};

export default Tooltip;
