import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "resources/AuthenticatedResource";
import RelationshipsResource from "resources/relationships";
import TablesResource from "resources/tables";
import EntitiesResource from "resources/entities";
import TagsResource from "resources/tags";
import { buildApiUri } from "resources/api-uri";
import Description from "types/Description";
import { DataSpecificType } from "types/dataType";
import Icon, { buildIcon } from "./Icon";
import {
  ENTITY_CATALOG_ROOT_URL_PATH,
  ENTITY_GRAPH_ROOT_URL_PATH,
} from "constants/url";
import DocumentationRequest from "resources/documentation-request";
import EntityResourceType from "resources/entity-resource-type";
import EntityType from "resources/entity-type";

export type NormalizedColumnRelationship = Omit<
  RelationshipsResource,
  "pk" | "url"
> & {
  column: ColumnsResource;
};

export default class ColumnsResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly name: string = "";
  readonly type: DataSpecificType = DataSpecificType.Text;
  readonly description: Description = {
    descriptionBlocks: [],
    descriptionHtml: "",
    descriptionText: "",
  };
  readonly parentColumns: RelationshipsResource[] = [];
  readonly childColumns: RelationshipsResource[] = [];
  readonly tagIds: string[] = [];
  readonly summary: any;
  readonly table: {
    id: string;
    name: string;
    schema: {
      id: string;
      name: string;
      database: { name: string; id: string };
    };
  } = {
    id: "",
    name: "",
    schema: { name: "", id: "", database: { name: "", id: "" } },
  };
  readonly statistics: {
    ordinal_position: number;
  } = {
    ordinal_position: 0,
  };
  readonly isImportant: boolean = false;

  readonly updatedAt: Date | undefined = undefined;
  readonly documentationRequests: DocumentationRequest[] = [];
  static entityType = EntityType.Column;
  readonly entityType = ColumnsResource.entityType;

  get columnRelationships(): NormalizedColumnRelationship[] {
    return [
      ...this.parentColumns.map((columnRelation) => ({
        ...columnRelation,
        column: ColumnsResource.fromJS(columnRelation.parentColumn),
      })),
      ...this.childColumns.map((columnRelation) => ({
        ...columnRelation,
        column: ColumnsResource.fromJS(columnRelation.childColumn),
      })),
    ];
  }

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("columns");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      optimisticUpdate: (params: any, body: any) => ({
        id: params.id,
        ...body,
      }),
    });
  }

  static removeRelationship<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "DELETE",
      url({ column, relationshipId }) {
        return `${ColumnsResource.urlRoot}/${column.id}/relationships/${relationshipId}`;
      },
      optimisticUpdate: (params: any, body: any) => ({
        ...params.column,
        childColumns: params.column.childColumns.filter(
          (relation: any) => relation.id !== params.relationshipId
        ),
        parentColumns: params.column.parentColumns.filter(
          (relation: any) => relation.id !== params.relationshipId
        ),
      }),
    });
  }

  static addRelationship<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      url({ id }) {
        return `${ColumnsResource.urlRoot}/${id}/relationships`;
      },
      schema: {
        column: this,
        relationship: RelationshipsResource,
      },
    });
  }

  static addTag<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url({ id }) {
        return `${ColumnsResource.urlRoot}/${id}/tags`;
      },
    });
  }

  static createAndAddTagToColumn<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url({ id }) {
        return `${ColumnsResource.urlRoot}/${id}/tags/new`;
      },
      schema: {
        column: this,
        tag: TagsResource,
      },
    });
  }
  static removeTag<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      method: "DELETE",
      url({ column, tagId }) {
        return `${ColumnsResource.urlRoot}/${column.id}/tags/${tagId}`;
      },
      optimisticUpdate: (params: any, body: any) => ({
        ...params.column,
        tagIds: params.column.tagIds.filter(
          (tagId: string) => tagId !== params.tagId
        ),
      }),
    });
  }

  static requestDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "POST",
      url({ id }) {
        return `${ColumnsResource.urlRoot}/${id}/documentation-requests`;
      },
    });
  }

  static resolveDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      url({ id }) {
        return `${ColumnsResource.urlRoot}/${id}/documentation-requests/resolve`;
      },
    });
  }

  static buildCatalogUrlPath({
    columnName,
    tableName,
    schemaName,
    databaseName,
  }: {
    columnName: string;
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return `${ENTITY_CATALOG_ROOT_URL_PATH}/${databaseName.toLowerCase()}/${schemaName.toLowerCase()}/${tableName.toLowerCase()}/${columnName.toLowerCase()}`;
  }

  static buildGraphUrlPath({
    columnName,
    tableName,
    schemaName,
    databaseName,
  }: {
    columnName: string;
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return `${ENTITY_GRAPH_ROOT_URL_PATH}/${databaseName.toLowerCase()}/${schemaName.toLowerCase()}/${tableName.toLowerCase()}/${columnName.toLowerCase()}`;
  }

  static buildSlug({
    columnName,
    tableName,
    schemaName,
    databaseName,
  }: {
    columnName: string;
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return (
      TablesResource.buildSlug({ tableName, schemaName, databaseName }) +
      `.${columnName.toLowerCase()}`
    );
  }

  static inEntities(entities?: EntitiesResource[]) {
    return EntitiesResource.columns(entities);
  }

  get tableResource() {
    return TablesResource.fromJS(this.table);
  }

  get catalogUrlPath() {
    return this.tableResource.catalogUrlPath;
  }

  get slug() {
    return this.tableResource.slug + `.${this.name.toLowerCase()}`;
  }

  get breadcrumbs(): EntityResourceType[] {
    return [
      this.tableResource.schemaResource.databaseResource,
      this.tableResource.schemaResource,
      this.tableResource,
    ];
  }

  static Icon = Icon;
  get Icon() {
    return buildIcon(this.type);
  }
  readonly EntityClass = ColumnsResource;
}
