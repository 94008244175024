import React from "react";
import styled from "styled-components";

import { UIProps } from "components/base/types";
import { Link, Icon, MiniButton, Tooltip } from "components/base";
import { Pane } from "components/base/layout";

import SidebarExpandableSectionContext from "./SidebarExpandableSectionContext";

const SidebarEntityLinkRow = ({
  children,
  title,
  expandable,
  ...props
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  expandable?: boolean;
} & UIProps) => {
  const { active, expanded, setExpanded } = React.useContext(
    SidebarExpandableSectionContext
  );

  return (
    <Tooltip
      title={title}
      placement="right"
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    >
      <div>
        <StyledSidebarEntityLinkRow
          display="flex"
          alignItems="center"
          minHeight={27}
          paddingLeft={14}
          paddingRight={16}
          defaultOpacity={0.6}
          fontSize={13}
          fontWeight={400}
          activeProps={{ fontWeight: 500, cursor: "default" }}
          active={active}
          className="onHoverParent"
          {...props}
        >
          {expandable ? (
            <MiniButton
              marginRight={10}
              padding={0}
              width={22}
              height={22}
              flexShrink={0}
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                setExpanded((expanded) => !expanded);
              }}
            >
              <Icon
                type="fas"
                fixedWidth
                name={expanded ? "fa-caret-down" : "fa-caret-right"}
                fontSize={12}
                opacity={0.6}
              />
            </MiniButton>
          ) : (
            <Pane width={32} flexShrink={0} />
          )}
          {children}
        </StyledSidebarEntityLinkRow>
      </div>
    </Tooltip>
  );
};

const StyledSidebarEntityLinkRow = styled(Link)`
  background-color: white;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #f4f7fa;
  }
`;

export default SidebarEntityLinkRow;
