import React, { MouseEvent } from "react";
import { useFetcher } from "rest-hooks";

import SearchResource from "resources/search";
import { NormalizedColumnRelationship } from "resources/columns";

import { useTrackWithEntity } from "utils/analytics";

import { UIProps } from "components/base/types";
import { MiniIconButton, Select } from "components/base";
import { Row } from "components/base/layout";
import ColumnMenuItemContents from "./ColumnMenuItemContents";

const EditEntityRelationshipsButton = ({
  entityId,
  addRelationship,
  selectedRelationships,
  removeRelationship,
  iconOnly,
  ...props
}: {
  entityId: string;
  selectedRelationships: NormalizedColumnRelationship[];
  addRelationship: (childId: string, relationshipType: string) => void;
  removeRelationship: (relationshipId: string) => void;
  iconOnly?: boolean;
} & UIProps) => {
  const trackWithEntity = useTrackWithEntity();
  const search = useFetcher(SearchResource.list());
  const [searchedItems, setSearchedItems] = React.useState<SearchResource[]>(
    []
  );

  const menuItems = [
    ...selectedRelationships.map((relation) => (
      <Select.MenuItem key={relation.id} value={relation.id}>
        <ColumnMenuItemContents
          columnName={relation.column.name || ""}
          tableName={relation.column.table.name || ""}
          schemaName={relation.column.table.schema.name || ""}
          databaseName={relation.column.table.schema.database.name || ""}
        />
      </Select.MenuItem>
    )),
    ...searchedItems
      .filter(
        (searchResult) =>
          !selectedRelationships.some(
            (relation) => relation.column.id === searchResult.id
          )
      )
      .map((searchResult) => (
        <Select.MenuItem
          key={searchResult.entityId}
          value={searchResult.entityId}
        >
          <ColumnMenuItemContents
            columnName={searchResult.attributes.columnName || ""}
            tableName={searchResult.attributes.tableName || ""}
            schemaName={searchResult.attributes.schemaName || ""}
            databaseName={searchResult.attributes.databaseName || ""}
          />
        </Select.MenuItem>
      )),
  ];

  const onSearch = async (input: string) => {
    const { results } = await search({
      query: input,
      entityType: "column",
    });

    setSearchedItems(
      results.filter(
        (searchResult: SearchResource) =>
          searchResult.type === "column" && searchResult.entityId !== entityId
      )
    );
  };

  const selectedRelationshipIDs = selectedRelationships.map((rel) => rel.id);

  return (
    <Row
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        trackWithEntity("Clicked add relationship button");
      }}
      {...props}
    >
      <Select
        value={selectedRelationshipIDs}
        onChange={(selectedValues: any, selectedId: any) => {
          if (selectedRelationshipIDs.includes(selectedId)) {
            removeRelationship(selectedId);
          } else {
            addRelationship(selectedId, "one-to-many");
          }
        }}
        menuItems={menuItems}
        searchPlaceholder="Search columns..."
        onSearch={onSearch}
        minWidth={360}
      >
        <MiniIconButton iconType="far" iconName="fa-link" title="Add relation">
          {!iconOnly && "Relation"}
        </MiniIconButton>
      </Select>
    </Row>
  );
};

export default EditEntityRelationshipsButton;
