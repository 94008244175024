import React, { useState, useContext } from "react";
import { useFetcher } from "rest-hooks";
import classNames from "classnames";
import styled from "styled-components";
import { toaster } from "evergreen-ui";

import SourcesResource from "resources/sources";

import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";

import ConnectionCardContext from "../ConnectionCardContext";

const TestButton = (props: UIProps) => {
  const { connection } = useContext(ConnectionCardContext);
  const testSource = useFetcher(SourcesResource.test());

  const [isTesting, setIsTesting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const onButtonClick = async () => {
    if (!isTesting) {
      setIsTesting(true);
      try {
        await testSource({ id: connection.id });
        setIsComplete(true);
        setIsTesting(false);
      } catch (e) {
        toaster.danger("Attempt to test failed -- please try again.");
        console.error(e);
        setIsTesting(false);
      }
    }
  };

  let buttonText = "Test";
  if (isComplete) {
    buttonText = "Success";
  } else if (isTesting) {
    buttonText = "Testing...";
  }

  return (
    <StyledTestButton
      fontSize={12}
      borderRadius={40}
      paddingY={1}
      border="1px solid currentcolor"
      paddingX={8}
      fontWeight={500}
      textTransform="uppercase"
      letterSpacing={0.3}
      whiteSpace="nowrap"
      {...props}
      onClick={onButtonClick}
      className={classNames(isTesting && "testing", isComplete && "complete")}
    >
      {buttonText}{" "}
    </StyledTestButton>
  );
};

const StyledTestButton = styled(Pane)`
  background-color: white;
  color: hsl(212, 25%, 60%);
  transition: color 0.2s ease, background-color 0.2s ease;
  &:not(.testing) {
    cursor: pointer;
  }
  &:hover {
    color: hsl(212, 25%, 40%);
  }

  &.testing,
  &.testing:hover {
    color: hsl(212, 20%, 70%);
  }

  &.complete,
  &.complete:hover {
    background-color: #3dbf29;
    color: white;
    border-color: #3dbf29;
  }
`;

export default TestButton;
