import { createContext } from "react";
import SourcesResource from "resources/sources";

export const ConnectionCardContext = createContext<{
  connection: SourcesResource;
}>({
  connection: new SourcesResource(),
});

export default ConnectionCardContext;
