import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/es/form";

import { Icon, Input, Button } from "components/base";
import { GITHUB_INSTALLATION_ID_STORAGE_KEY } from "constants/storageKeys";

const GITHUB_CONNECT_URL =
  "https://github.com/apps/metaplane-connector/installations/new";

const useWaitForInstallationId = (shouldBeginPolling: boolean = false) => {
  const [timerCounter, setTimerCounter] = useState(0);
  const [installationId, setInstallationId] = useState("");

  useEffect(() => {
    if (shouldBeginPolling) {
      const id = window.localStorage.getItem(
        GITHUB_INSTALLATION_ID_STORAGE_KEY
      );
      if (!id) {
        window.setTimeout(() => {
          setTimerCounter((count) => count + 1);
        }, 2000);
      } else {
        setInstallationId(id);
      }
    }
  }, [shouldBeginPolling, timerCounter]);

  if (!shouldBeginPolling) {
    return undefined;
  }

  return installationId;
};

const DbtConnectionFormItems = ({ form }: { form: FormInstance }) => {
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);
  const installationId = useWaitForInstallationId(!!connectionWindow);
  const { setFieldsValue } = form;

  useEffect(() => {
    if (installationId && connectionWindow) {
      window.localStorage.removeItem(GITHUB_INSTALLATION_ID_STORAGE_KEY);
      connectionWindow.close();
      setFieldsValue({ installationId });
    }
  }, [connectionWindow, installationId, setFieldsValue]);

  return (
    <>
      <Form.Item
        label="Nickname"
        name="name"
        rules={[{ required: true, message: "Nickname required" }]}
      >
        <Input placeholder={`Dbt for X warehouse`} />
      </Form.Item>
      <Form.Item
        label="Github ID"
        name="installationId"
        rules={[{ required: true, message: "Github connection required" }]}
      >
        <Input type="hidden" />

        <Button
          buttonColor="hsla(212, 94%, 36%, 1)"
          activeColor="hsla(212, 94%, 44%, 1)"
          onClick={() =>
            setConnectionWindow(
              window.open(GITHUB_CONNECT_URL, "_blank", "height=800,width=525")
            )
          }
          disabled={!!installationId}
        >
          <Icon
            type="fa"
            name={!installationId ? "fa-github" : "fa-check"}
            marginRight={8}
            fontSize={15}
          />
          Integrate{!installationId ? "" : "d"} with Github
        </Button>
      </Form.Item>
    </>
  );
};

export default DbtConnectionFormItems;
