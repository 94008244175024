import React, { useState } from "react";
import { useFetcher } from "rest-hooks";

import EditorJsType from "quantifai-editorjs";

import { useTrackWithEntity } from "utils/analytics";
import DocumentationRequest from "resources/documentation-request";

import { UIProps } from "components/base/types";
import { MiniIconButton, Button } from "components/base";
import { Row } from "components/base/layout";
import { Popover } from "antd";
import Editor from "components/Editor";
import { toaster } from "evergreen-ui";

const UpdateRequestEntityDocumentationButton = ({
  documentationRequest,
  iconOnly,
  autoOpen,
  ...props
}: {
  documentationRequest: DocumentationRequest;
  autoOpen?: boolean;
  iconOnly?: boolean;
} & UIProps) => {
  const [editorRef, setEditorRef] = useState<EditorJsType>();
  const [isPopoverVisible, setIsPopoverVisible] = useState(autoOpen || false);
  const trackWithEntity = useTrackWithEntity();
  const updateDocumentationRequest = useFetcher(
    DocumentationRequest.partialUpdate()
  );

  const onClickUpdate = async () => {
    if (!editorRef) {
      return;
    }
    const {
      blocks: commentBlocks,
      html: commentHtml,
      text: commentText,
    } = await editorRef.save();

    await updateDocumentationRequest(
      { id: documentationRequest.id },
      {
        comment: {
          commentBlocks,
          commentHtml,
          commentText,
        },
      }
    );
    setIsPopoverVisible(false);
    toaster.success("Request updated.");
    trackWithEntity("Updated documentation request for entity with comment");
  };

  return (
    <Popover
      placement="bottomRight"
      content={
        <Row alignItems="flex-start">
          <Editor
            setEditorRef={setEditorRef}
            hideToolbar
            hideToolbox
            autofocus
            placeholder="Add a comment to your request"
            data={{ blocks: documentationRequest.comment.commentBlocks }}
            id="documentation-request-comment"
            minWidth={300}
            fontSize={14}
          />
          <Button marginLeft={12} type="primary" onClick={onClickUpdate}>
            Update
          </Button>
        </Row>
      }
      title="You requested documentation"
      visible={isPopoverVisible}
    >
      <MiniIconButton
        iconType="far"
        iconName="fa-pencil"
        title="Edit your documentation request"
        tooltipProps={{ placement: "topRight" }}
        onClick={() => setIsPopoverVisible(!isPopoverVisible)}
        {...props}
      >
        {iconOnly ? null : "Edit request"}
      </MiniIconButton>
    </Popover>
  );
};

export default UpdateRequestEntityDocumentationButton;
