import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";

export default class UsersResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly firstName: string = "";
  readonly lastName: string = "";
  readonly email: string = "";

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("users");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this] },
    });
  }

  get fullName() {
    if (this.firstName && this.lastName) {
      return this.firstName + " " + this.lastName;
    } else if (this.lastName) {
      return this.lastName;
    }
    return this.firstName;
  }
}

export const usersListUpdater = (
  response: { user: any },
  userIds: { results: string[] } | undefined
) => ({
  ...userIds,
  results: [...(userIds?.results ? userIds.results : []), response.user],
});
