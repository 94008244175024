import React from "react";
import { useParams } from "react-router-dom";
import { useFetcher } from "rest-hooks";

import TablesResource from "resources/tables";

import { Icon } from "components/base";
import { Row, Span } from "components/base/layout";
import { Code } from "components/base/type";
import EntityContext from "contexts/EntityContext";

import SidebarExpandableSection from "./SidebarExpandableSection";

import { ProtectedEntityImportanceToggle } from "components/protected";

const SidebarTableRow = ({
  table,
  defaultOpen,
}: {
  table: TablesResource;
  defaultOpen?: boolean;
}) => {
  const { databaseName, schemaName, tableName } = useParams<{
    databaseName?: string;
    schemaName?: string;
    tableName?: string;
  }>();

  const updateTable = useFetcher(TablesResource.partialUpdate());

  return (
    <EntityContext.Provider value={{ entity: table }}>
      <SidebarExpandableSection
        key={table.id}
        active={table.hasEqualSlug({ tableName, schemaName, databaseName })}
      >
        <SidebarExpandableSection.LinkRow
          paddingLeft={42}
          to={table.catalogUrlPath}
          title={table.description.descriptionText}
        >
          <TablesResource.Icon
            marginRight={8}
            type={table.type}
            flexShrink={0}
          />
          <Code enforceCasing truncate paddingRight={20}>
            {table.name}
          </Code>
          <Row centerY marginLeft="auto" fontSize={12}>
            {table.isImportant && (
              <Icon
                className="hideOnParentHover"
                marginRight={6}
                fontSize={6}
                type="fas"
                name="fa-circle"
                color={"hsl(51 100% 48% / 1)"}
                title="Marked as important"
              />
            )}
            <Span className="hideOnParentHover">
              {table.statistics.col_count || 0}
            </Span>
            <ProtectedEntityImportanceToggle
              className="visibleOnParentHover defaultCollapsed"
              isImportant={table.isImportant}
              fontSize={12}
              noExpansion
              toggleEntityIsImportant={async (isImportant: boolean) => {
                await updateTable({ id: table.id }, { isImportant });
              }}
            />
          </Row>
        </SidebarExpandableSection.LinkRow>
      </SidebarExpandableSection>
    </EntityContext.Provider>
  );
};

export default SidebarTableRow;
