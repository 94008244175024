import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import LoginPage from "pages/LoginPage";
import GlobalThemeProvider from "../GlobalThemeProvider";

const UnauthenticatedApp = () => {
  return (
    <GlobalThemeProvider>
      <Router>
        <Switch>
          <Route path="/">
            <LoginPage />
          </Route>
        </Switch>
      </Router>
    </GlobalThemeProvider>
  );
};

export default UnauthenticatedApp;
