import { createContext } from "react";

export const SelectContext = createContext<{
  isMultiSelect: boolean;
  selectedValue: any;
  toggleSelectedValue: (valueToToggle: any) => void;
}>({
  isMultiSelect: false,
  selectedValue: null,
  toggleSelectedValue: (valueToToggle: any) => {},
});

export default SelectContext;
