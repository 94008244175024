import React from "react";
import { useFetcher } from "rest-hooks";
import { useTrackWithEntity } from "utils/analytics";

import { MiniIconButton } from "components/base";
import { UIProps } from "components/base/types";
import EntitiesResource from "resources/entities";

export type EntityImportanceToggleProps = {
  isImportant: boolean;
  toggleEntityIsImportant: (isImportant: boolean) => void;
} & UIProps;

export const EntityImportanceToggle = ({
  isImportant,
  toggleEntityIsImportant,
  ...props
}: EntityImportanceToggleProps) => {
  const trackWithEntity = useTrackWithEntity();
  const refreshImportantEntities = useFetcher(EntitiesResource.listImportant());

  return (
    <MiniIconButton
      onClick={async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await toggleEntityIsImportant(!isImportant);
        refreshImportantEntities({});

        trackWithEntity("Toggled entity importance", {
          isImportant,
        });
      }}
      iconType={isImportant ? "fas" : "far"}
      iconProps={{
        style: {
          "-webkit-text-stroke": isImportant
            ? "1px hsl(51 100% 30% / 1)"
            : undefined,
        },
        color: isImportant
          ? "hsl(51 100% 68% / 1)"
          : "hsla(212, 25%, 12%, 0.6)",
      }}
      iconName="fa-star"
      title={isImportant ? "Unmark as important" : "Mark as important"}
      tooltipProps={{ placement: "topRight" }}
      className={isImportant ? "alwaysVisible" : ""}
      {...props}
    />
  );
};
