import React from "react";

import { useAuth } from "authentication";
import { TagEntityType } from "resources/tag-entity-type";
import { RowProps } from "components/base/layout/Row";
import EntityTags from "components/EntityTags";

export const ProtectedEntityTagsSelect = ({
  tagIds,
  tagEntityType,
  addTagToEntity,
  removeTagFromEntity,
  createAndAddTagToEntity,
  iconOnly,
  ...props
}: {
  tagIds: string[];
  tagEntityType: TagEntityType;
  addTagToEntity: (tagId: string) => void;
  removeTagFromEntity: (tagId: string) => void;
  createAndAddTagToEntity: (newTagName: string) => void;
  iconOnly?: boolean;
} & RowProps) => {
  const { user } = useAuth();
  return (
    <EntityTags tagIds={tagIds} {...props}>
      {user.canWriteTags && (
        <EntityTags.AddTagButton
          className="visibleOnParentHover"
          marginRight={tagIds.length ? 6 : 0}
          tagEntityType={tagEntityType}
          addTagToEntity={addTagToEntity}
          createAndAddTagToEntity={createAndAddTagToEntity}
          removeTagFromEntity={removeTagFromEntity}
          iconOnly={iconOnly}
        />
      )}
    </EntityTags>
  );
};
