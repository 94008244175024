import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CacheProvider, NetworkErrorBoundary } from "rest-hooks";
import { Spinner } from "evergreen-ui";

import GlobalThemeProvider from "apps/GlobalThemeProvider";
import NetworkErrorFallbackPage from "pages/NetworkErrorFallbackPage";

import { Row, Column } from "components/base/layout";
import Header from "./Header";
import Routes from "./Routes";
import GithubCallbackPage from "pages/GithubCallbackPage";
import SlackCallbackPage from "pages/SlackCallbackPage";

const AuthenticatedApp = () => (
  <GlobalThemeProvider>
    <CacheProvider>
      <Router>
        <Switch>
          <Route path="/github/auth">
            <GithubCallbackPage />
          </Route>
          <Route path="/slack/auth">
            <SlackCallbackPage />
          </Route>
          <Route>
            <Column minHeight="100vh">
              <Header />
              <Suspense
                fallback={
                  <Row height="100%" minHeight={300} width="100%" center>
                    <Spinner />
                  </Row>
                }
              >
                <NetworkErrorBoundary
                  fallbackComponent={NetworkErrorFallbackPage}
                >
                  <Routes />
                </NetworkErrorBoundary>
              </Suspense>
            </Column>
          </Route>
        </Switch>
      </Router>
    </CacheProvider>
  </GlobalThemeProvider>
);

export default AuthenticatedApp;
