import { useState, useEffect, useCallback, RefObject } from "react";

export const useKeyPress = (
  targetKey: string,
  isMeta?: boolean,
  target?: RefObject<any>
) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    (e: KeyboardEvent) => {
      const { key, metaKey, ctrlKey } = e;
      if (key === targetKey) {
        if (!isMeta || metaKey || ctrlKey) {
          e.preventDefault();
          e.stopPropagation();
          setKeyPressed(true);
        }
      }
    },
    [targetKey, isMeta]
  );

  const upHandler = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;
      if (isMeta || key === targetKey) {
        e.preventDefault();
        e.stopPropagation();
        setKeyPressed(false);
      }
    },
    [targetKey, isMeta]
  );

  useEffect(() => {
    const listenerTarget = target ? target.current : window;

    if (listenerTarget) {
      listenerTarget.addEventListener("keydown", downHandler);
      listenerTarget.addEventListener("keyup", upHandler);
    }

    return () => {
      if (listenerTarget) {
        listenerTarget.removeEventListener("keydown", downHandler);
        listenerTarget.removeEventListener("keyup", upHandler);
      }
    };
  }, [target, downHandler, upHandler]);

  return keyPressed;
};

export default useKeyPress;
