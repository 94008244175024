export const SLACK_INTEGRATION_ID_STORAGE_KEY = "_mp_slack_integration_id";
export const GITHUB_INSTALLATION_ID_STORAGE_KEY = "_mp_github_installation_id";
export const SIDEBAR_WIDTH_STORAGE_KEY = "_mp_sidebar_width";
export const GRAPH_SIDEBAR_WIDTH_STORAGE_KEY = "_mp_graph_sidebar_width";

export const NETWORK_GRAPH_HIDDEN_SCHEMA_IDS_STORAGE_KEY =
  "_mp_relationship_graph_hidden_schema_ids";
export const NETWORK_GRAPH_HIDDEN_TABLE_IDS_STORAGE_KEY =
  "_mp_relationship_graph_hidden_table_ids";

export const FIRST_SESSION_DATE_STORAGE_KEY = "_mp_first_session_date";
