import React from "react";
import { Pane } from "./Pane";
import { UIProps } from "components/base/types";

export type RowProps = {
  centerX?: boolean;
  centerY?: boolean;
  center?: boolean;
  grow?: boolean;
  wrap?: boolean;
} & UIProps;

export const Row = React.forwardRef<HTMLDivElement, RowProps>(
  ({ centerX, centerY, center, grow, wrap, ...props }, ref) => {
    const options: any = {};
    const alignItems: string | undefined =
      centerY || center ? "center" : undefined;
    const justifyContent: string | undefined =
      centerX || center ? "center" : undefined;

    const flexGrow: number | undefined = grow ? 1 : undefined;
    const flexWrap: "wrap" | undefined = wrap ? "wrap" : undefined;
    if (flexWrap) {
      options.flexWrap = flexWrap;
    }

    return (
      <Pane
        ref={ref}
        display="flex"
        alignItems={alignItems}
        justifyContent={justifyContent}
        flexGrow={flexGrow}
        {...options}
        {...props}
      />
    );
  }
);
