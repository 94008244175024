import React from "react";
import { useParams } from "react-router-dom";
import { useFetcher } from "rest-hooks";

import SchemasResource from "resources/schemas";

import { Icon } from "components/base";
import { Row, Span } from "components/base/layout";
import { Code } from "components/base/type";
import EntityContext from "contexts/EntityContext";

import SidebarExpandableSection from "./SidebarExpandableSection";
import SidebarSchemaTablesList from "./SidebarSchemaTablesList";

import { ProtectedEntityImportanceToggle } from "components/protected";

const SidebarSchemaRow = ({
  schema,
  defaultOpen,
}: {
  schema: SchemasResource;
  defaultOpen?: boolean;
}) => {
  const { databaseName, schemaName } = useParams<{
    databaseName?: string;
    schemaName?: string;
  }>();

  const updateSchema = useFetcher(SchemasResource.partialUpdate());

  return (
    <EntityContext.Provider value={{ entity: schema }}>
      <SidebarExpandableSection
        key={schema.id}
        active={schema.hasEqualSlug({ schemaName, databaseName })}
        defaultOpen={!!defaultOpen}
      >
        <SidebarExpandableSection.LinkRow
          paddingLeft={28}
          to={schema.catalogUrlPath}
          title={schema.description.descriptionText}
          expandable
        >
          <SchemasResource.Icon marginRight={8} flexShrink={0} />
          <Code enforceCasing>{schema.name}</Code>

          <Row marginLeft="auto" centerY fontSize={12}>
            {schema.isImportant && (
              <Icon
                className="hideOnParentHover"
                marginRight={6}
                fontSize={6}
                type="fas"
                name="fa-circle"
                color={"hsl(51 100% 48% / 1)"}
                title="Marked as important"
              />
            )}
            <Span className="hideOnParentHover">
              {schema.statistics.table_count || 0}
            </Span>
            <ProtectedEntityImportanceToggle
              className="visibleOnParentHover defaultCollapsed"
              isImportant={schema.isImportant}
              fontSize={12}
              noExpansion
              toggleEntityIsImportant={async (isImportant: boolean) => {
                await updateSchema({ id: schema.id }, { isImportant });
              }}
            />
          </Row>
        </SidebarExpandableSection.LinkRow>
        <SidebarExpandableSection.ExpandableList>
          <SidebarSchemaTablesList schema={schema} />
        </SidebarExpandableSection.ExpandableList>
      </SidebarExpandableSection>
    </EntityContext.Provider>
  );
};

export default SidebarSchemaRow;
