import React from "react";
import { useQuery } from "utils/hooks";
import { GITHUB_INSTALLATION_ID_STORAGE_KEY } from "constants/storageKeys";

const GithubCallbackPage = () => {
  const query = useQuery();
  const installationId = query.get("installation_id");
  window.localStorage.setItem(
    GITHUB_INSTALLATION_ID_STORAGE_KEY,
    installationId || ""
  );
  return <></>;
};

export default GithubCallbackPage;
