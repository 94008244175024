import React, { useState } from "react";
import { OutputData } from "quantifai-editorjs";

import { Tooltip } from "components/base";
import { UIProps } from "components/base/types";
import { Pane, Span } from "components/base/layout";
import Editor from "components/Editor";

import { useEntityIdentifiers } from "contexts/EntityContext";
import Description from "types/Description";
import { useUser } from "utils/hooks";
import { getHumanReadableDatetime } from "utils";

export type EntityDescriptionProps = {
  description?: Description;
  onChange: (description: Description) => void;
  noDefer?: boolean;
  readonly?: boolean;
} & UIProps;

const EntityDescription = ({
  description,
  onChange,
  noDefer,
  readonly,
  ...props
}: EntityDescriptionProps) => {
  const [isReady, setIsReady] = useState(false);
  const [hasHovered, setHasHovered] = useState(false);
  const { entityId, entityType } = useEntityIdentifiers();

  const placeholder = `Add a ${entityType} description...`;

  const showEditor = !readonly && (noDefer || hasHovered);
  const showDescriptionHTML = !isReady && !!description?.descriptionText;
  const showPlaceholder =
    !isReady && !description?.descriptionText && !readonly;

  const lastEditedUser = useUser(description?.updatedByUserId);

  const tooltipTitle = lastEditedUser && (
    <Span fontSize={12}>
      Last edited by <Span fontWeight={600}>{lastEditedUser.fullName}</Span> @{" "}
      {getHumanReadableDatetime(description?.updatedAt)}
    </Span>
  );

  return (
    <Tooltip title={tooltipTitle} placement="bottomLeft">
      <Pane
        fontSize={14}
        onMouseEnter={() => {
          if (!noDefer && !hasHovered) {
            setHasHovered(true);
          }
        }}
      >
        {showEditor && (
          <Editor
            hideToolbar
            hideToolbox
            key={entityId}
            placeholder={placeholder}
            id={`entity-${entityId}-description`}
            onChange={(outputData?: OutputData) => {
              if (outputData) {
                onChange({
                  descriptionHtml: outputData.html || "",
                  descriptionBlocks: outputData.blocks || [],
                  descriptionText: outputData.text || "",
                });
              }
            }}
            data={{ blocks: description?.descriptionBlocks || [] }}
            minWidth={300}
            fontSize={14}
            display={isReady ? "block" : "none"}
            onReady={() => setIsReady(true)}
            {...props}
          />
        )}
        {showDescriptionHTML && (
          <Pane {...props}>
            <div
              dangerouslySetInnerHTML={{
                __html: description?.descriptionHtml || "",
              }}
            />
          </Pane>
        )}
        {showPlaceholder && (
          <Pane {...props}>
            <Pane paddingY="0.4em" color="#707684" whiteSpace="nowrap">
              {placeholder}
            </Pane>
          </Pane>
        )}
      </Pane>
    </Tooltip>
  );
};

export default EntityDescription;
