import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import EntityType from "./entity-type";
import DatabasesResource from "./databases";
import SchemasResource from "./schemas";
import TablesResource from "./tables";
import ColumnsResource from "./columns";

export default class SearchResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly entityId: string = "";
  readonly type: EntityType | undefined = undefined;
  readonly text = "";

  readonly attributes: {
    databaseName?: string;
    schemaName?: string;
    tableName?: string;
    columnName?: string;
    tags: string[];
    ownerNames: string[];
    sourceUpdatedAt?: Date;
  } = {
    databaseName: undefined,
    schemaName: undefined,
    tableName: undefined,
    tags: [],
    ownerNames: [],
    sourceUpdatedAt: undefined,
  };

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("search");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  getSlug() {
    switch (this.type) {
      case EntityType.Database:
        return DatabasesResource.buildSlug({
          databaseName: this.attributes.databaseName || "",
        });
      case EntityType.Schema:
        return SchemasResource.buildSlug({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
        });
      case EntityType.Table:
        return TablesResource.buildSlug({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
        });
      case EntityType.Column:
        return ColumnsResource.buildSlug({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
          columnName: this.attributes.columnName || "",
        });
      default:
        return "";
    }
  }

  getCatalogUrlPath() {
    switch (this.type) {
      case EntityType.Database:
        return DatabasesResource.buildCatalogUrlPath({
          databaseName: this.attributes.databaseName || "",
        });
      case EntityType.Schema:
        return SchemasResource.buildCatalogUrlPath({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
        });
      case EntityType.Table:
        return TablesResource.buildCatalogUrlPath({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
        });
      case EntityType.Column:
        return ColumnsResource.buildCatalogUrlPath({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
          columnName: this.attributes.columnName || "",
        });
      default:
        return "";
    }
  }

  getGraphUrlPath() {
    switch (this.type) {
      case EntityType.Database:
      case EntityType.Schema:
        return this.getCatalogUrlPath();
      case EntityType.Table:
        return TablesResource.buildGraphUrlPath({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
        });
      case EntityType.Column:
        return ColumnsResource.buildGraphUrlPath({
          databaseName: this.attributes.databaseName || "",
          schemaName: this.attributes.schemaName || "",
          tableName: this.attributes.tableName || "",
          columnName: this.attributes.columnName || "",
        });
      default:
        return "";
    }
  }

  getName() {
    switch (this.type) {
      case EntityType.Database:
        return this.attributes.databaseName;
      case EntityType.Schema:
        return this.attributes.schemaName;
      case EntityType.Table:
        return this.attributes.tableName;
      case EntityType.Column:
        return this.attributes.columnName;
      default:
        return "";
    }
  }
}
