import React from "react";
import { Typography } from "./Typography";
import { TypographyProps } from "components/base/types";
import { shouldTransformCasing } from "utils";

export const Code = ({
  enforceCasing,
  ...props
}: { enforceCasing?: boolean } & TypographyProps) => {
  const options: Pick<TypographyProps, "textTransform"> = {};

  if (
    enforceCasing &&
    typeof props.children === "string" &&
    shouldTransformCasing(props.children)
  ) {
    options.textTransform = "lowercase";
  }

  return <Typography is="code" color="#171E26" {...options} {...props} />;
};
