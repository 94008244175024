import React from "react";
import EntityDescription, {
  EntityDescriptionProps,
} from "components/EntityDescription";
import { useAuth } from "authentication";

export const ProtectedEntityDescription = (props: EntityDescriptionProps) => {
  const { user } = useAuth();
  return <EntityDescription readonly={!user.canWriteDescriptions} {...props} />;
};
