import React, { useRef, useEffect } from "react";
import EditorJs from "quantifai-react-editor-js";
import EditorJsType, {
  EditorConfig,
  OutputData,
  API,
} from "quantifai-editorjs";
import InlineCode from "quantifai-inline-code";

import { UIProps } from "components/base/types";
import { Pane } from "components/base/layout";
import { useTrackEvent } from "utils/analytics";

type UsefulEditorJsProps = Pick<
  EditorConfig,
  "placeholder" | "autofocus" | "data" | "onEnterPressed"
> & {
  hideToolbar?: boolean;
  hideToolbox?: boolean;
  instanceRef?: (instance: EditorJsType) => void;
};

type EditorProps = Omit<UsefulEditorJsProps, "instanceRef"> & {
  id: string;
  onChange?: (data?: OutputData) => void;
  setEditorRef?: (instance: EditorJsType) => void;
} & UIProps;

enum LogLevels {
  ERROR = "ERROR",
}

const Editor = ({
  id,
  onChange,
  hideToolbar,
  hideToolbox,
  placeholder,
  data,
  autofocus,
  setEditorRef,
  onEnterPressed,
  onReady,
  ...props
}: EditorProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const [hasEnterBeenPressed, setHasEnterBeenPressed] = React.useState<boolean>(
    false
  );
  const trackEvent = useTrackEvent();

  const options: UsefulEditorJsProps = {};

  if (hideToolbar) {
    options.hideToolbar = hideToolbar;
  }
  if (hideToolbox) {
    options.hideToolbox = hideToolbox;
  }
  if (autofocus) {
    options.autofocus = autofocus;
  }
  if (placeholder) {
    options.placeholder = placeholder;
  }
  if (data) {
    options.data = data;
  }
  if (setEditorRef) {
    options.instanceRef = (instance: EditorJsType) => setEditorRef(instance);
  }
  if (onEnterPressed) {
    options.onEnterPressed = () => {
      setHasEnterBeenPressed(true);
    };
  }

  useEffect(() => {
    if (hasEnterBeenPressed && onEnterPressed) {
      onEnterPressed();
      setHasEnterBeenPressed(false);
    }
  }, [hasEnterBeenPressed, onEnterPressed]);

  const onChangeWithTracking = (newData?: OutputData) => {
    if (onChange) {
      trackEvent("Editor: content changed", { itemId: id });
      onChange(newData);
    }
  };

  return (
    <Pane position="relative" className="editorjs-container">
      <EditorJs
        {...options}
        holder={id}
        logLevel={LogLevels.ERROR}
        onChange={(api: API, newData?: OutputData) =>
          onChangeWithTracking(newData)
        }
        onReady={onReady}
        tools={{
          inlineCode: {
            class: InlineCode,
            shortcut: "`",
          },
        }}
      >
        <Pane id={id} ref={containerRef} className="no-loader" {...props} />
      </EditorJs>
    </Pane>
  );
};

export default Editor;
