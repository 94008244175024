import React from "react";
import classNames from "classnames";
import { useFetcher } from "rest-hooks";

import { Tooltip } from "antd";
import { EntityRow, Icon, Link, MiniButton, Stat } from "components/base";
import { H2, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import {
  ProtectedEntityDescription,
  ProtectedEntityImportanceToggle,
} from "components/protected";
import EntityVisibilityToggle from "components/EntityVisibilityToggle";
import RequestEntityDocumentationController from "components/RequestEntityDocumentationController";
import EntityContext from "contexts/EntityContext";

import { useAuth } from "authentication";
import { getTimeFromNow, getSortedEntitiesByName } from "utils";

import SchemasResource from "resources/schemas";

import CustomField from "types/CustomField";

const SchemaRow = ({ schema }: { schema: SchemasResource }) => {
  const { user } = useAuth();
  const sortedCustomFields = getSortedEntitiesByName<CustomField>(
    schema.customFields
  );
  const updateSchema = useFetcher(SchemasResource.partialUpdate());
  const requestDocumentation = useFetcher(
    SchemasResource.requestDocumentation()
  );
  const resolveDocumentation = useFetcher(
    SchemasResource.resolveDocumentation()
  );

  return (
    <EntityContext.Provider value={{ entity: schema }}>
      <Pane className="onHoverParent" borderBottom="1px solid #F2F2F2">
        <EntityRow>
          <Pane whiteSpace="nowrap" minWidth={240}>
            <Row opacity={schema.hidden ? 0.5 : 1} alignItems="flex-end">
              <H2
                fontWeight={500}
                fontSize={14}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                <SchemasResource.Icon fontSize={12} marginRight={5} />
                <Tooltip color="black" title={`Go to schema page`}>
                  <Link highlight to={schema.catalogUrlPath}>
                    <MiniButton
                      fontSize="inherit"
                      fontWeight="inherit"
                      color="inherit"
                    >
                      <Code enforceCasing>{schema.name}</Code>
                      <Icon
                        className="visibleOnParentHover"
                        name="fa-arrow-right"
                        marginLeft={7}
                        transform="rotate(-45deg)"
                        fontSize={10}
                        fontWeight={600}
                        opacity={0.8}
                      />
                    </MiniButton>
                  </Link>
                </Tooltip>
              </H2>
            </Row>
            <Pane paddingLeft={26} fontSize={12} opacity={0.5} marginTop={2}>
              <Span>
                Updated {getTimeFromNow(schema.sourceUpdatedAt)} &bull;{" "}
                {schema.statistics.table_count || 0} Tables
              </Span>
            </Pane>
          </Pane>

          <Row
            paddingLeft={34}
            paddingRight={20}
            fontSize={14}
            center
            className={classNames(
              !schema.description?.descriptionText && "visibleOnParentHover"
            )}
          >
            <ProtectedEntityDescription
              description={schema.description}
              onChange={(description) => {
                updateSchema({ id: schema.id }, { description });
              }}
            />
          </Row>

          <Row marginLeft="auto" centerY>
            {sortedCustomFields.map((customField) => (
              <Stat name={customField.name}>{customField.value}</Stat>
            ))}

            <RequestEntityDocumentationController
              marginLeft={8}
              iconOnly
              documentationRequests={schema.documentationRequests}
              requestDocumentation={() => {
                requestDocumentation({ id: schema.id }, {});
              }}
              resolveDocumentation={() => {
                resolveDocumentation({ id: schema.id }, {});
              }}
            />

            <ProtectedEntityImportanceToggle
              marginLeft={8}
              isImportant={schema.isImportant}
              toggleEntityIsImportant={(isImportant: boolean) =>
                updateSchema({ id: schema.id }, { isImportant })
              }
            />

            {user.canWriteEntityVisibility && (
              <EntityVisibilityToggle
                marginLeft={8}
                isHidden={schema.hidden}
                onToggle={(hidden) =>
                  updateSchema({ id: schema.id }, { hidden })
                }
              />
            )}
          </Row>
        </EntityRow>
      </Pane>
    </EntityContext.Provider>
  );
};

export default SchemaRow;
