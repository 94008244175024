import React from "react";

import DatabasesResource from "resources/databases";

import { Code } from "components/base/type";
import EntityContext from "contexts/EntityContext";

import SidebarExpandableSection from "./SidebarExpandableSection";
import SidebarDatabaseSchemasList from "./SidebarDatabaseSchemasList";

const SidebarDatabaseRow = ({
  database,
  active,
  defaultOpen,
}: {
  database: DatabasesResource;
  active?: boolean;
  defaultOpen?: boolean;
}) => (
  <EntityContext.Provider value={{ entity: database }}>
    <SidebarExpandableSection
      active={(defaultOpen && active) || false}
      defaultOpen={defaultOpen}
    >
      <SidebarExpandableSection.LinkRow
        to={database.catalogUrlPath}
        title={database.description.descriptionText}
        expandable
      >
        <DatabasesResource.Icon marginRight={8} flexShrink={0} />
        <Code enforceCasing>{database.name}</Code>
      </SidebarExpandableSection.LinkRow>
      <SidebarExpandableSection.ExpandableList>
        <SidebarDatabaseSchemasList database={database} />
      </SidebarExpandableSection.ExpandableList>
    </SidebarExpandableSection>
  </EntityContext.Provider>
);

export default SidebarDatabaseRow;
