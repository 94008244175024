import {
  StrictGraphData,
  SchemaCombo,
  TableRelationshipEdge,
  CentralityMeasuresType,
} from "components/SharedGraph/types";

const filterGraphData = (
  allData: StrictGraphData,
  selectedNodeId: string,
  includeSecondDegreeConnections: boolean,
  onlyCentralTables: boolean,
  centralities: CentralityMeasuresType
): StrictGraphData => {
  if (!selectedNodeId && !centralities) {
    return allData;
  }

  const {
    nodes: baseTableNodes,
    edges: baseTableRelationshipEdges,
    combos: baseSchemaCombos,
  } = allData;

  const centralNodes = onlyCentralTables
    ? baseTableNodes.filter((node) => centralities[node.id] > 2)
    : baseTableNodes;
  const centralNodeIds = centralNodes.map((node) => node.id);
  const centralRelationships = baseTableRelationshipEdges.filter(
    (relationship) =>
      centralNodeIds.includes(relationship.source) ||
      centralNodeIds.includes(relationship.source)
  );

  const processDegreeRelationships = () => {
    if (!selectedNodeId) {
      return {
        relationships: centralRelationships,
        nodeIds: centralNodes.map((node) => node.id),
      };
    }

    const nodeIdsInFirstDegreeRelationships: string[] = [];

    const firstDegreeRelationships = centralRelationships.filter(
      (relationship) =>
        relationship.source === selectedNodeId ||
        relationship.target === selectedNodeId
    );

    firstDegreeRelationships.forEach((relationship) => {
      if (!nodeIdsInFirstDegreeRelationships.includes(relationship.source)) {
        nodeIdsInFirstDegreeRelationships.push(relationship.source);
      }
      if (!nodeIdsInFirstDegreeRelationships.includes(relationship.target)) {
        nodeIdsInFirstDegreeRelationships.push(relationship.target);
      }
    });

    const processSecondDegreeRelationships = () => {
      if (!includeSecondDegreeConnections) {
        return {
          relationships: firstDegreeRelationships,
          nodeIds: nodeIdsInFirstDegreeRelationships,
        };
      }

      const secondDegreeRelationships = centralRelationships.filter(
        (relationship) =>
          nodeIdsInFirstDegreeRelationships.includes(relationship.source) ||
          nodeIdsInFirstDegreeRelationships.includes(relationship.target)
      );

      const nodeIdsInSecondDegreeRelationships: string[] = [];
      secondDegreeRelationships.forEach((relationship) => {
        if (!nodeIdsInSecondDegreeRelationships.includes(relationship.source)) {
          nodeIdsInSecondDegreeRelationships.push(relationship.source);
        }
        if (!nodeIdsInSecondDegreeRelationships.includes(relationship.target)) {
          nodeIdsInSecondDegreeRelationships.push(relationship.target);
        }
      });
      return {
        relationships: secondDegreeRelationships,
        nodeIds: nodeIdsInSecondDegreeRelationships,
      };
    };
    return processSecondDegreeRelationships();
  };

  const filterHiddenEntities = ({
    relationships,
    nodeIds,
  }: {
    relationships: TableRelationshipEdge[];
    nodeIds: string[];
  }) => ({
    relationships: relationships.filter(
      (relationship) =>
        nodeIds.includes(relationship.source) &&
        nodeIds.includes(relationship.target)
    ),
    nodeIds,
  });

  const { relationships, nodeIds } = filterHiddenEntities(
    processDegreeRelationships()
  );

  const filteredNodes = centralNodes
    .filter((node) => nodeIds.includes(node.id))
    .map((node) => {
      if (node.id === selectedNodeId) {
        return {
          ...node,
          style: {
            ...node.style,
            lineDash: [10, 5],
          },
        };
      }
      return node;
    });

  const filteredSchemaCombos = filteredNodes.reduce((schemaCombos, node) => {
    const schemaCombo = baseSchemaCombos?.find(
      (schema) => schema.id === node.schemaId
    );

    if (!schemaCombo) {
      return schemaCombos;
    }

    return [
      ...schemaCombos,
      {
        ...schemaCombo,
        id: node.schemaId,
        label: node.schemaName,
      },
    ];
  }, [] as SchemaCombo[]);

  const uniqueFilteredSchemaCombos = filteredSchemaCombos.filter(
    (schema, i, filteredSchemas) =>
      filteredSchemas.findIndex(
        (schemaIterator) => schemaIterator.id === schema.id
      ) === i
  );

  const newData = {
    nodes: filteredNodes,
    edges: relationships,
    combos: uniqueFilteredSchemaCombos,
  };

  return newData;
};

export default filterGraphData;
