import React from "react";

import { UIProps } from "components/base/types";
import Box from "ui-box";
import { Span } from "components/base/layout";

export const Stat = ({
  name,
  title,
  children,
  ...props
}: { name: string } & UIProps) => (
  <Box paddingRight={28} {...props}>
    <Box textTransform="uppercase" fontSize={11} opacity={0.5}>
      {name}
    </Box>
    <Span opacity={0.9} fontSize={14} title={title}>
      {children}
    </Span>
  </Box>
);

export default Stat;
