import React from "react";
import { useResource } from "rest-hooks";
import { indexBy, prop } from "rambda";

import UsersResource from "resources/users";

import { UIProps } from "components/base/types";
import { Row } from "components/base/layout";
import MiniIconButton from "components/base/MiniIconButton";
import PersonRow from "components/base/PersonRow";
import Select from "components/base/Select";
import { useAuth } from "authentication";
import { filterOutMetaplaneUsers } from "utils/user";

type UserId = string;
export enum PersonType {
  Owner = "Owner",
  Person = "Person",
}

const Owners = ({ users }: { users: UsersResource[] }) => (
  <Row>
    {users.map<React.ReactNode>((user) => (
      <PersonRow key={user.id} name={user.firstName || user.email} />
    ))}
  </Row>
);

export type PersonSelectProps = {
  selectUser: (selectedUserId: UserId) => void;
  deselectUser: (deselectedUserId: UserId) => void;
  selectedUserIds: UserId[];
  personType?: PersonType;
  disabled?: boolean;
} & UIProps;

export const PersonSelect = ({
  selectUser,
  deselectUser,
  selectedUserIds,
  personType,
  disabled,
  ...divProps
}: PersonSelectProps) => {
  const { user } = useAuth();
  const { results: organizationsUsers } = useResource(UsersResource.list(), {});
  const personTypeOrDefaultPersonType = personType || PersonType.Person;

  const filteredOrganizationUsers = filterOutMetaplaneUsers({
    loggedInUser: user,
    organizationsUsers,
  });

  const usersById = indexBy(prop("id"), filteredOrganizationUsers);
  const selectedUsers = selectedUserIds
    .map((userId) => usersById[userId])
    .filter((user) => !!user);

  const toggleUser = (userId: UserId) => {
    const isSelected = selectedUserIds.includes(userId);
    if (isSelected) {
      deselectUser(userId);
    } else {
      selectUser(userId);
    }
  };

  if (disabled) {
    return selectedUsers.length > 0 ? <Owners users={selectedUsers} /> : null;
  }

  return (
    <Select
      onChange={(newSelectedUserIds: UserId[], toggledUserId: UserId) => {
        toggleUser(toggledUserId);
      }}
      value={selectedUserIds || []}
      menuItems={filteredOrganizationUsers.map((user) => (
        <Select.MenuItem key={user.id} value={user.id}>
          <PersonRow
            name={user.firstName || user.email}
            marginTop={2}
            paddingRight={60}
          />
        </Select.MenuItem>
      ))}
    >
      <MiniIconButton
        iconType="far"
        iconName={!selectedUsers.length ? "fa-user" : undefined}
        title={`Select ${personTypeOrDefaultPersonType.toLowerCase()}s`}
        {...divProps}
      >
        {selectedUsers.length > 0 ? (
          <Owners users={selectedUsers} />
        ) : (
          `${personTypeOrDefaultPersonType}s`
        )}
      </MiniIconButton>
    </Select>
  );
};

PersonSelect.PersonType = PersonType;

export default PersonSelect;
