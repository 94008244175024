import React from "react";
import { useResource } from "rest-hooks";

import TagsResource from "resources/tags";

import { Row } from "components/base/layout";
import { RowProps } from "components/base/layout/Row";
import Tags from "./Tags";
import AddTagButton from "./AddTagButton";
import EntityTagsContext from "./EntityTagsContext";

const EntityTags = ({
  tagIds: entitysTagIds,
  children,
  ...props
}: {
  tagIds: string[];
} & RowProps) => {
  const { results: availableTags } = useResource(TagsResource.list(), {});
  return (
    <EntityTagsContext.Provider value={{ availableTags, entitysTagIds }}>
      <Row
        marginLeft={18}
        minWidth={70}
        justifyContent="flex-end"
        alignItems="center"
        {...props}
      >
        {children}
        <Tags />
      </Row>
    </EntityTagsContext.Provider>
  );
};

EntityTags.AddTagButton = AddTagButton;

export default EntityTags;
