import G6 from "@antv/g6";
import { scaleLinear } from "d3-scale";

import { getLabelTextAndSize } from "utils/graph";

import SchemasResource from "resources/schemas";
import TablesResource from "resources/tables";
import ColumnsResource from "resources/columns";
import RelationshipsResource from "resources/relationships";

import {
  NODE_SIZES,
  NODE_COLORS,
  COMBO_COLORS,
  COMBO_LABEL_COLORS,
  BLACK,
  EDGE_SEPARATOR,
} from "components/SharedGraph/constants";
import {
  TableRelationshipEdge,
  TableNode,
  SchemaCombo,
  StrictGraphData,
} from "components/SharedGraph/types";

const buildGroupedNodes = (
  columns: ColumnsResource[],
  tableRelationshipEdges: TableRelationshipEdge[]
): TableNode[] => {
  const allSchemas = columns.map(
    (column: ColumnsResource) => column.table.schema.name
  );
  const uniqueSchemas = Array.from(new Set(allSchemas));

  const tableIdToColumns: Record<string, ColumnsResource[]> = columns.reduce(
    (tableIdToColumns, column) => ({
      ...tableIdToColumns,
      [column.table.id]: [column],
    }),
    {} as Record<string, ColumnsResource[]>
  );

  const numberOfRelationshipsPerNode: Record<string, number> = {};
  const numberOfRelationships: number[] = [];
  Object.keys(tableIdToColumns).forEach((tableId) => {
    const nodeRelationships = tableRelationshipEdges.filter(
      (tableRelation: TableRelationshipEdge) =>
        tableRelation.source === tableId || tableRelation.target === tableId
    );
    numberOfRelationshipsPerNode[tableId] = nodeRelationships.length;
    numberOfRelationships.push(nodeRelationships.length);
  });
  const minNumberOfRelationships = Math.min(...numberOfRelationships);
  const maxNumberOfRelationships = Math.max(...numberOfRelationships);

  const sizeScale = scaleLinear()
    .domain([minNumberOfRelationships, maxNumberOfRelationships])
    .range([NODE_SIZES.min, NODE_SIZES.max]);

  return Object.keys(tableIdToColumns).map((tableId) => {
    const tableColumns = tableIdToColumns[tableId];
    const firstColumn = tableColumns[0];

    const nodeSize =
      sizeScale(numberOfRelationshipsPerNode[tableId]) || NODE_SIZES.default;

    const fullLabel = firstColumn.table.name;
    const { label, fontSize } = getLabelTextAndSize(fullLabel, nodeSize);

    return {
      id: tableId,
      tableSlug: TablesResource.buildSlug({
        databaseName: firstColumn.table.schema.database.name,
        schemaName: firstColumn.table.schema.name,
        tableName: firstColumn.table.name,
      }),
      friendlyTableName: firstColumn.table.name.split("_").join("\n"),
      tableName: firstColumn.table.name,
      schemaSlug: SchemasResource.buildSlug({
        databaseName: firstColumn.table.schema.database.name,
        schemaName: firstColumn.table.schema.name,
      }),
      schemaName: firstColumn.table.schema.name,
      databaseName: firstColumn.table.schema.database.name,
      schemaId: firstColumn.table.schema.id,
      comboId: firstColumn.table.schema.id,
      cluster: firstColumn.table.schema.id,
      fullLabel: fullLabel,
      truncatedLabel: label,
      label: label,
      columns: tableColumns,
      size: nodeSize,
      style: {
        fill:
          COMBO_COLORS[uniqueSchemas.indexOf(firstColumn.table.schema.name)],
        stroke:
          NODE_COLORS[uniqueSchemas.indexOf(firstColumn.table.schema.name)],
        lineWidth: 2,
        cursor: "pointer",
      },
      labelCfg: {
        style: {
          fill: BLACK,
          fontSize,
          fontWeight: 500,
          fontFamily: "Fira Code",
          cursor: "pointer",
        },
      },
    } as TableNode;
  });
};

const buildTableRelationshipEdges = (
  columnRelations: RelationshipsResource[]
): TableRelationshipEdge[] => {
  let edgeCounts: Record<string, number> = {};
  columnRelations.forEach((edge) => {
    const edgeName = `${edge.parentColumn.table.id}-${edge.childColumn.table.id}`;
    const reverseEdgeName = `${edge.childColumn.table.id}-${edge.parentColumn.table.id}`;
    if (edgeName in edgeCounts) {
      edgeCounts[edgeName] += 1;
    } else if (reverseEdgeName in edgeCounts) {
      edgeCounts[reverseEdgeName] += 1;
    } else {
      edgeCounts[edgeName] = 1;
    }
  });
  return columnRelations.map((edge) => {
    const edgeName = `${edge.parentColumn.table.id}-${edge.childColumn.table.id}`;
    const reverseEdgeName = `${edge.childColumn.table.id}-${edge.parentColumn.table.id}`;

    let moreThanOneRelationBetweenTable = false;
    [edgeName, reverseEdgeName].forEach((name) => {
      if (name in edgeCounts) {
        if (edgeCounts[name] > 1) {
          moreThanOneRelationBetweenTable = true;
        }
      }
    });
    const edgeType =
      edge.parentColumn.table.id === edge.childColumn.table.id
        ? "loop"
        : moreThanOneRelationBetweenTable
        ? "quadratic"
        : "line";
    return {
      source: edge.parentColumn.table.id || "",
      target: edge.childColumn.table.id || "",
      hiddenLabel:
        edge.parentColumn.name === edge.childColumn.name
          ? edge.parentColumn.name
          : `${edge.parentColumn.name} ${EDGE_SEPARATOR} ${edge.childColumn.name}`,
      type: edgeType,
    };
  });
};

const buildGraphData = (
  relationships: RelationshipsResource[]
): StrictGraphData => {
  if (!relationships.length) {
    return {
      nodes: [],
      edges: [],
      combos: [],
    };
  }

  const parentColumns = relationships.map(
    (relationship) => relationship.parentColumn
  );
  const childColumns = relationships.map(
    (relationship) => relationship.childColumn
  );
  const allColumns = [...parentColumns, ...childColumns];

  const uniqueColumns = allColumns.filter(
    (node, i, allNodes) =>
      allNodes.findIndex((nodeIterator) => nodeIterator.id === node.id) === i
  );

  const tableRelationshipEdges = buildTableRelationshipEdges(relationships);
  const tableNodes = buildGroupedNodes(uniqueColumns, tableRelationshipEdges);

  const uniqueSchemas = uniqueColumns
    .map((column) => column.table.schema)
    .filter(
      (schema, i, allSchemas) =>
        allSchemas.findIndex(
          (schemaIterator) => schemaIterator.id === schema.id
        ) === i
    );

  const schemaCombos: SchemaCombo[] = uniqueSchemas.map((s, i) => ({
    ...s,
    id: s.id,
    label: s.name,
    padding: 0,
    style: {
      opacity: 0.3,
    },
    labelCfg: {
      refY: 10,
      position: "top",
      style: {
        fill: COMBO_LABEL_COLORS[i],
        fontSize: 15,
        fontFamily: "Fira Code",
      },
    },
  }));

  G6.Util.processParallelEdges(tableRelationshipEdges);

  return {
    nodes: tableNodes,
    edges: tableRelationshipEdges,
    combos: schemaCombos,
  };
};

export default buildGraphData;
