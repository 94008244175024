import React from "react";
import { Slider, Checkbox } from "antd";

import { Row, Span } from "components/base/layout";

const StandardSetupTab = () => {
  return (
    <>
      <Row centerY>
        <Span flexShrink={0} marginRight={12} fontWeight={500}>
          Sync schema only
        </Span>
        <Checkbox />
      </Row>
      <Row marginTop={12} centerY>
        <Span flexShrink={0} marginRight={12} fontWeight={500}>
          Sync frequency
        </Span>{" "}
        <Slider
          style={{ width: "100%" }}
          defaultValue={50}
          tooltipVisible
          tipFormatter={(value?: number) => {
            if (!value) {
              return <></>;
            }
            if (value >= 0 && value <= 20) {
              return <>1 week</>;
            }
            if (value > 20 && value <= 40) {
              return <>2 days</>;
            }
            if (value > 40 && value <= 60) {
              return <>1 day</>;
            }
            if (value > 60 && value <= 70) {
              return <>12 hours</>;
            }
            if (value > 70 && value <= 80) {
              return <>6 hours</>;
            }
            if (value > 80 && value <= 90) {
              return <>1 hour</>;
            }
            if (value > 90 && value <= 100) {
              return <>5 minutes</>;
            }

            return <></>;
          }}
        />
      </Row>
    </>
  );
};
export default StandardSetupTab;
