import CustomField from "types/CustomField";
import TablesResource from "resources/tables";
import SchemasResource from "resources/schemas";

type Entity = CustomField | TablesResource | SchemasResource;

function sortEntitiesAlphabetically(entityA: Entity, entityB: Entity) {
  if (entityA.name > entityB.name) {
    return 1;
  } else if (entityB.name > entityA.name) {
    return -1;
  }
  return 0;
}

export function getSortedEntitiesByName<T extends Entity>(
  entities: Entity[]
): T[] {
  return [...entities].sort(sortEntitiesAlphabetically) as T[];
}

export default getSortedEntitiesByName;
