export const pageForSegment = (
  name?: string | undefined,
  properties?: Object | undefined,
  options?: SegmentAnalytics.SegmentOpts | undefined,
  callback?: (() => void) | undefined
) => {
  if (window.analytics?.page) {
    return window.analytics?.page(name, properties, options, callback);
  }
};

export default pageForSegment;
