import React from "react";

import { useTrackWithEntity } from "utils/analytics";

import { UIProps } from "components/base/types";
import { MiniIconButton } from "components/base";

export const ResolveEntityDocumentationButton = ({
  resolveDocumentation,
  iconOnly,
  ...props
}: {
  resolveDocumentation: () => void;
  iconOnly?: boolean;
} & UIProps) => {
  const trackWithEntity = useTrackWithEntity();

  return (
    <MiniIconButton
      onClick={() => {
        resolveDocumentation();
        trackWithEntity("Resolved documentation for entity");
      }}
      iconType="far"
      iconName="fa-check"
      title="Resolve documentation request"
      tooltipProps={{ placement: "topRight" }}
      {...props}
    >
      {!iconOnly && "Resolve"}
    </MiniIconButton>
  );
};

export default ResolveEntityDocumentationButton;
