import React, { useContext } from "react";
import { useFetcher } from "rest-hooks";

import SourcesResource, { SourceStatus } from "resources/sources";

import { Switch } from "antd";
import { Row, Span } from "components/base/layout";
import { Icon } from "components/base";

import ConnectionCardContext from "./ConnectionCardContext";

const ConnectionCardHeader = () => {
  const { connection } = useContext(ConnectionCardContext);

  const updateSource = useFetcher(SourcesResource.partialUpdate());

  const toggleConnection = (isEnabled: boolean) =>
    updateSource(
      { id: connection.id },
      { status: isEnabled ? SourceStatus.Enabled : SourceStatus.Disabled }
    );

  return (
    <Row centerY>
      <Span>{connection.name}</Span>
      <Row marginLeft="auto" marginTop={2} paddingLeft={8}>
        <Switch
          checkedChildren={<Icon name="fa-check" />}
          checked={connection.status === SourceStatus.Enabled}
          onChange={(checked: boolean) => toggleConnection(checked)}
        />
      </Row>
    </Row>
  );
};

export default ConnectionCardHeader;
