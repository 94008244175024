import React from "react";
import PersonSelect, { PersonSelectProps } from "components/base/PersonSelect";
import { useAuth } from "authentication";

export const ProtectedEntityOwnerSelect = (props: PersonSelectProps) => {
  const { user } = useAuth();
  return (
    <PersonSelect
      personType={PersonSelect.PersonType.Owner}
      disabled={!user.canWriteOwners}
      {...props}
    />
  );
};
