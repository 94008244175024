import G6 from "@antv/g6";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import useResizeAware from "react-resize-aware";
import { useDebouncedCallback } from "use-debounce";

import { Graph } from "@antv/g6";
import { GraphOptions } from "@antv/g6/lib/types";

import { LINK_DISTANCE } from "./constants";

import { Pane, Row } from "components/base/layout";

import { StrictGraphData } from "components/SharedGraph/types";

const GraphRenderer = ({
  graphHasData,
  graphData,
  setGraph,
  graph,
  buildGraphConfig,
  hideMiniMap,
  layout,
}: {
  graphHasData: boolean;
  graphData: StrictGraphData;
  setGraph: (graph: Graph) => void;
  graph: Graph | null;
  buildGraphConfig: (
    node: HTMLElement,
    linkDistance: number,
    numNodes: number,
    plugins: any[],
    onTick?: Function,
    layout?: string
  ) => GraphOptions;
  hideMiniMap?: boolean;
  layout?: string;
}) => {
  const ref = React.useRef(null);
  const [resizeListener, sizes] = useResizeAware();

  const debouncedChangeSize = useDebouncedCallback(
    (graph: Graph, width: number, height: number) => {
      graph.changeSize(width, height);
      graph.refresh();
    },
    1000
  );

  useEffect(() => {
    if (!graph && ref.current && graphHasData) {
      const domNode = ReactDOM.findDOMNode(ref.current);
      if (!(domNode && domNode instanceof HTMLElement)) {
        return;
      }

      const onTick = () => {};

      const plugins = hideMiniMap
        ? []
        : [
            new G6.Minimap({
              size: [300, 200],
            }),
          ];

      const numNodes = graphData?.nodes?.length;
      const config = buildGraphConfig(
        domNode,
        LINK_DISTANCE,
        numNodes,
        plugins,
        onTick,
        layout
      );
      setGraph(new Graph(config));
    }
  }, [
    ref,
    graph,
    graphData,
    graphHasData,
    setGraph,
    buildGraphConfig,
    hideMiniMap,
    layout,
  ]);

  useEffect(() => {
    if (graph) {
      const { width, height } = sizes;
      if (width && height) {
        debouncedChangeSize.callback(graph, width, height);
      }
    }
  }, [debouncedChangeSize, graph, sizes]);

  return (
    <Row flexGrow={1} width="100%" position="relative">
      <Pane flexGrow={1} width="100%" ref={ref} />
      {resizeListener}
    </Row>
  );
};

export default GraphRenderer;
