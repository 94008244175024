import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import App from "apps/App";
import { AuthWrapper } from "authentication";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "antd/dist/antd.css";

if (window.navigator.userAgent.indexOf("Mac OS X") !== -1) {
  window.document.body.classList.add("mac");
} else {
  window.document.body.classList.add("not-mac");
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthWrapper>
        <App />
      </AuthWrapper>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
