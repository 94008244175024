import { GraphOptions } from "@antv/g6/lib/types";
import { GRAY } from "components/SharedGraph/constants";

const getComboLayout = (linkDistance: number) => ({
  type: "dagre",
  rankdir: "LR",
  align: "UL",
  controlPoints: true,
  nodesepFunc: () => 1,
  ranksepFunc: () => 70,
});

const buildGraphConfig = (
  node: HTMLElement,
  linkDistance: number,
  numNodes: number,
  plugins: any[],
  onTick?: Function
): GraphOptions => {
  const { width, height } = node.getBoundingClientRect();

  return {
    container: node,
    width: width,
    height: height - 7,
    fitView: true,
    fitViewPadding: 80,
    fitCenter: true,
    animate: false,
    layout: getComboLayout(linkDistance),
    defaultNode: {
      type: "rect",
      size: [30, 20],
      style: {
        radius: 5,
        stroke: "#69c0ff",
        fill: "#ffffff",
        fillOpacity: 1,
        cursor: "pointer",
      },
      labelCfg: {
        style: {
          fill: GRAY,
          fontSize: 12,
          background: {
            fill: "#ffffff",
            stroke: GRAY,
            padding: [2, 2, 2, 2],
            radius: 2,
          },
          fontFamily: "Inter var",
        },
        position: "bottom",
      },
    },
    defaultEdge: {
      type: "polyline",
      style: {
        stroke: "#8C8F93",
        opacity: 0.5,
        endArrow: {
          path: "M 0,0 L 8,4 L 8,-4 Z",
          fill: "#e2e2e2",
        },
        radius: 20,
      },
      labelCfg: {
        refY: 10,
        autoRotate: true,
        style: {
          fontFamily: "Inter var",
          fontSize: 10,
        },
      },
    },
    modes: {
      default: [
        "drag-canvas",
        "drag-node",
        "zoom-canvas",
        "activate-relations",
      ],
    },
    nodeStateStyles: {
      hover: {
        lineWidth: 1,
        stroke: "#1890ff",
        fill: "#e6f7ff",
      },
      active: {
        opacity: 0.6,
      },
      inactive: {
        labelCfg: {
          fill: "red",
        },
      },
      selected: {
        opacity: 0.6,
      },
    },
    edgeStateStyles: {
      active: {
        stroke: "#000",
      },
      inactive: {
        opacity: 0.1,
      },
    },
    plugins,
  };
};

export default buildGraphConfig;
