import React from "react";
import { matchPath } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { useAuth } from "authentication";

import { NavLinkProps } from "components/base/NavLink";

import { Dropdown, Menu } from "antd";
import { Row, Pane } from "components/base/layout";
import { Icon, Link, NavLink } from "components/base";
import SearchBar from "./SearchBar";
import LogoMark from "./LogoMark";

const Logo = () => (
  <Pane minWidth={26}>
    <Link to="/" active width="fit-content">
      <Row centerY>
        <LogoMark />
      </Row>
    </Link>
  </Pane>
);

const menu = (
  <Menu>
    <Menu.Item>
      <NavLink to="/settings/connections" defaultOpacity={0.6}>
        Connections
      </NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/logout" defaultOpacity={0.6}>
        Logout
      </NavLink>
    </Menu.Item>
  </Menu>
);

const HeaderNavLink = ({
  shortPath,
  ...props
}: { shortPath?: string } & NavLinkProps) => (
  <NavLink
    marginRight={8}
    isActive={(match, location) => {
      if (match) {
        return true;
      }
      if (shortPath) {
        return !!matchPath(location.pathname, { path: shortPath });
      }
      return false;
    }}
    {...props}
  />
);

const UNGATED_LINEAGE_ORG_IDS = [
  "79be197b-1b69-4f66-89b0-8d0ea6ec57ad", // CloudFactory
  "3fa9e820-e88f-42f2-b12b-08f36ca8bd45", // Metaplane
];

const UNGATED_GRAPH_ORG_IDS = [
  "79be197b-1b69-4f66-89b0-8d0ea6ec57ad", // CloudFactory
  "3fa9e820-e88f-42f2-b12b-08f36ca8bd45", // Metaplane
  "74a5f310-00cd-4ce9-86c8-5b74585f741f", // Gingko
];

const NavLinks = () => {
  const { user } = useAuth();

  const isGraphUngated =
    user.organization?.id &&
    UNGATED_GRAPH_ORG_IDS.includes(user.organization?.id);

  const isLineageUngated =
    user.organization?.id &&
    UNGATED_LINEAGE_ORG_IDS.includes(user.organization?.id);
  return (
    <Row marginLeft={24}>
      {user.canResolveDocumentation && (
        <HeaderNavLink to="/admin">Admin</HeaderNavLink>
      )}
      <HeaderNavLink to="/catalog" shortPath="/c">
        Catalog
      </HeaderNavLink>
      {isGraphUngated && (
        <HeaderNavLink to="/relationships" shortPath="/r">
          Relationships
        </HeaderNavLink>
      )}
      {isLineageUngated && <HeaderNavLink to="/lineage">Lineage</HeaderNavLink>}
      {user.isMetaplaneSuperAdmin && user.role && (
        <>
          <HeaderNavLink to="/dashboards">Dashboards</HeaderNavLink>
          <HeaderNavLink to="/metrics">Metrics</HeaderNavLink>
          <HeaderNavLink to="/notebooks">Notebooks</HeaderNavLink>
        </>
      )}
    </Row>
  );
};

const HeaderRightActions = () => {
  const isSettingsRoute = !!useRouteMatch({
    path: "/settings",
    sensitive: true,
  });

  return (
    <Row marginLeft="auto" centerY>
      <Link active={isSettingsRoute} marginLeft={26}>
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <Pane>
            <Icon name="fa-cog" fontSize={22} />
          </Pane>
        </Dropdown>
      </Link>
    </Row>
  );
};

const Header = () => (
  <Row centerY paddingY={2} paddingX={22} borderBottom="1px solid #F2F2F2">
    <Logo />
    <SearchBar marginLeft={30} />
    <NavLinks />
    <HeaderRightActions />
  </Row>
);

export default Header;
