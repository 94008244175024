import React from "react";

import EntitiesResource from "resources/entities";
import DatabasesResource from "resources/databases";

import { Pane } from "components/base/layout";
import SidebarDatabasesList from "./SidebarDatabasesList";
import SidebarContext from "./SidebarContext";

const ImportantSidebar = ({
  importantEntities,
  selectedDatabase,
}: {
  importantEntities: EntitiesResource[];
  selectedDatabase?: DatabasesResource;
}) => {
  const importantDatabases = DatabasesResource.inEntities(importantEntities);

  return (
    <SidebarContext.Provider value={{ defaultEntities: importantEntities }}>
      {importantEntities.length ? (
        <Pane paddingBottom={28}>
          <SidebarDatabasesList
            databases={importantDatabases}
            selectedDatabase={selectedDatabase}
            isDefaultOpen
          />
        </Pane>
      ) : (
        <></>
      )}
    </SidebarContext.Provider>
  );
};
export default ImportantSidebar;
