import React, { useContext } from "react";
import moment from "moment";

import { getTimeFromNow, getHumanReadableDatetime } from "utils";

import { SourceSyncStatus } from "resources/sources";

import { Tooltip } from "antd";
import { Pane, Row } from "components/base/layout";
import SyncButton from "./SyncButton";
import TestButton from "./TestButton";
import { ConnectionType } from "types/Connection";

import ConnectionCardContext from "../ConnectionCardContext";

const StatusTab = () => {
  const { connection } = useContext(ConnectionCardContext);

  const oldestStatus = connection.syncHistory[0];
  const mostRecentStatus = connection.syncHistory.length
    ? connection.syncHistory[connection.syncHistory.length - 1]
    : oldestStatus;

  const mostRecentSuccessfulStatus = [...connection.syncHistory]
    .reverse()
    .filter(
      (syncHistoryEvent) =>
        syncHistoryEvent.status === SourceSyncStatus.Succeeded
    )[0];

  return (
    <>
      {mostRecentStatus && (
        <>
          <Row fontSize={11} fontWeight={300}>
            <Pane>{moment(oldestStatus.createdAt).format("MM/DD")}</Pane>
            <Pane marginLeft="auto">
              {moment(mostRecentStatus.createdAt).format("MM/DD")}
            </Pane>
          </Row>
          <Row justifyContent="space-between" marginTop={7}>
            {connection.syncHistory.map((syncEvent) => (
              <Tooltip
                key={syncEvent.id}
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                color="black"
                title={
                  <Pane>
                    <Pane whiteSpace="nowrap">
                      {syncEvent.status === SourceSyncStatus.Errored
                        ? "Sync error"
                        : "Synced"}
                    </Pane>
                    <Pane opacity={0.6}>
                      {getHumanReadableDatetime(syncEvent.createdAt)}
                    </Pane>
                  </Pane>
                }
              >
                <Row centerX flexGrow={1}>
                  <Pane
                    width={2}
                    height={25}
                    backgroundColor={
                      syncEvent.status === SourceSyncStatus.Errored
                        ? "hsla(0, 60%, 75%, 1)"
                        : "#A5DFFF"
                    }
                  />
                </Row>
              </Tooltip>
            ))}
          </Row>
        </>
      )}

      <Row marginTop={20} alignItems="center">
        <Pane
          paddingRight={8}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {mostRecentStatus?.status === SourceSyncStatus.Errored && (
            <>
              {mostRecentSuccessfulStatus && (
                <Pane>
                  Last sync completed:{" "}
                  {getTimeFromNow(mostRecentSuccessfulStatus.createdAt)}
                </Pane>
              )}
              <Pane>
                Last sync attempted:{" "}
                {getTimeFromNow(mostRecentStatus.createdAt)}
              </Pane>
            </>
          )}
          {mostRecentStatus?.status === SourceSyncStatus.Succeeded && (
            <>Synced {getTimeFromNow(mostRecentStatus.createdAt)}</>
          )}
          {!mostRecentStatus && "Never synced"}
        </Pane>
        <Row marginLeft="auto">
          {connection.type !== ConnectionType.Dbt && <TestButton />}
          <SyncButton marginLeft={8} />
        </Row>
      </Row>
    </>
  );
};

export default StatusTab;
