import React from "react";

import { H3, H2 } from "components/base/type";
import { Row } from "components/base/layout";
import { Connection, ConnectionType } from "types/Connection";
import IntegrationCardGroup, { IntegrationCard } from "./IntegrationCardGroup";
import SlackIntegrationCard from "./SlackIntegrationCard";

const DATABASES: Connection[] = [
  {
    name: "Snowflake",
    type: ConnectionType.Snowflake,
    logoFileName: "integration-tile_snowflake.svg",
  },
  {
    name: "Google BigQuery",
    type: ConnectionType.BigQuery,
    logoFileName: "integration-tile_google-big-query.svg",
  },
  {
    name: "MySQL",
    type: ConnectionType.MySQL,
    logoFileName: "integration-tile_mysql.svg",
    disabled: false,
  },
  {
    name: "PostgreSQL",
    type: ConnectionType.PostgreSQL,
    logoFileName: "integration-tile_postgres-sql.svg",
    disabled: true,
  },
  {
    name: "Amazon Redshift",
    type: ConnectionType.Redshift,
    logoFileName: "integration-tile_redshift.svg",
    disabled: true,
  },
];

const MODELERS: Connection[] = [
  {
    name: "dbt",
    type: ConnectionType.Dbt,
    logoFileName: "integration-tile_dbt.svg",
    maxWidth: 100,
  },
];

const BI: Connection[] = [
  {
    name: "Looker",
    type: ConnectionType.Looker,
    logoFileName: "integration-tile_looker.svg",
    disabled: true,
  },
  {
    name: "Tableau",
    type: ConnectionType.Tableau,
    logoFileName: "integration-tile_tableau.svg",
    disabled: true,
  },
  {
    name: "Mode",
    type: ConnectionType.Mode,
    logoFileName: "integration-tile_mode.svg",
    disabled: true,
  },
  {
    name: "Redash",
    type: ConnectionType.Redash,
    logoFileName: "integration-tile_redash.svg",
    disabled: true,
  },
];

const APPS: Connection[] = [
  {
    name: "Slack",
    type: ConnectionType.Slack,
    logoFileName: "integration-tile_slack.svg",
    maxWidth: 100,
    integrationCardRenderer: SlackIntegrationCard,
  },
  {
    name: "Salesforce",
    type: ConnectionType.Salesforce,
    logoFileName: "integration-tile_salesforce.svg",
    disabled: true,
  },
];

const AvailableConnectionsSection = ({
  setConnection,
}: {
  setConnection: (connection?: Connection) => void;
}) => {
  return (
    <>
      <H2 marginBottom={41}>Available connections</H2>
      <Row wrap marginRight={-72}>
        <IntegrationCardGroup marginRight={86}>
          <H3>Databases</H3>
          <IntegrationCardGroup.CardsRow>
            {DATABASES.map((source) => (
              <IntegrationCard
                key={source.type}
                disabled={source.disabled}
                onClick={() => setConnection(source)}
              >
                <IntegrationCard.Image
                  logoFileName={source.logoFileName}
                  maxWidth={source.maxWidth}
                />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
        <IntegrationCardGroup marginRight={86}>
          <H3>Modelers</H3>
          <IntegrationCardGroup.CardsRow>
            {MODELERS.map((source) => (
              <IntegrationCard
                key={source.type}
                disabled={source.disabled}
                onClick={() => setConnection(source)}
              >
                <IntegrationCard.Image
                  logoFileName={source.logoFileName}
                  maxWidth={source.maxWidth}
                />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
        <IntegrationCardGroup marginRight={86}>
          <H3 opacity={0.4}>Business Intelligence</H3>
          <IntegrationCardGroup.CardsRow>
            {BI.map((source) => (
              <IntegrationCard
                key={source.type}
                disabled={source.disabled}
                onClick={() => setConnection(source)}
              >
                <IntegrationCard.Image
                  logoFileName={source.logoFileName}
                  maxWidth={source.maxWidth}
                />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
        <IntegrationCardGroup>
          <H3>Apps</H3>
          <IntegrationCardGroup.CardsRow>
            {APPS.map((source) => {
              if (source.integrationCardRenderer) {
                const CustomIntegrationCard = source.integrationCardRenderer;
                return (
                  <CustomIntegrationCard key={source.type} source={source} />
                );
              }
              return (
                <IntegrationCard
                  key={source.type}
                  disabled={source.disabled}
                  onClick={() => setConnection(source)}
                >
                  <IntegrationCard.Image
                    logoFileName={source.logoFileName}
                    maxWidth={source.maxWidth}
                  />
                </IntegrationCard>
              );
            })}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
      </Row>
    </>
  );
};

export default AvailableConnectionsSection;
