import { useRef, useEffect } from "react";

export function usePrevious<T>(currentValue: T): T | null {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = currentValue;
  });
  return ref.current;
}

export default usePrevious;
