import React from "react";
import { Typography } from "./Typography";
import { TypographyProps } from "components/base/types";

export const H4 = (props: TypographyProps) => (
  <Typography
    is="h4"
    margin={0}
    fontSize={16}
    fontWeight={500}
    color="#171E26"
    {...props}
  />
);
