import React from "react";

import { useResource } from "rest-hooks";
import LineageResource from "resources/lineage";

import { MetaTitle } from "components/base";
import { Column } from "components/base/layout";
import LineageGraphController from "components/LineageGraphController";

const LineagePage = () => {
  const { results: lineage } = useResource(LineageResource.list(), {});

  return (
    <Column flexGrow={1}>
      <MetaTitle>Lineage</MetaTitle>
      <LineageGraphController lineage={lineage} />
    </Column>
  );
};

export default LineagePage;
