import React from "react";
import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";
import classNames from "classnames";

export const Icon = ({
  name,
  type,
  fixedWidth,
  className,
  ...props
}: {
  name: string;
  type?: string;
  fixedWidth?: boolean;
  className?: string;
} & UIProps) => (
  <Pane
    is="i"
    className={classNames(
      type || "fal",
      fixedWidth && "fa-fw",
      name,
      className
    )}
    {...props}
  />
);

export default Icon;
