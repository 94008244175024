import React from "react";
import { useParams } from "react-router-dom";

import { withLoader } from "components/base";
import { Pane, Row } from "components/base/layout";

import DatabasePage from "pages/DatabasePage";
import SchemaPage from "pages/SchemaPage";
import TablePage from "pages/TablePage";

import PageSectionColumn from "./PageSectionColumn";
import Sidebar from "./Sidebar";

const DatabasePageLoader = withLoader(DatabasePage);
const SchemaPageLoader = withLoader(SchemaPage);
const TablePageLoader = withLoader(TablePage);

const CatalogPage = () => {
  const {
    databaseName: selectedDatabaseName,
    schemaName: selectedSchemaName,
    tableName: selectedTableName,
  } = useParams<{
    databaseName?: string;
    schemaName?: string;
    tableName?: string;
  }>();

  return (
    <Pane height="calc(100vh - 63px)">
      <Row height="100%">
        <Sidebar />
        {(selectedDatabaseName || selectedSchemaName || selectedTableName) && (
          <PageSectionColumn
            flexGrow={1}
            borderLeft="1px solid #eee"
            minHeight="100%"
          >
            {selectedTableName ? (
              <TablePageLoader />
            ) : selectedSchemaName ? (
              <SchemaPageLoader />
            ) : (
              <DatabasePageLoader />
            )}
          </PageSectionColumn>
        )}
      </Row>
    </Pane>
  );
};

export default CatalogPage;
