import React from "react";
import { useParams } from "react-router-dom";

import { useResource } from "rest-hooks";
import RelationshipsResource from "resources/relationships";

import { MetaTitle } from "components/base";
import { Column } from "components/base/layout";
import NetworkGraphController from "components/NetworkGraphController";

const GraphPage = () => {
  const { tableName, schemaName, databaseName } = useParams<{
    tableName?: string;
    schemaName?: string;
    databaseName?: string;
  }>();

  const { results: relationships } = useResource(RelationshipsResource.list(), {
    entityType: "column",
  });

  return (
    <Column flexGrow={1}>
      <MetaTitle>Relationships Graph</MetaTitle>
      <NetworkGraphController
        relationships={relationships}
        tableName={tableName}
        schemaName={schemaName}
        databaseName={databaseName}
      />
    </Column>
  );
};

export default GraphPage;
