import React, { useRef } from "react";
import Box from "ui-box";
import { UIProps } from "components/base/types";
import ReactContentEditable, {
  ContentEditableEvent,
  Props as ReactContentEditableProps,
} from "react-contenteditable";
import styled from "styled-components";

export interface IContentEditableRef {
  text: string;
  reset: () => void;
}

interface IContentEditable {
  minEmptyWidth?: number;
  onPressEnter?: (value: string) => void;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  innerRef?: { current?: IContentEditableRef };
}

type StyledContentEditableProps = Pick<
  ReactContentEditableProps,
  "html" | "onChange"
> &
  Pick<IContentEditable, "minEmptyWidth">;

const StyledReactContentEditable = styled<React.FC<StyledContentEditableProps>>(
  ({ minEmptyWidth, ...rest }) => <ReactContentEditable {...rest} />
)`
  outline: none;
  cursor: text;

  &:empty {
    min-width: ${(props) => props.minEmptyWidth || 0}px;
    font-weight: 400;

    &:before {
      content: attr(data-placeholder);
      opacity: 0.5;
    }
  }
`;

export const ContentEditable = ({
  onChange,
  onBlur,
  onPressEnter,
  innerRef,
  ...props
}: UIProps & IContentEditable) => {
  const text = useRef("");

  const reset = () => {
    text.current = "";
  };

  const handleChange = (event: ContentEditableEvent) => {
    text.current = event.target.value;
    if (innerRef) {
      innerRef.current = { text: text.current, reset };
    }

    onChange && onChange(text.current);
  };

  const handleBlur = () => {
    onBlur && onBlur(text.current);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = event.which || event.keyCode;
    const ENTER_KEY_KEYCODE = 13;
    if (keyCode === ENTER_KEY_KEYCODE) {
      event.preventDefault();
      onPressEnter && onPressEnter(text.current);
    }
  };

  return (
    <Box
      is={StyledReactContentEditable}
      html={text.current}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      {...props}
    />
  );
};

export default ContentEditable;
