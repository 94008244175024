import React from "react";
import { Drawer, Form } from "antd";
import { Store } from "antd/lib/form/interface";

import { Pane, Row } from "components/base/layout";
import { Button } from "components/base";
import { Connection } from "types/Connection";
import ConnectionFormItems from "./ConnectionFormItems";

const formLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const AddConnectionDrawer = ({
  selectedConnection,
  onClose,
  onSaveConnection,
}: {
  selectedConnection?: Connection;
  onClose: () => void;
  onSaveConnection: (values: Store) => void;
}) => {
  const [form] = Form.useForm();
  if (!selectedConnection) {
    return <></>;
  }

  return (
    <Drawer
      title={`Connect ${selectedConnection.name}`}
      placement="right"
      width={600}
      onClose={onClose}
      visible={!!selectedConnection}
    >
      <Pane paddingRight={30} paddingLeft={30}>
        <Form
          {...formLayout}
          form={form}
          name="basic"
          labelAlign="left"
          colon={false}
          onFinish={onSaveConnection}
        >
          <ConnectionFormItems form={form} connection={selectedConnection} />
          <Row>
            <Button
              type="primary"
              htmlType="submit"
              marginLeft="auto"
              marginTop={30}
            >
              Connect
            </Button>
          </Row>
        </Form>
      </Pane>
    </Drawer>
  );
};

export default AddConnectionDrawer;
