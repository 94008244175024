import { getTruncatedText } from "utils/graph";

import SchemasResource from "resources/schemas";
import TablesResource from "resources/tables";
import LineageResource from "resources/lineage";

import { GRAPH_FONT_SIZE, NODE_COLORS } from "components/SharedGraph/constants";
import {
  TableRelationshipEdge,
  TableNode,
  StrictGraphData,
} from "components/SharedGraph/types";

const buildGroupedNodes = (
  tables: TablesResource[],
  tableRelationshipEdges: TableRelationshipEdge[]
): TableNode[] => {
  const allSchemas = tables.map((table: TablesResource) => table.schema.name);
  const uniqueSchemas = Array.from(new Set(allSchemas));

  return tables.map((table) => {
    return {
      id: table.id,
      tableSlug: TablesResource.buildSlug({
        databaseName: table.schema.database.name,
        schemaName: table.schema.name,
        tableName: table.name,
      }),
      tableName: table.name,
      schemaSlug: SchemasResource.buildSlug({
        databaseName: table.schema.database.name,
        schemaName: table.schema.name,
      }),
      schemaName: table.schema.name,
      databaseName: table.schema.database.name,
      schemaId: table.schema.id,
      cluster: table.schema.id,
      label: getTruncatedText(`${table.name}`, 200, GRAPH_FONT_SIZE),
      columns: [],
      style: {
        fill: NODE_COLORS[uniqueSchemas.indexOf(table.schema.name)],
        opacity: 0.6,
        stroke: NODE_COLORS[uniqueSchemas.indexOf(table.schema.name)],
        lineWidth: 2,
        cursor: "pointer",
      },
    };
  });
};

const buildTableRelationshipEdges = (
  tableRelations: LineageResource[]
): TableRelationshipEdge[] =>
  tableRelations.map((edge) => ({
    source: edge.sourceTable.id || "",
    target: edge.targetTable.id || "",
    hiddenLabel: "",
    type: edge.sourceTable.id === edge.targetTable.id ? "loop" : "polyline",
  }));

const buildGraphData = (lineage: LineageResource[]): StrictGraphData => {
  if (!lineage.length) {
    return {
      nodes: [],
      edges: [],
    };
  }

  const sourceTables = lineage.map(
    (lineage_relationship) => lineage_relationship.sourceTable
  );
  const targetTables = lineage.map(
    (lineage_relationship) => lineage_relationship.targetTable
  );
  const allTables = [...sourceTables, ...targetTables];

  const uniqueTables = allTables.filter(
    (node, i, allNodes) =>
      allNodes.findIndex((nodeIterator) => nodeIterator.id === node.id) === i
  );

  const tableRelationshipEdges = buildTableRelationshipEdges(lineage);
  const tableNodes = buildGroupedNodes(uniqueTables, tableRelationshipEdges);

  return {
    nodes: tableNodes,
    edges: tableRelationshipEdges,
  };
};

export default buildGraphData;
