import React, { useContext } from "react";
import { Tabs } from "antd";

import { Pane } from "components/base/layout";

import StatusTab from "./StatusTab";
import LogsTab from "./LogsTab";
import SetupTab from "./SetupTab";
import ConnectionCardContext from "../ConnectionCardContext";
import { ConnectionType } from "types/Connection";
import SourcesResource from "resources/sources";

const validateSource = (source: SourcesResource) => {
  switch (source.type) {
    case ConnectionType.Dbt:
      return (
        !!source.config?.repositoryId &&
        !!source.config?.branch &&
        !!source.config?.warehouseId
      );
    default:
      return true;
  }
};

enum TabKeys {
  Status = "status",
  Logs = "logs",
  Setup = "setup",
}

const ConnectionCardTabs = () => {
  const { connection } = useContext(ConnectionCardContext);

  const isSetupRequired =
    connection.type === ConnectionType.Dbt && !validateSource(connection);

  return (
    <Pane marginTop={10}>
      <Tabs defaultActiveKey={isSetupRequired ? TabKeys.Setup : TabKeys.Status}>
        <Tabs.TabPane tab="Status" key={TabKeys.Status}>
          <StatusTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Logs" key={TabKeys.Logs} disabled>
          <LogsTab />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Setup"
          key={TabKeys.Setup}
          disabled={connection.type !== ConnectionType.Dbt}
        >
          <SetupTab />
        </Tabs.TabPane>
      </Tabs>
    </Pane>
  );
};

export default ConnectionCardTabs;
