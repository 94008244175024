import React from "react";

import { Pane } from "components/base/layout";
import { Resizable } from "re-resizable";
import { useLocalStorage } from "@rehooks/local-storage";

import { SIDEBAR_WIDTH_STORAGE_KEY } from "constants/storageKeys";
import PageSectionColumn from "../PageSectionColumn";
import SidebarContents from "./SidebarContents";

const Sidebar = () => {
  const [userSidebarWidth, setUserSidebarWidth] = useLocalStorage(
    SIDEBAR_WIDTH_STORAGE_KEY
  );

  return (
    <Pane
      is={Resizable}
      defaultSize={{ width: userSidebarWidth || "20vw", height: "auto" }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStop={(
        e: MouseEvent | TouchEvent,
        direction: string,
        ref: HTMLElement,
        delta: object
      ) => {
        setUserSidebarWidth(ref.offsetWidth + "px");
      }}
      flexShrink={0}
      overflow="scroll"
    >
      <PageSectionColumn
        fontSize={14}
        paddingTop={16}
        paddingBottom={40}
        overflow="visible"
      >
        <SidebarContents />
      </PageSectionColumn>
    </Pane>
  );
};

export default Sidebar;
