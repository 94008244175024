import { buildApiUri } from "resources/api-uri";

export const identifyUser = (
  userId: string,
  properties: Record<string, unknown>
) =>
  fetch(buildApiUri("track/identify"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      properties,
    }),
  });
