// https://github.com/coinbase/rest-hooks/blob/master/packages/rest-hooks/src/resource/paramsToString.ts

export const paramsToString = (
  searchParams: Readonly<Record<string, string | number | boolean>>
) => {
  const params = new URLSearchParams(searchParams as any);
  params.sort();
  return params.toString();
};

export default paramsToString;
