import React from "react";

import DatabasesResource from "resources/databases";

import { withLoader } from "components/base";
import SidebarDatabaseRow from "./SidebarDatabaseRow";

const SidebarDatabasesList = ({
  databases,
  selectedDatabase,
  isDefaultOpen,
}: {
  databases: DatabasesResource[];
  selectedDatabase?: DatabasesResource;
  isDefaultOpen?: boolean;
}) => (
  <>
    {databases.map((database) => (
      <SidebarDatabaseRow
        database={database}
        key={database.id}
        active={selectedDatabase && database.id === selectedDatabase.id}
        defaultOpen={isDefaultOpen}
      />
    ))}
  </>
);

export default withLoader(SidebarDatabasesList, {}, () => <></>);
