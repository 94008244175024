import React from "react";

import { useAuth } from "authentication";
import {
  EntityImportanceToggleProps,
  EntityImportanceToggle,
} from "components/EntityImportanceToggle";

export const ProtectedEntityImportanceToggle = (
  props: EntityImportanceToggleProps
) => {
  const { user } = useAuth();
  if (!user.canWriteEntityImportance) {
    return null;
  }
  return <EntityImportanceToggle {...props} />;
};
