import React from "react";
import { UIProps } from "components/base/types";
import { EntityTypeIcon, Icon as BaseIcon } from "components/base";
import SchemasResource from "./index";

const Icon = (props: UIProps) => {
  return (
    <EntityTypeIcon type={SchemasResource.entityType}>
      <BaseIcon
        fixedWidth
        name="fa-chart-network"
        {...props}
        color="hsl(241 41% 40% / 1)"
      />
    </EntityTypeIcon>
  );
};

export default Icon;
