import React from "react";

import { useResource } from "rest-hooks";
import UsersResource from "resources/users";

import { useAuth } from "authentication";
import { H4 } from "components/base/type";
import { Pane, Row } from "components/base/layout";
import { MetaTitle, PersonRow } from "components/base";
import DocumentationRequestsSection from "./DocumentationRequestsSection";
import { filterOutMetaplaneUsers } from "utils/user";

const AdminPage = () => {
  const { user } = useAuth();

  const { results: organizationsUsers } = useResource(UsersResource.list(), {});
  const filteredUsers = filterOutMetaplaneUsers({
    loggedInUser: user,
    organizationsUsers,
  });
  return (
    <Pane paddingX={72} paddingTop={45} paddingBottom={200}>
      <MetaTitle>Admin</MetaTitle>
      <Row>
        <DocumentationRequestsSection />
        <Pane width={350} paddingLeft={100}>
          <H4 marginBottom={20}>Users</H4>
          {filteredUsers.map((user) => (
            <PersonRow name={user.fullName} marginBottom={10} />
          ))}
        </Pane>
      </Row>
    </Pane>
  );
};

export default AdminPage;
