import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import ColumnsResource from "./columns";

export default class RelationshipsResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly type: string = "";
  readonly parentColumn: ColumnsResource = new ColumnsResource();
  readonly childColumn: ColumnsResource = new ColumnsResource();
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("relationships");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this] },
    });
  }

  static getShortestPathRelationships<T extends typeof Resource>(this: T) {
    return super.list().extend({
      url(urlParams: Readonly<Record<string, any>>) {
        const { fromTableId, toTableId } = urlParams;
        return `${RelationshipsResource.urlRoot}/shortestPaths?fromTableId=${fromTableId}&toTableId=${toTableId}`;
      },
    });
  }
}

export const entityRelationshipsAddToListUpdater = (
  response: { relationship: string },
  sourceIds: { results: string[] } | undefined
) => ({
  ...sourceIds,
  results: [
    ...(sourceIds?.results ? sourceIds.results : []),
    response.relationship,
  ],
});

export const entityRelationshipsRemoveFromListUpdater = (
  relationshipId: string
) => (response: any, sourceIds: { results: string[] } | undefined) => ({
  ...sourceIds,
  results:
    sourceIds?.results?.filter((id: string) => id !== relationshipId) || [],
});
