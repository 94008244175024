import React, { useState } from "react";
import { Input, PageSection } from "components/base";
import { Pane } from "components/base/layout";
import TableColumnsList from "components/TableColumnsList";

const TableColumnsSection = ({ tableId }: { tableId: string }) => {
  const [filterQuery, setFilterQuery] = useState("");

  return (
    <>
      <PageSection marginBottom={-6} paddingTop={6}>
        <Pane marginLeft={-16}>
          <Input
            placeholder="Filter columns..."
            className="graph-search-column-filter"
            onChange={(e) => setFilterQuery(e.currentTarget.value)}
          />
        </Pane>
      </PageSection>

      <PageSection>
        <TableColumnsList tableId={tableId} filterQuery={filterQuery} />
      </PageSection>
    </>
  );
};

export default TableColumnsSection;
