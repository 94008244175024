import { Resource } from "@rest-hooks/rest";

import CustomField from "types/CustomField";
import Description from "types/Description";
import {
  ENTITY_CATALOG_ROOT_URL_PATH,
  ENTITY_GRAPH_ROOT_URL_PATH,
} from "constants/url";
import { enforceCasing, paramsToString } from "utils";

import AuthenticatedResource from "resources/AuthenticatedResource";
import { buildApiUri } from "resources/api-uri";
import SchemasResource from "resources/schemas";
import EntitiesResource from "resources/entities";
import EntityType from "resources/entity-type";
import EntityResourceType from "resources/entity-resource-type";
import TagsResource from "resources/tags";
import Icon, { buildIcon } from "./Icon";
import DocumentationRequest from "resources/documentation-request";

export interface EntityUserRelation {
  userId: string;
  relationType: string;
}

export enum TableType {
  View = "view",
  Table = "table",
}

export default class TablesResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly name: string = "";
  readonly description: Description = {
    descriptionBlocks: [
      {
        type: "paragraph",
        data: {
          text: "",
        },
      },
    ],
    descriptionHtml: "",
    descriptionText: "",
  };
  readonly hidden: boolean = false;
  readonly isImportant: boolean = false;

  readonly tagIds: string[] = [];
  readonly entityUserRelations: EntityUserRelation[] = [];

  readonly customFields: CustomField[] = [];
  readonly schema: {
    id: string;
    name: string;
    database: { name: string; id: string };
  } = { name: "", id: "", database: { name: "", id: "" } };
  readonly statistics: {
    col_count: number;
    row_count: number;
    type: string;
  } = {
    col_count: 0,
    row_count: 0,
    type: "",
  };

  readonly status: string | undefined = undefined;
  readonly sourceCreatedAt: Date | undefined = undefined;
  readonly sourceUpdatedAt: Date | undefined = undefined;
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;
  static entityType = EntityType.Table;
  readonly entityType = TablesResource.entityType;
  readonly documentationRequests: DocumentationRequest[] = [];

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("tables");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static detailByName<T extends typeof Resource>(this: T) {
    return this.detail().extend({
      schema: { results: [this] },

      url(urlParams: Readonly<Record<string, any>>) {
        return TablesResource.urlRoot + `?${paramsToString(urlParams)}`;
      },
    });
  }

  static addTag<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url({ id }) {
        return `${TablesResource.urlRoot}/${id}/tags`;
      },
    });
  }

  static createAndAddTagToTable<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url({ id }) {
        return `${TablesResource.urlRoot}/${id}/tags/new`;
      },
      schema: {
        table: this,
        tag: TagsResource,
      },
    });
  }
  static removeTag<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      method: "DELETE",
      url({ table, tagId }) {
        return `${TablesResource.urlRoot}/${table.id}/tags/${tagId}`;
      },
      optimisticUpdate: (params: any, body: any) => ({
        ...params.table,
        tagIds: params.table.tagIds.filter(
          (tagId: string) => tagId !== params.tagId
        ),
      }),
    });
  }

  static addOwner<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "POST",
      url() {
        return buildApiUri("entity-user-relations");
      },
      fetch({}, { id, userId }: { id: string; userId: string }) {
        return endpoint.fetch.call(
          this,
          {},
          { targetId: id, userId, entityType: "table", relationType: "owner" }
        );
      },
      schema: {
        entity: this,
      },
    });
  }

  static removeOwner<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "DELETE",
      url({ userId, targetId }) {
        return `${buildApiUri(
          "entity-user-relations"
        )}?userId=${userId}&targetId=${targetId}&entityType=table&relationType=owner`;
      },
      fetch({ id, userId }: { id: string; userId: string }) {
        return endpoint.fetch.call(this, {
          userId,
          targetId: id,
        });
      },
      schema: {
        entity: this,
      },
    });
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      optimisticUpdate: (params: any, body: any) => ({
        id: params.id,
        ...body,
      }),
    });
  }

  static requestDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "POST",
      url({ id }) {
        return `${TablesResource.urlRoot}/${id}/documentation-requests`;
      },
    });
  }

  static resolveDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      url({ id }) {
        return `${TablesResource.urlRoot}/${id}/documentation-requests/resolve`;
      },
    });
  }

  static buildCatalogUrlPath({
    tableName,
    schemaName,
    databaseName,
  }: {
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return `${ENTITY_CATALOG_ROOT_URL_PATH}/${enforceCasing(
      databaseName
    )}/${enforceCasing(schemaName)}/${enforceCasing(tableName)}`;
  }

  static buildGraphUrlPath({
    tableName,
    schemaName,
    databaseName,
  }: {
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return `${ENTITY_GRAPH_ROOT_URL_PATH}/${enforceCasing(
      databaseName
    )}/${enforceCasing(schemaName)}/${enforceCasing(tableName)}`;
  }

  static buildSlug({
    tableName,
    schemaName,
    databaseName,
  }: {
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) {
    return (
      SchemasResource.buildSlug({ schemaName, databaseName }) +
      `.${tableName.toLowerCase()}`
    );
  }

  static inEntities(entities?: EntitiesResource[]) {
    return EntitiesResource.tables(entities);
  }

  get schemaResource() {
    return SchemasResource.fromJS(this.schema);
  }

  get type() {
    return this.statistics.type === "VIEW" ? TableType.View : TableType.Table;
  }

  get isTable() {
    return this.type === TableType.Table;
  }

  get isView() {
    return this.type === TableType.View;
  }

  get catalogUrlPath() {
    return TablesResource.buildCatalogUrlPath({
      tableName: this.name,
      schemaName: this.schema.name,
      databaseName: this.schema.database.name,
    });
  }

  get graphUrlPath() {
    return TablesResource.buildGraphUrlPath({
      tableName: this.name,
      schemaName: this.schema.name,
      databaseName: this.schema.database.name,
    });
  }

  get slug() {
    return this.schemaResource.slug + `.${this.name.toLowerCase()}`;
  }

  get breadcrumbs(): EntityResourceType[] {
    return [this.schemaResource.databaseResource, this.schemaResource];
  }

  hasEqualSlug({
    tableName,
    schemaName,
    databaseName,
  }: {
    tableName?: string;
    schemaName?: string;
    databaseName?: string;
  }) {
    return (
      (tableName &&
        schemaName &&
        databaseName &&
        this.name.toLowerCase() === tableName.toLowerCase() &&
        this.schema.name.toLowerCase() === schemaName.toLowerCase() &&
        this.schema.database.name.toLowerCase() ===
          databaseName.toLowerCase()) ||
      false
    );
  }

  static Icon = Icon;
  get Icon() {
    return buildIcon(this.type);
  }
  readonly EntityClass = TablesResource;
}
