import React from "react";

import { Input as AntdInput } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";

type AntdInputProps = React.ComponentProps<typeof AntdInput>;

export const Input = (props: AntdInputProps & UIProps) => (
  <Box is={AntdInput} {...props} />
);

type AntdPasswordInputProps = React.ComponentProps<typeof AntdInput.Password>;
Input.Password = (props: AntdPasswordInputProps & UIProps) => (
  <Box is={AntdInput.Password} {...props} />
);

type AntdSearchInputProps = React.ComponentProps<typeof AntdInput.Search>;

const InputSearch = ({
  focus,
  ...props
}: AntdSearchInputProps & { focus?: boolean } & UIProps) => {
  const searchRef = React.useRef<AntdInput>(null);
  React.useEffect(() => {
    if (focus && searchRef.current) {
      window.setTimeout(() => {
        searchRef?.current?.input.focus();
      }, 200);
    }
  }, [searchRef, focus]);

  return <Box is={AntdInput.Search} {...props} ref={searchRef} />;
};
Input.Search = InputSearch;

type AntdTextAreaInputProps = React.ComponentProps<typeof AntdInput.TextArea>;
Input.TextArea = (props: AntdTextAreaInputProps & UIProps) => (
  <Box is={AntdInput.TextArea} {...props} />
);

export default Input;
