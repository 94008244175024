import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useRetrieve } from "rest-hooks";

import { usePageViews } from "utils/hooks";
import { useAuth } from "authentication";

import CatalogPage from "pages/CatalogPage";
import ConnectionsPage from "pages/ConnectionsPage";
import GraphPage from "pages/GraphPage";
import LineagePage from "pages/LineagePage";
import AdminPage from "pages/AdminPage";

import TagsResource from "resources/tags";
import UsersResource from "resources/users";
import * as UrlPaths from "constants/url";

const DashboardsPage = () => <></>;
const MetricsPage = () => <></>;
const NotebooksPage = () => <></>;

const Routes = () => {
  const { user } = useAuth();
  useRetrieve(TagsResource.list(), {});
  useRetrieve(UsersResource.list(), {});

  usePageViews();
  return (
    <Switch>
      <Route path="/admin">
        <AdminPage />
      </Route>
      <Route
        path={`${UrlPaths.ENTITY_CATALOG_ROOT_URL_PATH}/:databaseName/:schemaName/:tableName`}
      >
        <CatalogPage />
      </Route>
      <Route
        path={`${UrlPaths.ENTITY_CATALOG_ROOT_URL_PATH}/:databaseName/:schemaName`}
      >
        <CatalogPage />
      </Route>
      <Route path={`${UrlPaths.ENTITY_CATALOG_ROOT_URL_PATH}/:databaseName`}>
        <CatalogPage />
      </Route>
      <Route path={UrlPaths.CATALOG_ROOT_URL_PATH}>
        <CatalogPage />
      </Route>
      <Redirect
        from={UrlPaths.ENTITY_CATALOG_ROOT_URL_PATH}
        to={UrlPaths.CATALOG_ROOT_URL_PATH}
      />
      <Route
        path={`${UrlPaths.ENTITY_GRAPH_ROOT_URL_PATH}/:databaseName/:schemaName/:tableName`}
      >
        <GraphPage />
      </Route>
      <Route path={UrlPaths.GRAPH_ROOT_URL_PATH}>
        <GraphPage />
      </Route>
      <Redirect
        from={UrlPaths.ENTITY_GRAPH_ROOT_URL_PATH}
        to={UrlPaths.GRAPH_ROOT_URL_PATH}
      />
      <Route path={UrlPaths.LINEAGE_ROOT_URL_PATH}>
        <LineagePage />
      </Route>
      <Redirect from="/l" to={UrlPaths.LINEAGE_ROOT_URL_PATH} />
      <Route path="/dashboards">
        <DashboardsPage />
      </Route>
      <Route path="/metrics">
        <MetricsPage />
      </Route>
      <Route path="/notebooks">
        <NotebooksPage />
      </Route>
      <Route path="/settings/connections">
        <ConnectionsPage />
      </Route>
      <Route path="/logout">
        <LogoutPage />
      </Route>
      <Route exact path="/">
        {user.canResolveDocumentation ? (
          <Redirect to="/admin" />
        ) : (
          <Redirect to={UrlPaths.CATALOG_ROOT_URL_PATH} />
        )}
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

const LogoutPage = () => {
  const { logout } = useAuth();
  logout({ returnTo: window.location.origin });
  return <></>;
};

export default Routes;
