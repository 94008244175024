import React from "react";

import { Button as AntdButton } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";
import styled from "styled-components";

type AntdButtonProps = React.ComponentProps<typeof AntdButton>;

export enum Size {
  SM = "sm",
  MD = "md",
}

interface IButton {
  buttonSize?: Size;
  buttonColor?: string;
  activeColor?: string;
}

const StyledButton = styled<React.FC<IButton & AntdButtonProps>>(
  ({
    buttonSize,
    buttonColor,
    activeColor,
    ...rest
  }: IButton & AntdButtonProps) => <AntdButton {...rest} />
)`
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: ${(props) => (props.buttonSize === Size.SM ? 0 : 7)}px
    ${(props) => (props.buttonSize === Size.SM ? 7 : 16)}px;
  height: auto;
  font-size: 13px;
  background: ${(props) => props.buttonColor || "hsla(212, 25%, 16%, 1)"};
  border-color: ${(props) => props.buttonColor || "hsla(212, 25%, 16%, 1)"};
  color: white;
  width: fit-content;

  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.activeColor || "hsla(212, 25%, 30%, 1)"};
    border-color: ${(props) => props.activeColor || "hsla(212, 25%, 30%, 1)"};
    color: white;
  }
`;

export const Button = (props: IButton & AntdButtonProps & UIProps) => (
  <Box is={StyledButton} {...props} />
);

Button.Size = Size;

export default Button;
