import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import { UIProps } from "components/base/types";
import { Pane, Row } from "components/base/layout";
import { Tooltip } from "antd";

const StyledPersonIcon = styled(Pane)`
  box-sizing: content-box;
`;

export enum Size {
  S = "s",
  M = "m",
}

const SizeBreakdown: Record<Size, { width: number; fontSize: number }> = {
  [Size.S]: {
    width: 20,
    fontSize: 14,
  },
  [Size.M]: {
    width: 23,
    fontSize: 14,
  },
};

export const PersonIcon = ({
  name,
  email,
  size,
  active,
  noTooltip,
  ...props
}: {
  name: string;
  email?: string;
  size?: Size;
  active?: boolean;
  noTooltip?: boolean;
} & UIProps) => {
  const sizeBreakdown = SizeBreakdown[size || Size.M];

  const Container = ({ children }: { children?: React.ReactNode }) =>
    noTooltip ? (
      <>{children}</>
    ) : (
      <Tooltip
        color="black"
        title={
          <Pane>
            <Pane>{name}</Pane>
            {email && <Pane opacity={0.6}>{email}</Pane>}
          </Pane>
        }
      >
        <>{children}</>
      </Tooltip>
    );

  return (
    <Pane
      className={classNames("person-icon", active && "active")}
      width={sizeBreakdown.width}
      height={sizeBreakdown.width}
      borderRadius={30}
      position="relative"
      {...props}
    >
      <Container>
        <Pane
          backgroundColor="white"
          borderRadius="100%"
          boxShadow="rgba(15, 15, 15, 0.1) 0px 2px 4px"
        >
          <StyledPersonIcon
            border="1px solid currentcolor"
            borderRadius="100%"
            width={sizeBreakdown.width - 2}
            height={sizeBreakdown.width - 2}
            lineHeight={`${sizeBreakdown.width - 2}px`}
            overflow="hidden"
            textAlign="center"
            fontSize={sizeBreakdown.fontSize}
            background="white"
            userSelect="none"
            color="rgb(55, 53, 47)"
          >
            <Pane>{name[0].toUpperCase()}</Pane>
          </StyledPersonIcon>
        </Pane>
      </Container>
    </Pane>
  );
};

PersonIcon.Group = styled(Row)`
  .person-icon {
    opacity: 0.3;

    &.active {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: -5px;
    }
  }
`;

PersonIcon.Size = Size;

export default PersonIcon;
