import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";

export default class SlackChannelsResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly name: string | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("slack-integration");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      url() {
        return `${SlackChannelsResource.urlRoot}/channels`;
      },
      schema: { results: [this] },
    });
  }
}
