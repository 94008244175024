import React, { useState, useContext } from "react";
import SidebarExpandableSectionContext from "./SidebarExpandableSectionContext";
import SidebarEntityLinkRow from "./SidebarEntityLinkRow";

const SidebarExpandableSection = ({
  active,
  children,
  defaultOpen,
}: {
  active: boolean;
  children: React.ReactNode;
  defaultOpen?: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(
    defaultOpen || (defaultOpen !== false && active)
  );

  return (
    <SidebarExpandableSectionContext.Provider
      value={{ active, expanded, setExpanded }}
    >
      {children}
    </SidebarExpandableSectionContext.Provider>
  );
};

const SidebarExpandableList = ({ children }: { children: React.ReactNode }) => {
  const { expanded } = useContext(SidebarExpandableSectionContext);
  return <>{expanded ? children : null}</>;
};

SidebarExpandableSection.ExpandableList = SidebarExpandableList;
SidebarExpandableSection.LinkRow = SidebarEntityLinkRow;

export default SidebarExpandableSection;
