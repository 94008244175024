import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "resources/AuthenticatedResource";
import SchemasResource from "resources/schemas";
import EntitiesResource from "resources/entities";
import { buildApiUri } from "resources/api-uri";
import EntityType from "resources/entity-type";
import EntityResourceType from "resources/entity-resource-type";
import Description from "types/Description";
import { enforceCasing, paramsToString } from "utils";
import { ENTITY_CATALOG_ROOT_URL_PATH } from "constants/url";
import Icon from "./Icon";

export default class DatabasesResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly name: string = "";
  readonly description: Description = {
    descriptionBlocks: [
      {
        type: "paragraph",
        data: {
          text: "",
        },
      },
    ],
    descriptionHtml: "",
    descriptionText: "",
  };

  readonly schemas: SchemasResource[] = [];
  readonly status: string | undefined = undefined;
  readonly statistics: { schema_count: number; table_count: number } = {
    schema_count: 0,
    table_count: 0,
  };
  readonly sourceCreatedAt: Date | undefined = undefined;
  readonly sourceUpdatedAt: Date | undefined = undefined;
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;

  static entityType = EntityType.Database;
  readonly entityType = DatabasesResource.entityType;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("databases");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static detailByName<T extends typeof Resource>(this: T) {
    return this.detail().extend({
      schema: { results: [this] },

      url(urlParams: Readonly<Record<string, any>>) {
        return DatabasesResource.urlRoot + `?${paramsToString(urlParams)}`;
      },
    });
  }

  static buildCatalogUrlPath({ databaseName }: { databaseName: string }) {
    return `${ENTITY_CATALOG_ROOT_URL_PATH}/${enforceCasing(databaseName)}`;
  }

  static buildSlug({ databaseName }: { databaseName: string }) {
    return databaseName.toLowerCase();
  }

  static inEntities(entities?: EntitiesResource[]) {
    return EntitiesResource.databases(entities);
  }

  get breadcrumbs(): EntityResourceType[] {
    return [];
  }

  get catalogUrlPath() {
    return DatabasesResource.buildCatalogUrlPath({ databaseName: this.name });
  }

  get slug() {
    return this.name.toLowerCase();
  }

  static Icon = Icon;
  readonly Icon = DatabasesResource.Icon;
  readonly EntityClass = DatabasesResource;
}
