import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import SlackChannelsResource from "./slack-channels";
import { buildApiUri } from "./api-uri";

export type SlackChannel = { id: string; name: string };

export default class SlackIntegrationResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly organizationId: string | undefined = undefined;
  readonly properties:
    | { channel: SlackChannelsResource }
    | undefined = undefined;
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("slack-integration");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this] },
    });
  }

  static updateChannel<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      url({ id }) {
        return `${SlackIntegrationResource.urlRoot}/${id}/channel`;
      },
    });
  }
}

export const slackIntegrationAddToListAppender = (
  newSlackIntegrationId: string,
  slackIntegrationIds: { results: string[] } | undefined
) => ({
  ...slackIntegrationIds,
  results: [
    ...(slackIntegrationIds?.results ? slackIntegrationIds.results : []),
    newSlackIntegrationId,
  ],
});
