import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "utils/hooks";

import { pageForSegment, useTrackPageView } from "utils/analytics";

export const usePageViews = () => {
  const location = useLocation();
  const trackPageView = useTrackPageView();
  const previousLocation = usePrevious(location);
  const locationHasChanged = previousLocation !== location;

  useEffect(() => {
    if (locationHasChanged) {
      trackPageView({
        path: window.location.pathname,
        referrer: document.referrer,
        search: window.location.search,
        title: document.title,
        url: window.location.href,
      });
      pageForSegment("App Page", { location });
    }
  }, [locationHasChanged, trackPageView]);
};

export default usePageViews;
