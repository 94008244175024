import { Graph } from "@antv/g6";
import { TableNode } from "../types";

import initializeEdgeHoverListeners from "./initializeEdgeHoverListeners";
import initializeNodeHoverListeners from "./initializeNodeHoverListeners";
import initializeNodeClickListeners from "./initializeNodeClickListeners";
import { resetGraph } from "./utils";

export const turnOffListeners = (graph: Graph) => {
  graph.off("edge:mouseenter");
  graph.off("edge:mouseleave");
  graph.off("node:click");
  graph.off("node:mouseenter");
  graph.off("node:mouseleave");
};

export const turnOnListeners = (
  graph: Graph,
  onClickNode: (node: TableNode) => void,
  onClickHideNode?: (node: TableNode) => void
) => {
  resetGraph(graph);
  initializeEdgeHoverListeners(graph);
  initializeNodeHoverListeners(graph);
  initializeNodeClickListeners(graph, onClickNode, onClickHideNode);
};
