import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import { buildApiUri } from "resources/api-uri";
import { User } from "./user";
import { identifyUser, identifyUserForSegment } from "utils/analytics";
const AUTH0_DOMAIN = "auth.metaplane.dev";
const AUTH0_AUDIENCE = "https://api.metaplane.dev";

export const AuthContext = createContext({
  isAuthenticated: false,
  loginWithRedirect: () => {},
  logout: (logoutOptions?: { returnTo: string }) => {},
  error: "",
  user: {} as User,
  isLoading: true,
});
AuthContext.displayName = "AuthContext";

const DEFAULT_LOGIN_ERROR_MESSAGE =
  "Sorry, we had trouble logging you in. Can you please try again?";

function AuthProvider(props: any) {
  const {
    user: auth0User,
    error: auth0Error,
    isLoading: auth0IsLoading,
    isAuthenticated: auth0IsAuthenticated,
    logout: auth0Logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!auth0User) {
      return;
    }

    const upsertUser = async () => {
      try {
        setError(undefined);
        const accessToken = await getAccessTokenSilently({
          audience: AUTH0_AUDIENCE,
          scope: "openid profile email",
        });

        const upsertUserResult = await fetch(buildApiUri("authenticate"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            firstName: auth0User.given_name,
            lastName: auth0User.family_name,
            email: auth0User.email,
          }),
        });

        if (!upsertUserResult.ok) {
          setError(DEFAULT_LOGIN_ERROR_MESSAGE);
          return;
        }

        const metaplaneUser = await upsertUserResult.json();

        setUser(
          User.fromJS({
            accessToken,
            ...metaplaneUser,
          })
        );

        identifyUserForSegment(metaplaneUser.id, metaplaneUser);
        identifyUser(metaplaneUser.id, metaplaneUser);
      } catch (error) {
        setError(DEFAULT_LOGIN_ERROR_MESSAGE);
        console.error(error);
      }
    };

    upsertUser();
  }, [auth0User, getAccessTokenSilently]);

  const logout = useCallback(
    async (logoutOptions?: { returnTo: string }) => {
      setError(undefined);
      auth0Logout(logoutOptions);
    },
    [auth0Logout]
  );

  const login = useCallback(async () => {
    setError(undefined);
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        loginWithRedirect: login,
        error: auth0Error || error,
        isLoading: auth0IsLoading || (auth0IsAuthenticated && !user),
        isAuthenticated: auth0IsAuthenticated && user,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

const AuthWrapper = (props: { children?: any }) => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId="gUa1c80cAriyejIkDg4eJ76YHGAjvpn7"
    redirectUri={`${window.location.origin}/authenticate`}
    audience={AUTH0_AUDIENCE}
  >
    <AuthProvider>{props.children}</AuthProvider>
  </Auth0Provider>
);

export { AuthProvider, AuthWrapper, useAuth };
