import React from "react";
import { NetworkError } from "rest-hooks";
import { useAuth } from "authentication";
import { useTrackEvent } from "utils/analytics";

import { Icon } from "components/base";
import { Pane, Row } from "components/base/layout";

const NetworkErrorFallbackPage = (props: { error: NetworkError }) => {
  const { logout } = useAuth();
  const trackEvent = useTrackEvent();

  trackEvent("Network error", { error: props.error });

  if (props.error?.status === 401) {
    logout();
    return <></>;
  }

  return (
    <Row centerX grow paddingTop={120}>
      <Pane textAlign="center" opacity={0.8}>
        <Icon name="fa-construction" opacity={0.6} fontSize={124} />
        <Pane marginTop={50} fontSize={18}>
          Sorry, we had trouble with your last request. Could you please try
          again?
        </Pane>
      </Pane>
    </Row>
  );
};

export default NetworkErrorFallbackPage;
