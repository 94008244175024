import React, { useState } from "react";
import { useResource, useFetcher } from "rest-hooks";

import { TableNode } from "../types";
import TablesResource from "resources/tables";

import {
  getNumberAsShorthandString,
  getHumanReadableDatetime,
  getTimeFromNow,
} from "utils";

import { Stat, Input } from "components/base";
import { Pane, Row, Column } from "components/base/layout";
import { H2, Code } from "components/base/type";
import { UIProps } from "components/base/types";
import TableColumnsList from "components/TableColumnsList";

import { ProtectedEntityDescription } from "components/protected";

const EntityDetailBoxContents = ({
  focusedNode,
}: {
  focusedNode: TableNode;
}) => {
  const [
    {
      results: [table],
    },
  ] = useResource([
    TablesResource.detailByName(),
    {
      name: focusedNode.tableName,
      schemaName: focusedNode.schemaName,
      databaseName: focusedNode.databaseName,
    },
  ]);

  const updateTable = useFetcher(TablesResource.partialUpdate());

  const [filterQuery, setFilterQuery] = useState("");

  return (
    <Column maxHeight="100%">
      <Code
        truncate
        textTransform="uppercase"
        fontSize={11}
        opacity={0.5}
        color="#171E26"
        flexShrink={0}
      >
        {focusedNode.databaseName}.{focusedNode.schemaName}.
        {focusedNode.tableName}
      </Code>
      <Row flexShrink={0}>
        <H2 truncate marginBottom={10}>
          {focusedNode.tableName.toLowerCase()}
        </H2>
        <Code
          textTransform="uppercase"
          marginLeft={7}
          marginTop={6}
          fontSize={11}
          opacity={0.5}
          color="#171E26"
        >
          {table.type}
        </Code>
      </Row>
      <Pane flexShrink={0} minHeight={38}>
        <ProtectedEntityDescription
          description={table.description}
          onChange={(description) => {
            updateTable({ id: table.id }, { description });
          }}
        />
      </Pane>

      <Section flexShrink={0}>
        <Row>
          <Stat name="Cols" title={table.statistics.col_count || 0}>
            {getNumberAsShorthandString(table.statistics.col_count || 0)}
          </Stat>
          {table.isTable && (
            <Stat name="Rows" title={table.statistics.row_count || 0}>
              {getNumberAsShorthandString(table.statistics.row_count || 0)}
            </Stat>
          )}
          <Stat
            name="Created"
            title={getHumanReadableDatetime(table.sourceCreatedAt)}
          >
            {getTimeFromNow(table.sourceUpdatedAt)}
          </Stat>
          <Stat
            name="Altered"
            title={getHumanReadableDatetime(table.sourceUpdatedAt)}
          >
            {getTimeFromNow(table.sourceUpdatedAt)}
          </Stat>
        </Row>
      </Section>

      <Section
        marginLeft={-20}
        paddingLeft={4}
        marginBottom={-6}
        paddingTop={6}
      >
        <Input
          placeholder="Filter columns..."
          className="graph-search-column-filter"
          onChange={(e) => setFilterQuery(e.currentTarget.value)}
        />
      </Section>

      <Section overflow="scroll">
        <TableColumnsList tableId={focusedNode.id} filterQuery={filterQuery} />
      </Section>
    </Column>
  );
};

const Section = (props: UIProps) => (
  <Pane
    marginTop={12}
    paddingTop={12}
    marginX={-20}
    paddingX={20}
    borderTop="1px solid whitesmoke"
    {...props}
  />
);

export default EntityDetailBoxContents;
