import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useResource } from "rest-hooks";

import { enforceCasing } from "utils";
import { Pane } from "components/base/layout";
import EntitiesResource from "resources/entities";
import DatabasesResource from "resources/databases";
import SidebarDatabasesList from "./SidebarDatabasesList";
import ImportantSidebar from "./ImportantSidebar";
import { withLoader } from "components/base";

const SidebarContents = () => {
  const { results: importantEntities } = useResource(
    EntitiesResource.listImportant(),
    {}
  );

  const importantDatabases = DatabasesResource.inEntities(importantEntities);

  const { results: allDatabases } = useResource(DatabasesResource.list(), {});

  const { databaseName: selectedDatabaseName } = useParams<{
    databaseName?: string;
  }>();

  if (!allDatabases.length) {
    return <Redirect to="/settings/connections" />;
  }

  const selectedDatabase =
    allDatabases.length && selectedDatabaseName
      ? allDatabases.find(
          (database) =>
            database.name.toLowerCase() === selectedDatabaseName.toLowerCase()
        )
      : undefined;

  if (!selectedDatabase) {
    const defaultDatabase = importantDatabases?.length
      ? importantDatabases[0]
      : allDatabases[0];
    return <Redirect to={`/c/${enforceCasing(defaultDatabase.name)}`} />;
  }

  return (
    <>
      <ImportantSidebar
        importantEntities={importantEntities}
        selectedDatabase={selectedDatabase}
      />
      <Pane
        textTransform="uppercase"
        marginBottom={6}
        marginLeft={21}
        fontWeight={600}
        fontSize={11.5}
        letterSpacing=".03em"
        color="rgba(55, 53, 47, 0.45)"
      >
        Reference
      </Pane>
      <SidebarDatabasesList
        databases={allDatabases}
        selectedDatabase={selectedDatabase}
      />
    </>
  );
};

export default withLoader(SidebarContents, {}, () => <></>);
