import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "./AuthenticatedResource";
import { buildApiUri } from "./api-uri";
import TablesResource from "./tables";

export default class LineageResource extends AuthenticatedResource {
  readonly id: string | undefined = undefined;
  readonly type: string = "";
  readonly sourceTable: TablesResource = new TablesResource();
  readonly targetTable: TablesResource = new TablesResource();
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("lineage");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({
      schema: { results: [this] },
    });
  }
}
