import React, { useEffect, useState } from "react";
import { Dropdown, Menu } from "antd";
import { Spinner } from "evergreen-ui";
import { DropDownProps as AntdDropdownProps } from "antd/es/dropdown";
import { useDebouncedCallback } from "use-debounce";

import { Pane, Row, Span } from "components/base/layout";
import Button from "components/base/Button";
import Input from "components/base/Input";
import Link from "components/base/Link";
import ContentEditable, {
  IContentEditableRef,
} from "components/base/ContentEditable";

import SelectContext from "./SelectContext";
import SelectMenuItem from "./SelectMenuItem";

import { usePrevious } from "utils/hooks";

type SelectProps = {
  value: any;
  onChange: (selectedValues: any, valueChanged: any) => void;
  children: React.ReactNode;
  menuItems: React.ReactElement[];
  title?: string;
  itemName?: string;
  itemCreationInputContainer?: React.FC;
  itemCreationInputPlaceholder?: string;
  onCreateItem?: (newItem: string) => void;
  searchPlaceholder?: string;
  onSearch?: (input: string) => void;
  persistent?: boolean;
  minWidth?: number;
  showStatusBar?: boolean;
  disabled?: boolean;
} & Partial<AntdDropdownProps>;

export const Select = ({
  value,
  onChange,
  menuItems,
  onSearch,
  title,
  itemName,
  children,
  itemCreationInputContainer,
  itemCreationInputPlaceholder,
  onCreateItem,
  searchPlaceholder,
  persistent,
  minWidth,
  showStatusBar,
  disabled,
  ...dropdownProps
}: SelectProps) => {
  const creationInputRef = React.useRef<IContentEditableRef>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const prevValue = usePrevious(value);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const prevIsDropdownVisible = usePrevious(isDropdownVisible);
  const [selectedValue, setSelectedValue] = useState(value);
  const prevSelectedValue = usePrevious(selectedValue);
  const isMultiSelect = Array.isArray(value);

  useEffect(() => {
    if (
      prevValue !== value &&
      JSON.stringify(prevValue) !== JSON.stringify(value)
    ) {
      setSelectedValue(value);
    }
  }, [value, setSelectedValue, prevValue]);

  useEffect(() => {
    setIsSearching(false);
  }, [menuItems, setIsSearching]);

  const hasSelectedValueChanged = prevSelectedValue !== selectedValue;
  useEffect(() => {
    if (
      hasSelectedValueChanged &&
      isDropdownVisible &&
      isDropdownVisible === prevIsDropdownVisible
    ) {
      setIsUpdating(true);
    }
  }, [
    isDropdownVisible,
    prevIsDropdownVisible,
    hasSelectedValueChanged,
    setIsUpdating,
  ]);

  useEffect(() => {
    const hasValueChanged =
      prevValue !== value &&
      JSON.stringify(prevValue) !== JSON.stringify(value);
    const hasDropdownVisibleChanged =
      isDropdownVisible !== prevIsDropdownVisible;
    if (hasValueChanged && !hasDropdownVisibleChanged && isDropdownVisible) {
      setIsUpdating(false);
      if (!persistent) {
        setIsDropdownVisible(false);
      }
    }
  }, [
    value,
    prevValue,
    isDropdownVisible,
    prevIsDropdownVisible,
    setIsUpdating,
    setIsDropdownVisible,
    persistent,
  ]);

  const toggleSelectedValue = (valueToToggle: any) => {
    let newSelectedValue;
    if (isMultiSelect) {
      if (selectedValue.includes(valueToToggle)) {
        newSelectedValue = selectedValue.filter(
          (value: any) => value !== valueToToggle
        );
      } else {
        newSelectedValue = [...selectedValue, valueToToggle];
      }
    } else {
      newSelectedValue = valueToToggle;
    }

    setSelectedValue(newSelectedValue);
    onChange(newSelectedValue, valueToToggle);
  };

  const clearAll = () => {
    setSelectedValue([]);
    onChange([], null);
  };

  const selectAll = () => {
    const allValues = menuItems.map((item) => item.props.value);
    setSelectedValue(allValues);
    onChange(allValues, null);
  };

  const createItemAndSetUpdateStatus = (newItem: string) => {
    setIsUpdating(true);
    onCreateItem && onCreateItem(newItem.replace("&nbsp;", " ").trim());
  };

  const CreationInputContainer = itemCreationInputContainer || Pane;

  const onSearchInput = async (input: string) => {
    if (onSearch) {
      onSearch(input);
    }
  };

  const debouncedOnSearchInput = useDebouncedCallback(onSearchInput, 750);

  return (
    <SelectContext.Provider
      value={{
        isMultiSelect,
        selectedValue,
        toggleSelectedValue,
      }}
    >
      <Dropdown
        disabled={disabled || false}
        visible={isDropdownVisible}
        onVisibleChange={setIsDropdownVisible}
        overlay={
          <Menu
            style={{
              maxHeight: 300,
              overflowY: "scroll",
              position: "static",
              minWidth: minWidth || (onSearch ? 210 : 150),
              paddingBottom: showStatusBar ? 32 : 4,
              minHeight: 39,
            }}
          >
            {isUpdating && (
              <div>
                <Pane
                  pointerEvents="all"
                  position="absolute"
                  zIndex={1000}
                  left={0}
                  top={0}
                  bottom={0}
                  right={0}
                  backgroundColor="rgba(0,0,0,0.2)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner />
                </Pane>
              </div>
            )}

            {title && (
              <div>
                <Row
                  marginTop={6}
                  marginBottom={8}
                  paddingX={12}
                  color="rgba(55, 53, 47, 0.6)"
                  fontSize={12}
                  lineHeight="120%"
                  userSelect="none"
                >
                  <Span>{title}</Span>
                </Row>
              </div>
            )}
            {onSearch && (
              <div className="search-box">
                <Pane
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  zIndex={1}
                  borderRadius={2}
                  overflow="hidden"
                >
                  <Input.Search
                    focus
                    loading={isSearching}
                    fontFamily="Fira Code"
                    placeholder={searchPlaceholder || "Search..."}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!e.currentTarget.value) {
                        onSearch(e.currentTarget.value);
                      } else {
                        setIsSearching(true);
                        debouncedOnSearchInput.callback(e.currentTarget.value);
                      }
                    }}
                  />
                </Pane>
              </div>
            )}

            {menuItems}

            {onCreateItem && (
              <>
                {menuItems && (
                  <div>
                    <Pane
                      width="100%"
                      marginY={12}
                      borderBottom="1px solid rgba(0, 0, 0, 0.06)"
                    />
                  </div>
                )}
                <div
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <Row paddingY={5} paddingX={16}>
                    <CreationInputContainer>
                      <ContentEditable
                        innerRef={creationInputRef}
                        minEmptyWidth={30}
                        onPressEnter={createItemAndSetUpdateStatus}
                        data-placeholder={
                          itemCreationInputPlaceholder || "Create an item"
                        }
                      />
                    </CreationInputContainer>

                    <Button
                      type="primary"
                      onClick={() => {
                        if (creationInputRef.current?.text) {
                          createItemAndSetUpdateStatus(
                            creationInputRef.current.text
                          );
                        }
                      }}
                      marginLeft="auto"
                      buttonSize={Button.Size.SM}
                    >
                      +
                    </Button>
                  </Row>
                </div>
              </>
            )}
            {showStatusBar && (
              <div>
                <Pane
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  paddingY={8}
                  paddingX={16}
                  color="rgba(55, 53, 47, 0.5)"
                  fontSize={12}
                  lineHeight="120%"
                  userSelect="none"
                  backgroundColor="white"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <Row>
                    <Span marginRight={16}>
                      {isMultiSelect &&
                        Array.isArray(value) &&
                        `Selected ${value.length} of `}
                      {menuItems.length} {itemName || "items"}
                    </Span>

                    {isMultiSelect && (
                      <>
                        <Link
                          marginLeft="auto"
                          onClick={() => selectAll()}
                          disabled={menuItems.length === selectedValue.length}
                        >
                          All
                        </Link>
                        <Link
                          marginLeft={8}
                          onClick={() => clearAll()}
                          disabled={selectedValue.length === 0}
                        >
                          None
                        </Link>
                      </>
                    )}
                  </Row>
                </Pane>
              </div>
            )}
          </Menu>
        }
        trigger={["click"]}
      >
        {children}
      </Dropdown>
    </SelectContext.Provider>
  );
};

Select.MenuItem = SelectMenuItem;

export default Select;
