import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withErrorBoundary } from "react-error-boundary";
import { useLocalStorage } from "@rehooks/local-storage";

import { Graph } from "@antv/g6";
import {
  TableNode,
  StrictGraphData,
  GraphControlsType,
} from "components/SharedGraph/types";

import TablesResource from "resources/tables";
import RelationshipsResource from "resources/relationships";

import buildGraphData from "./buildGraphData";
import buildGraphConfig, {
  getComboLayout,
  getForceLayout,
} from "./buildGraphConfig";
import filterGraphData from "./filterGraphData";
import groupGraphDataBySchema from "./groupGraphDataBySchema";
import withDynamicSizeGraphData from "./withDynamicSizeGraphData";

import { Row } from "components/base/layout";
import GraphRenderer from "components/SharedGraph/GraphRenderer";
import EntityDetailBox from "components/SharedGraph/EntityDetailBox";
import GraphControls from "./GraphControls";
import {
  LINK_DISTANCE,
  DEFAULT_CONTROLS,
} from "components/SharedGraph/constants";
import {
  NETWORK_GRAPH_HIDDEN_SCHEMA_IDS_STORAGE_KEY,
  NETWORK_GRAPH_HIDDEN_TABLE_IDS_STORAGE_KEY,
} from "constants/storageKeys";
import { usePrevious } from "utils/hooks";

import {
  turnOnListeners,
  turnOffListeners,
} from "components/SharedGraph/listeners";

import GraphContext from "components/SharedGraph/GraphContext";
import { calculateDegreeCentrality } from "components/SharedGraph/centrality";

const NetworkGraphController = ({
  relationships,
  tableName,
  schemaName,
  databaseName,
  defaultControls,
  hideGraphControls,
  hideDetailBox,
  hideMiniMap,
  onClickNodeCallback,
  layout,
}: {
  relationships: RelationshipsResource[];
  tableName?: string;
  schemaName?: string;
  databaseName?: string;
  defaultControls?: GraphControlsType;
  hideGraphControls?: boolean;
  hideDetailBox?: boolean;
  hideMiniMap?: boolean;
  onClickNodeCallback?: ({
    id,
    tableName,
    schemaName,
    databaseName,
  }: {
    id: string;
    tableName: string;
    schemaName: string;
    databaseName: string;
  }) => void;
  layout?: string;
}) => {
  const history = useHistory();
  const [graph, setGraph] = useState<Graph | null>(null);
  const [controls, setControls] = useState(defaultControls || DEFAULT_CONTROLS);
  const [hiddenSchemaIds, setHiddenSchemaIds] = useLocalStorage<string[]>(
    NETWORK_GRAPH_HIDDEN_SCHEMA_IDS_STORAGE_KEY,
    []
  );
  const [hiddenTableIds, setHiddenTableIds] = useLocalStorage<string[]>(
    NETWORK_GRAPH_HIDDEN_TABLE_IDS_STORAGE_KEY,
    []
  );

  const allGraphData = React.useMemo(() => buildGraphData(relationships), [
    relationships,
  ]);

  const centralities = React.useMemo(
    () => calculateDegreeCentrality(allGraphData),
    [allGraphData]
  );

  const [currentGraphData, setCurrentGraphData] = useState<StrictGraphData>();

  const focusedNode = React.useMemo(
    () =>
      allGraphData.nodes.find(
        (node) =>
          node.tableName.toLowerCase() === tableName &&
          node.schemaName.toLowerCase() === schemaName &&
          node.databaseName.toLowerCase() === databaseName
      ),
    [allGraphData, tableName, schemaName, databaseName]
  );
  const focusedNodeId = focusedNode ? focusedNode.id : "";

  const previousOnlyCentralTables = usePrevious(controls.onlyCentralTables);
  const didOnlyCentralTablesChange =
    previousOnlyCentralTables !== controls.onlyCentralTables &&
    previousOnlyCentralTables !== null;

  const previousGroupBySchema = usePrevious(controls.groupBySchema);
  const didGroupBySchemaChange =
    previousGroupBySchema !== controls.groupBySchema &&
    previousGroupBySchema !== null;

  const previousControls = usePrevious(controls);
  const didControlsChange =
    controls !== previousControls && previousControls !== null;

  const defaultOnClickNode = React.useCallback(
    (clickedNode: TableNode) => {
      const { tableName, schemaName, databaseName, id } = clickedNode;
      history.push(
        id === focusedNodeId
          ? "/relationships"
          : TablesResource.buildGraphUrlPath({
              tableName,
              schemaName,
              databaseName,
            })
      );
    },
    [history, focusedNodeId]
  );

  const onClickNode = onClickNodeCallback || defaultOnClickNode;

  const onClickHideNode = React.useCallback(
    (clickedNode: TableNode) => {
      setHiddenTableIds([...hiddenTableIds, clickedNode.id]);
    },
    [setHiddenTableIds, hiddenTableIds]
  );

  useEffect(() => {
    if (graph && didGroupBySchemaChange) {
      if (controls.groupBySchema) {
        graph.clear();
        graph.updateLayout(getComboLayout(LINK_DISTANCE));
      } else {
        graph.updateLayout(getForceLayout(LINK_DISTANCE));
      }
    }
  }, [controls.groupBySchema, didGroupBySchemaChange, graph]);

  const [shouldUpdateGraph, setShouldUpdateGraph] = useState(false);
  useEffect(() => {
    setShouldUpdateGraph(true);
  }, [focusedNodeId, controls, allGraphData]);

  useEffect(() => {
    if (graph && shouldUpdateGraph) {
      const {
        groupBySchema,
        includeSecondDegreeConnections,
        isNodeSizeDynamic,
        onlyCentralTables,
      } = controls;
      const filteredData = filterGraphData(
        allGraphData,
        focusedNodeId,
        includeSecondDegreeConnections,
        onlyCentralTables,
        centralities
      );
      setCurrentGraphData(filteredData);

      const groupedBySchemaData = groupGraphDataBySchema(
        filteredData,
        groupBySchema
      );

      const withDynamicSizeData = withDynamicSizeGraphData(
        groupedBySchemaData,
        isNodeSizeDynamic
      );

      setShouldUpdateGraph(false);
      turnOffListeners(graph);

      graph.read(withDynamicSizeData);
      turnOnListeners(graph, onClickNode, onClickHideNode);
    }
  }, [
    graph,
    allGraphData,
    controls,
    didControlsChange,
    focusedNodeId,
    didGroupBySchemaChange,
    didOnlyCentralTablesChange,
    centralities,
    shouldUpdateGraph,
    onClickNode,
    onClickHideNode,
  ]);

  useEffect(() => {
    if (graph && !shouldUpdateGraph) {
      graph
        .getCombos()
        .filter((combo) => !combo.destroyed)
        .forEach((combo) => {
          const comboId = combo.getID();

          if (!combo.isVisible() && !hiddenSchemaIds.includes(comboId)) {
            graph.showItem(comboId);
          }
          if (combo.isVisible() && hiddenSchemaIds.includes(comboId)) {
            graph.hideItem(comboId);
          }
        });

      graph
        .getNodes()
        .filter((node) => !node.destroyed)
        .forEach((node) => {
          const nodeId = node.getID();
          const schemaId = (node.getModel() as TableNode).schemaId;

          if (
            !node.isVisible() &&
            !hiddenTableIds.includes(nodeId) &&
            !hiddenSchemaIds.includes(schemaId)
          ) {
            graph.showItem(nodeId);
          }
          if (
            node.isVisible() &&
            (hiddenTableIds.includes(nodeId) ||
              hiddenSchemaIds.includes(schemaId))
          ) {
            graph.hideItem(nodeId);
          }
        });
    }
  }, [graph, hiddenTableIds, hiddenSchemaIds, shouldUpdateGraph]);

  return (
    <GraphContext.Provider
      value={{
        isGraphActive: true,
      }}
    >
      <Row flexGrow={1} position="relative" width="100%">
        <GraphRenderer
          graphHasData={allGraphData.nodes.length > 0}
          graphData={allGraphData}
          setGraph={setGraph}
          graph={graph}
          buildGraphConfig={buildGraphConfig}
          hideMiniMap={hideMiniMap}
          layout={layout}
        />

        {!hideGraphControls && (
          <GraphControls
            currentGraphData={currentGraphData}
            controls={controls}
            setControls={setControls}
            hiddenSchemaIds={hiddenSchemaIds}
            setHiddenSchemaIds={setHiddenSchemaIds}
            hiddenTableIds={hiddenTableIds}
            setHiddenTableIds={setHiddenTableIds}
            focusedNode={focusedNode}
          />
        )}
        {!hideDetailBox && focusedNode && (
          <EntityDetailBox focusedNode={focusedNode} />
        )}
      </Row>
    </GraphContext.Provider>
  );
};

const FallbackComponent = () => (
  <Row center>Error displaying graph, please refresh.</Row>
);

export default withErrorBoundary(NetworkGraphController, {
  FallbackComponent,
  onError(error, info) {
    console.log("error", error, info);
  },
});
