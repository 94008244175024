import React from "react";

import { Tag as AntdTag } from "antd";
import styled from "styled-components";
import Box from "ui-box";
import { UIProps } from "components/base/types";

interface ITag {
  color?: string;
}

const StyledTag = styled(AntdTag)`
  &.ant-tag {
    font-weight: 600;
    opacity: 0.8;
    background: white;
    line-height: 22px;
    cursor: inherit;
    margin-right: 6px;
  }
`;

export const Tag = ({ color, children, ...props }: UIProps & ITag) => (
  <Box display="flex" alignItems="center" {...props}>
    <StyledTag color={color || ""}>{children}</StyledTag>
  </Box>
);

export default Tag;
