import { useLocalStorage } from "@rehooks/local-storage";
import moment from "moment";
import { FIRST_SESSION_DATE_STORAGE_KEY } from "constants/storageKeys";

export const useIsOnboarding = () => {
  const [firstSessionDate, setFirstSessionDate] = useLocalStorage(
    FIRST_SESSION_DATE_STORAGE_KEY
  );

  if (!firstSessionDate) {
    setFirstSessionDate(Date.now().toString());
  }

  const isOnboarding =
    !firstSessionDate || moment().diff(moment(firstSessionDate), "days") < 4;

  return isOnboarding;
};
