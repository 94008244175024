import React from "react";

import { useAuth } from "authentication";
import { Pane } from "components/base/layout";
import { Image } from "components/base";

const MetaplaneLogoMark = () => (
  <Pane marginTop={5.5} marginLeft={5.5}>
    <Pane
      borderTop="2.5px solid #171E26"
      borderLeft="2.5px solid #171E26"
      width={20}
      height={20}
      position="absolute"
      marginLeft={-5.5}
      marginTop={-5.5}
    ></Pane>
    <Pane border="2.5px solid #171E26" width={20} height={20}></Pane>
  </Pane>
);

enum OrganizationDomains {
  Ginkgo = "ginkgobioworks.com",
}

const OrganizationsWithCustomLogos: Set<string> = new Set([
  OrganizationDomains.Ginkgo,
]);

const LogoMark = () => {
  const { user } = useAuth();

  if (OrganizationsWithCustomLogos.has(user.organization?.domain || "")) {
    return (
      <Image
        src={`https://logo.clearbit.com/${user.organization?.domain}`}
        maxWidth={42}
        marginLeft={-6}
        marginRight={-10}
      />
    );
  }

  return <MetaplaneLogoMark />;
};

export default LogoMark;
