import React from "react";

import { TableNode } from "../types";

import { DefaultLoader } from "components/base/withLoader";
import { Column, Row } from "components/base/layout";
import { H2, Code } from "components/base/type";

const EntityDetailBoxContentsPlaceholder = ({
  focusedNode,
}: {
  focusedNode: TableNode;
}) => (
  <Column maxHeight="100%">
    <Code
      truncate
      textTransform="uppercase"
      fontSize={11}
      opacity={0.5}
      color="#171E26"
    >
      {focusedNode.databaseName}.{focusedNode.schemaName}.
      {focusedNode.tableName}
    </Code>
    <Row>
      <H2 truncate marginBottom={10}>
        {focusedNode.tableName.toLowerCase()}
      </H2>
    </Row>
    <DefaultLoader minHeight={100} />
  </Column>
);

export default EntityDetailBoxContentsPlaceholder;
