import { buildApiUri } from "resources/api-uri";
import { useAuth } from "authentication";

export const useTrackPageView = () => {
  const { user } = useAuth();
  return (properties: {
    path: string;
    referrer: string;
    search: string;
    title: string;
    url: string;
  }) =>
    fetch(buildApiUri("track/page-view"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        organizationId: user.organization.id,
        properties,
      }),
    });
};
