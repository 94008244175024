export enum ConnectionType {
  BigQuery = "big_query",
  Dbt = "dbt",
  Looker = "looker",
  Mode = "mode",
  PostgreSQL = "postgresql",
  MySQL = "mysql",
  Redash = "redash",
  Redshift = "redshift",
  Salesforce = "salesforce",
  Snowflake = "snowflake",
  Tableau = "tableau",
  None = "",
  Slack = "slack",
}

export interface Connection {
  name: string;
  type: ConnectionType;
  logoFileName: string;
  disabled?: boolean;
  maxWidth?: number;
  integrationCardRenderer?: (props: {
    source: Connection;
  }) => React.ReactElement;
}
