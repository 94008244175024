import React from "react";

import { useResource } from "rest-hooks";

import ColumnsResource from "resources/columns";

import { withLoader } from "components/base";
import ColumnRow from "./ColumnRow";

const TableColumnsList = ({
  tableId,
  filterQuery,
}: {
  tableId: string;
  filterQuery?: string;
}) => {
  const { results: columns } = useResource(ColumnsResource.list(), {
    tableId,
    take: 500,
  });
  const sortedFilteredColumns = [...columns].sort(
    (colA, colB) =>
      colA.statistics.ordinal_position - colB.statistics.ordinal_position
  );

  return (
    <>
      {sortedFilteredColumns.map((column: ColumnsResource) =>
        !filterQuery || column.name.toLowerCase().includes(filterQuery) ? (
          <ColumnRow key={column.id} column={column} />
        ) : (
          <></>
        )
      )}
    </>
  );
};

export default withLoader(TableColumnsList, { minHeight: 100 });
