import { User } from "authentication/user";
import UsersResource from "resources/users";

const METAPLANE_SUPERADMIN_ORGANIZATION_IDS = [
  "3fa9e820-e88f-42f2-b12b-08f36ca8bd45",
  "12f4cff1-6ba4-417d-8e21-de85fe761a4e",
];
const METAPLANE_SUPERADMIN_EMAIL_DOMAINS = ["@metaplane.dev", "@quantif.ai"];

export function filterOutMetaplaneUsers({
  loggedInUser,
  organizationsUsers,
}: {
  loggedInUser: User;
  organizationsUsers: UsersResource[];
}) {
  return METAPLANE_SUPERADMIN_ORGANIZATION_IDS.includes(
    loggedInUser.organization?.id || ""
  )
    ? organizationsUsers
    : organizationsUsers.filter(
        (user) =>
          !METAPLANE_SUPERADMIN_EMAIL_DOMAINS.some((domain) =>
            user.email.includes(domain)
          )
      );
}
