import React, { MouseEvent, useContext } from "react";

import { useTrackWithEntity } from "utils/analytics";
import { TagEntityType } from "resources/tag-entity-type";

import { UIProps } from "components/base/types";
import { MiniIconButton, Select, Tag } from "components/base";
import { Row } from "components/base/layout";
import EntityTagsContext from "./EntityTagsContext";

const AddTagButton = ({
  tagEntityType,
  addTagToEntity,
  removeTagFromEntity,
  createAndAddTagToEntity,
  iconOnly,
  ...props
}: {
  tagEntityType: TagEntityType;
  addTagToEntity: (tagId: string) => void;
  removeTagFromEntity: (tagId: string) => void;
  createAndAddTagToEntity: (newTagName: string) => void;
  iconOnly?: boolean;
} & UIProps) => {
  const { availableTags, entitysTagIds } = useContext(EntityTagsContext);
  const trackWithEntity = useTrackWithEntity();

  return (
    <Row
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        trackWithEntity("Clicked add tag button");
      }}
      {...props}
    >
      <Select
        value={entitysTagIds}
        onChange={(selectedValues: any, valueChanged: any) => {
          if (entitysTagIds.includes(valueChanged)) {
            removeTagFromEntity(valueChanged);
          } else {
            addTagToEntity(valueChanged);
          }
        }}
        menuItems={availableTags
          .filter((tag) => tag.type === tagEntityType)
          .map((tag) => (
            <Select.MenuItem key={tag.id} value={tag.id}>
              <Tag>{tag.name}</Tag>
            </Select.MenuItem>
          ))}
        onCreateItem={createAndAddTagToEntity}
        itemCreationInputPlaceholder="Create tag"
        itemCreationInputContainer={Tag}
      >
        <MiniIconButton iconType="far" iconName="fa-tag" title="Add a tag">
          {!iconOnly && "Tags"}
        </MiniIconButton>
      </Select>
    </Row>
  );
};

export default AddTagButton;
