import { Resource } from "@rest-hooks/rest";

import AuthenticatedResource from "resources/AuthenticatedResource";
import DatabasesResource from "resources/databases";
import TablesResource from "resources/tables";
import { buildApiUri } from "resources/api-uri";
import EntityType from "resources/entity-type";
import EntitiesResource from "resources/entities";
import EntityResourceType from "resources/entity-resource-type";
import DocumentationRequest from "resources/documentation-request";

import { enforceCasing, paramsToString } from "utils";
import CustomField from "types/CustomField";
import Description from "types/Description";
import { ENTITY_CATALOG_ROOT_URL_PATH } from "constants/url";
import Icon from "./Icon";

export default class SchemasResource extends AuthenticatedResource {
  readonly id: string = "";
  readonly name: string = "";
  readonly hidden: boolean = false;
  readonly isImportant: boolean = false;
  readonly description: Description = {
    descriptionBlocks: [
      {
        type: "paragraph",
        data: {
          text: "",
        },
      },
    ],
    descriptionHtml: "",
    descriptionText: "",
  };

  readonly tables: TablesResource[] = [];
  readonly status: string | undefined = undefined;
  readonly database: {
    name: string;
    id: string;
  } = { name: "", id: "" };
  readonly customFields: CustomField[] = [];
  readonly statistics: {
    table_count: number;
  } = {
    table_count: 0,
  };

  readonly sourceCreatedAt: Date | undefined = undefined;
  readonly sourceUpdatedAt: Date | undefined = undefined;
  readonly createdAt: Date | undefined = undefined;
  readonly updatedAt: Date | undefined = undefined;
  static entityType = EntityType.Schema;
  readonly entityType = SchemasResource.entityType;

  readonly documentationRequests: DocumentationRequest[] = [];

  pk() {
    return this.id?.toString();
  }

  static urlRoot = buildApiUri("schemas");

  static list<T extends typeof Resource>(this: T) {
    return super.list().extend({ schema: { results: [this] } });
  }

  static detailByName<T extends typeof Resource>(this: T) {
    return this.detail().extend({
      schema: { results: [this] },

      url(urlParams: Readonly<Record<string, any>>) {
        return SchemasResource.urlRoot + `?${paramsToString(urlParams)}`;
      },
    });
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      optimisticUpdate: (params: any, body: any) => ({
        id: params.id,
        ...body,
      }),
    });
  }

  static requestDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      method: "POST",
      url({ id }) {
        return `${SchemasResource.urlRoot}/${id}/documentation-requests`;
      },
    });
  }

  static resolveDocumentation<T extends typeof Resource>(this: T) {
    const endpoint = super.partialUpdate();
    return endpoint.extend({
      url({ id }) {
        return `${SchemasResource.urlRoot}/${id}/documentation-requests/resolve`;
      },
    });
  }

  static buildCatalogUrlPath({
    schemaName,
    databaseName,
  }: {
    schemaName: string;
    databaseName: string;
  }) {
    return `${ENTITY_CATALOG_ROOT_URL_PATH}/${enforceCasing(
      databaseName
    )}/${enforceCasing(schemaName)}`;
  }

  static buildSlug({
    schemaName,
    databaseName,
  }: {
    schemaName: string;
    databaseName: string;
  }) {
    return (
      DatabasesResource.buildSlug({ databaseName }) +
      `.${schemaName.toLowerCase()}`
    );
  }

  static inEntities(entities?: EntitiesResource[]) {
    return EntitiesResource.schemas(entities);
  }

  get databaseResource() {
    return DatabasesResource.fromJS(this.database);
  }

  get catalogUrlPath() {
    return SchemasResource.buildCatalogUrlPath({
      databaseName: this.database.name,
      schemaName: this.name,
    });
  }

  get slug() {
    return this.databaseResource.slug + `.${this.name.toLowerCase()}`;
  }

  get breadcrumbs(): EntityResourceType[] {
    return [this.databaseResource];
  }

  hasEqualSlug({
    schemaName,
    databaseName,
  }: {
    schemaName?: string;
    databaseName?: string;
  }) {
    return (
      (schemaName &&
        databaseName &&
        this.name.toLowerCase() === schemaName.toLowerCase() &&
        this.database.name.toLowerCase() === databaseName.toLowerCase()) ||
      false
    );
  }

  static Icon = Icon;
  readonly Icon = SchemasResource.Icon;
  readonly EntityClass = SchemasResource;
}
