import React from "react";
import { UIProps } from "components/base/types";
import { Pane } from "components/base/layout";

export const OverlayPanel = (props: UIProps) => (
  <Pane
    padding={12}
    borderRadius={3}
    boxShadow="rgba(15, 15, 15, 0.037) 0px 0px 0px 1px, rgba(15, 15, 15, 0.05) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
    backgroundColor="rgba(255,255,255,0.95)"
    pointerEvents="all"
    overflow="hidden"
    maxHeight="100%"
    maxWidth="100%"
    {...props}
  />
);

OverlayPanel.Positioner = (props: UIProps) => (
  <Pane position="absolute" pointerEvents="none" {...props} />
);

export default OverlayPanel;
