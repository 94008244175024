import React, { useState } from "react";
import { useFetcher, useResource } from "rest-hooks";

import SlackIntegrationResource from "resources/slack-integration";
import SlackChannelsResource from "resources/slack-channels";

import { Switch } from "antd";
import { toaster } from "evergreen-ui";
import { Icon, MiniButton, Select } from "components/base";
import { Pane, Row } from "components/base/layout";

const SlackConnectionCard = ({
  slackIntegration,
}: {
  slackIntegration: SlackIntegrationResource;
}) => {
  const { results: channels } = useResource(SlackChannelsResource.list(), {});
  const updateSlackIntegration = useFetcher(
    SlackIntegrationResource.updateChannel()
  );

  return (
    <Pane
      paddingTop={22}
      paddingX={22}
      paddingBottom={14}
      border="1px solid hsla(210, 25%, 90%, 1)"
      borderRadius={6}
      width={350}
      marginRight={24}
      marginBottom={24}
    >
      <Row>
        Slack
        <Row marginLeft="auto" marginTop={2} paddingLeft={8}>
          <Switch
            checkedChildren={<Icon name="fa-check" />}
            checked={!!slackIntegration.properties?.channel}
            disabled
          />
        </Row>
      </Row>

      <Pane marginTop={22}>
        <SelectSlackChannelDropdown
          selectedChannel={slackIntegration.properties?.channel}
          channels={channels}
          onSelectChannel={async (channel: SlackChannelsResource) => {
            await updateSlackIntegration(
              { id: slackIntegration.id },
              { channel }
            );
            toaster.success("Slack notification channel updated.");
          }}
        />
      </Pane>
    </Pane>
  );
};

const SelectSlackChannelDropdown = ({
  channels,
  selectedChannel,
  onSelectChannel,
}: {
  channels: SlackChannelsResource[];
  selectedChannel: SlackChannelsResource | undefined;
  onSelectChannel: (channel: SlackChannelsResource) => void;
}) => {
  const [filteredChannels, setFilteredChannels] = useState<
    SlackChannelsResource[]
  >(channels);

  const onSearch = (query: string) => {
    if (query.length < 1) {
      setFilteredChannels(channels);
      return;
    }

    const matchingChannels = channels.filter((channel) =>
      channel.name?.includes(query)
    );
    setFilteredChannels(matchingChannels);
  };

  return (
    <Row fontSize={14}>
      Send notifications to:
      <Select
        onSearch={onSearch}
        onChange={(selectedValues, newSelectedChannelId) => {
          if (newSelectedChannelId === selectedChannel?.id) {
            return;
          }

          const newChannel = channels.find(
            (channel) => channel.id === newSelectedChannelId
          );

          if (newChannel) {
            onSelectChannel(newChannel);
          }
        }}
        value={selectedChannel?.id}
        menuItems={filteredChannels.map((channel) => (
          <Select.MenuItem key={channel.name} value={channel.id}>
            <Row>{channel.name}</Row>
          </Select.MenuItem>
        ))}
      >
        <MiniButton marginLeft={6}>
          {selectedChannel ? `#${selectedChannel.name}` : "Select channel"}
        </MiniButton>
      </Select>
    </Row>
  );
};

export default SlackConnectionCard;
