import React from "react";
import { Tooltip } from "antd";
import { UIProps } from "components/base/types";

import Icon from "components/base/Icon";
import {
  getSuperType,
  DataSpecificType,
  DataSuperTypeConfig,
} from "types/dataType";

const DataTypeIcon = ({
  dataType,
  ...props
}: { dataType: DataSpecificType } & UIProps) => {
  const superType = getSuperType(dataType);
  return (
    <Tooltip color="black" title={dataType.toLowerCase()}>
      <Icon
        fixedWidth
        fontSize={12}
        opacity={0.7}
        {...props}
        type="far"
        name={DataSuperTypeConfig[superType].icon}
        color={DataSuperTypeConfig[superType].color}
      />
    </Tooltip>
  );
};

export default DataTypeIcon;
